<template>
  <SrpDetailsSummary class="photos-spoiler" :isOpened="true">
    <template #heading>
      <!-- Header -->
      <div class="header" style="margin-top: 2px">
        <h4
          class="global-h4 header__title"
          v-html="
            props.searchText && includesCaseInsensitive(props.title, props.searchText)
              ? props.title.replace(new RegExp(escapeSpecialSymbols(props.searchText), 'i'), match => `<b>${match}</b>`)
              : props.title
          "
        ></h4>

        <RouterLink v-if="props.nonLinkText && props.linkRoute" class="header__link-snippet" target="_blank" :to="props.linkRoute">
          <!--<IconEmbedded name="user_2" :size="21" style="margin-right: 3px" color="rgba(0, 0, 0, 0.5)" />-->
          <span class="header__link-snippet-link" @click.stop>
            {{ props.nonLinkText || "Go to" }}
            <IconEmbedded name="external-link_2-5" :size="15" style="margin-left: 3px" color="rgba(0, 0, 0, 0.5)" />
          </span>
        </RouterLink>

        <div v-if="props.email" class="header__email">
          <IconEmbedded name="envelope_2" :size="20" style="margin-right: 3px" color="rgba(0, 0, 0, 0.5)" />
          {{ props.email }}
        </div>

        <div v-if="props.date" class="header__date">
          <IconEmbedded name="calendar_2" :size="19" style="margin-right: 3px" color="rgba(0, 0, 0, 0.5)" />
          {{ props.date }}
        </div>
      </div>
      <!-- / Header -->
    </template>

    <template #details>
      <div style="width: 100%; height: 0; margin: 6px 0 17px; border-bottom: 1px rgba(0, 0, 0, 0.15) solid"></div>

      <!-- Photos spoiler content -->
      <div class="photos-spoiler-content">
        <!-- Uploads history -->
        <div v-if="props.groupingType !== 'community-upload' && props.additionalInfo" class="uploads-history photos-spoiler-content__uploads-history">
          <ul
            :class="{
              'uploads-history__info-snippets-list': true,
              'uploads-history__info-snippets-list--half-hidden': props.additionalInfo.length > additionalInfoTruncCharLimit && !isAdditionalInfoFullyVisible,
            }"
          >
            <li class="uploads-history__info-snippet">
              {{ props.additionalInfo.trim() }}
            </li>
          </ul>

          <div v-if="props.additionalInfo.length > additionalInfoTruncCharLimit" class="uploads-history__footer">
            <LinkWithIcon isDottedUnderline iconInset="auto auto auto 2px" style="margin-left: -5px" @click="isAdditionalInfoFullyVisible = !isAdditionalInfoFullyVisible">
              <template #icon>
                <IconEmbedded :name="isAdditionalInfoFullyVisible ? 'caret-top_3' : 'caret-bottom_3'" :size="14" />
              </template>
              <span v-if="isAdditionalInfoFullyVisible">Show less</span>
              <span v-else>Show more</span>
            </LinkWithIcon>
          </div>
        </div>
        <!-- / Uploads history -->

        <!-- "Select all" and "Edit" buttons -->
        <div v-if="props.isWithSelectAllCheckbox || props.showManageUploads" class="select-all-and-edit-buttons photos-spoiler-content__select-all-and-edit-buttons">
          <div class="select-all-and-edit-buttons__left-side">
            <SrpCheckbox v-if="props.isWithSelectAllCheckbox" class="select-all-and-edit-buttons__button" size="medium">
              <template #input
                ><input type="checkbox" @change="$event => emit('checkUncheckAll', ($event.target as HTMLInputElement).checked)" :checked="props.checkedFiles?.length === props.photosList?.length"
              /></template>
              <template #text>Select all</template>
            </SrpCheckbox>
            <slot name="custom-buttons"></slot>
          </div>

          <div class="select-all-and-edit-buttons__right-side">
            <LinkWithIcon v-if="props.showManageUploads" @click="emit('manageUploads')" class="select-all-and-edit-buttons__button" isDottedUnderline color="blue">
              <template #icon><IconEmbedded name="pencil_2" :size="20" /></template>
              <span>Manage uploads</span>
            </LinkWithIcon>
          </div>
        </div>
        <!-- / "Select all" and "Edit" buttons -->

        <template v-for="(subGroup, index) in photoSubGroupsList" :key="index">
          <!-- Thumbnails Sub Group -->
          <div
            v-if="
              includesCaseInsensitive(props.title, props.searchText) ||
              includesCaseInsensitive(subGroup.title, props.searchText) ||
              checkAdditionalInfoForSearchMatches(subGroup.additionalInfo, props.searchText)
            "
            class="thumbnails-sub-group photos-spoiler-content__thumbnails-sub-group"
            data-viewport-culling-root-margin="500px"
            v-viewport-culling="
              isVisible => {
                if (isVisible) visibleSubGroupIndexes.push(index);
                else visibleSubGroupIndexes = visibleSubGroupIndexes.filter(i => i !== index);
              }
            "
          >
            <!-- Sub Group title -->
            <div v-if="subGroup.title" class="sub-group-title thumbnails-sub-group__title">
              <div class="sub-group-title__content-wrap">
                <IconEmbedded v-if="subGroup.type === 'byLocation'" class="sub-group-title__icon" name="map-pin_2-5" :size="19" color="rgba(0, 0, 0, 0.5)" />
                <IconEmbedded v-if="subGroup.type === 'byAdventure'" class="sub-group-title__icon" name="road-sign_2-5" :size="20" color="rgba(0, 0, 0, 0.5)" />
                <IconEmbedded v-if="subGroup.type === 'byUploadCampaign'" class="sub-group-title__icon" name="upload_2-5" :size="20" color="rgba(0, 0, 0, 0.5)" />
                <IconEmbedded v-if="subGroup.type === 'byCreator'" class="sub-group-title__icon" name="user_2-5" :size="21" color="rgba(0, 0, 0, 0.5)" />

                <div class="sub-group-title__type-and-name">
                  <div class="sub-group-title__type">
                    {{
                      {
                        byLocation: "Location",
                        byAdventure: "Creator Visit",
                        byUploadCampaign: "Upload Campaign",
                        byCreator: groupingType === "community-upload" ? "Uploader" : "Creator",
                      }[subGroup.type]
                    }}:
                  </div>
                  <RouterLink
                    v-if="groupingType === 'location' && subGroup.type === 'byCreator'"
                    class="sub-group-title__name sub-group-title__name--link"
                    :to="{
                      name: 'CreatorProfilePublic',
                      params: { creatorId: subGroup.id },
                    }"
                  >
                    <span
                      v-html="
                        props.searchText && includesCaseInsensitive(subGroup.title, props.searchText)
                          ? subGroup.title.replace(new RegExp(escapeSpecialSymbols(props.searchText), 'i'), match => `<b>${match}</b>`)
                          : subGroup.title
                      "
                    >
                    </span>
                  </RouterLink>
                  <div v-else class="sub-group-title__name">
                    <span
                      v-html="
                        props.searchText && includesCaseInsensitive(subGroup.title, props.searchText)
                          ? subGroup.title.replace(new RegExp(escapeSpecialSymbols(props.searchText), 'i'), match => `<b>${match}</b>`)
                          : subGroup.title
                      "
                    >
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <!-- / Sub Group title -->

            <SubGroupAdditionalInfo v-if="subGroup.additionalInfo" class="thumbnails-sub-group__additional-info" :text="subGroup.additionalInfo" :searchTerm="props.searchText" />

            <ul class="thumbnails-sub-group__thumbnails-list">
              <!-- Thumbnail container -->
              <li class="thumbnail-container thumbnails-sub-group__thumbnail-container" v-for="fileName in subGroup.photos" :key="fileName">
                <ButtonIconExpandable
                  v-if="props.isThumbnailsWithDownloadButton && visibleSubGroupIndexes.includes(index)"
                  class="thumbnail-container__button-icon thumbnail-container__button-icon--top-right"
                  :icons="['download_2-5', 'download_2']"
                  :iconSizes="[16, 16]"
                  @click="emit('downloadImage', fileName)"
                />

                <SrpFileThumbnail
                  v-if="visibleSubGroupIndexes.includes(index)"
                  class="thumbnail-container__thumbnail"
                  :fileName="fileName"
                  :thumbSize="'thumb-tiny'"
                  :galleryPostfix="props.title + subGroup.title"
                  :isEntireAreaClickable="props.isThumbnailsWithCheckboxes ? false : props.isThumbnailsEntireAreaClickable"
                  :isEntireAreaCheckable="props.isThumbnailsEntireAreaCheckable"
                >
                  <template v-if="props.isThumbnailsWithCheckboxes" #default>
                    <input type="checkbox" :checked="props.checkedFiles.includes(fileName)" @change="$event => $emit('checkUncheckFile', fileName, $event.target.checked)" />
                  </template>
                </SrpFileThumbnail>
              </li>
              <!-- / Thumbnail container -->

              <!-- Spacers are needed to align the last row to the left edge while keeping all tiles horizontally centered at the same time (it's not possible to achieve the same effect with CSS alone) -->
              <li class="thumbnails-sub-group__thumbnail-spacer" v-for="_ in 50" :key="_"></li>
            </ul>
          </div>
          <!-- / Thumbnails Sub Group -->
        </template>
      </div>
      <!-- / Photos spoiler content -->
    </template>
  </SrpDetailsSummary>
</template>

<script setup lang="ts">
import { ref, onMounted, computed, nextTick } from "vue";
import { escapeSpecialSymbols, includesCaseInsensitive } from "@logic/String";
import generateSrc from "@helpers/GenerateGallerySrc";
import FileUtils from "@logic/FileUtils";

// Components
import IconEmbedded from "@components/ui/IconEmbedded.vue";
import LinkWithIcon from "@components/LinkWithIcon.vue";
import SrpDetailsSummary from "@components/ui/SrpDetailsSummary.vue";
import SrpCheckbox from "@components/ui/SrpCheckbox.vue";
import SrpFileThumbnail from "@components/ui/SrpFileThumbnail.vue";
import SubGroupAdditionalInfo from "@components/AllPhotosSection/SubGroupAdditionalInfo.vue";
import ButtonIconExpandable from "@components/ui/ButtonIconExpandable.vue";

// Types
import {
  SubGroupingType,
  PhotoSubGroup,
  LocationShortPhotoSummary,
  AdventureShortPhotoSummary,
  UploadCampaignShortPhotoSummary,
  CreatorShortPhotoSummary,
  GroupingType,
} from "@contracts/photosPageData";
import { ThumbSize } from "@helpers/GenerateGallerySrc";
import { checkAdditionalInfoForSearchMatches } from "@helpers/ContentLibrary";

const props = withDefaults(
  defineProps<{
    title: string | null;
    date?: string | null;
    email?: string | null;
    additionalInfo?: string | null;
    nonLinkText?: string | null;
    linkRoute?: any | null;
    isThumbnailsWithCheckboxes?: boolean;
    isThumbnailsWithDownloadButton?: boolean;
    isWithSelectAllCheckbox?: boolean;
    showManageUploads?: boolean;
    photosList: Array<string> | null;
    checkedFiles: Array<string> | null;
    isThumbnailsEntireAreaClickable?: boolean;
    isThumbnailsEntireAreaCheckable?: boolean;
    groupingType: GroupingType;
    subGroupingTypes: Array<SubGroupingType> | null;
    locationShortPhotoSummariesList?: Array<LocationShortPhotoSummary> | null;
    adventureShortPhotoSummariesList?: Array<AdventureShortPhotoSummary> | null;
    uploadCampaignShortPhotoSummariesList?: Array<UploadCampaignShortPhotoSummary> | null;
    creatorShortPhotoSummariesList?: Array<CreatorShortPhotoSummary> | null;
    searchText?: string | null;
  }>(),
  {
    title: null,
    date: null,
    email: null,
    additionalInfo: null,
    nonLinkText: null,
    linkRoute: null,
    isThumbnailsWithCheckboxes: false,
    isThumbnailsWithDownloadButton: false,
    isWithSelectAllCheckbox: false,
    showManageUploads: false,
    photosList: () => [],
    checkedFiles: () => [],
    isThumbnailsEntireAreaClickable: true,
    isThumbnailsEntireAreaCheckable: false,
    groupingType: null,
    subGroupingTypes: null,
    locationShortPhotoSummariesList: null,
    adventureShortPhotoSummariesList: null,
    uploadCampaignShortPhotoSummariesList: null,
    creatorShortPhotoSummariesList: null,
    searchText: null,
  }
);

const emit = defineEmits<{
  (e: "downloadImage", value: string): void;
  (e: "checkUncheckFile", fileName: string, isChecked: boolean): void;
  (e: "checkUncheckAll", isChecked: boolean): void;
  (e: "manageUploads"): void;
  (e: "uploadMore"): void;
}>();

// Additional info params =====================================================
const isAdditionalInfoFullyVisible = ref<boolean>(false);
const additionalInfoTruncCharLimit = 130;

// Make Photo Sub Groups ======================================================

function createSubGroup(
  photosList: Array<string>,
  summary: {
    locationId?: string;
    locationName?: string;

    adventureId?: string;
    adventureName?: string;

    campaignId?: string;
    campaignName?: string;

    photos: Array<string>;
    additionalInfo?: string;
  },
  subGroupingType: SubGroupingType
): PhotoSubGroup {
  const keyWord = {
    byLocation: "location",
    byAdventure: "adventure",
    byUploadCampaign: "uploadCampaign",
    byCreator: "creator",
  }[subGroupingType];

  const subGroup = {
    id: summary[`${keyWord}Id`],
    type: subGroupingType,
    title: summary[`${keyWord}Name`],
    photos: [],
    additionalInfo: summary.additionalInfo,
  } as PhotoSubGroup;

  summary.photos.forEach(fileName => {
    if (props.photosList.includes(fileName)) {
      subGroup.photos.push(fileName);
      // Delete the file from the local copy of the _photosList
      const justSubGroupedFileIndex = photosList.findIndex(f => f === fileName);
      if (justSubGroupedFileIndex !== -1) {
        photosList.splice(justSubGroupedFileIndex, 1);
      }
    }
  });

  return subGroup;
}

const photoSubGroupsList = computed<Array<PhotoSubGroup>>(() => {
  const _photoSubGroupsList = [];
  const _photosList = [...props.photosList];

  if (props.subGroupingTypes?.includes("byLocation") && props.locationShortPhotoSummariesList?.length) {
    props.locationShortPhotoSummariesList.forEach(summary => {
      _photoSubGroupsList.push(createSubGroup(_photosList, summary, "byLocation"));
    });
  }

  if (props.subGroupingTypes?.includes("byAdventure") && props.adventureShortPhotoSummariesList?.length) {
    props.adventureShortPhotoSummariesList.forEach(summary => {
      _photoSubGroupsList.push(createSubGroup(_photosList, summary, "byAdventure"));
    });
  }

  if (props.subGroupingTypes?.includes("byUploadCampaign") && props.uploadCampaignShortPhotoSummariesList?.length) {
    props.uploadCampaignShortPhotoSummariesList.forEach(summary => {
      _photoSubGroupsList.push(createSubGroup(_photosList, summary, "byUploadCampaign"));
    });
  }

  if (props.subGroupingTypes?.includes("byCreator") && props.creatorShortPhotoSummariesList?.length) {
    props.creatorShortPhotoSummariesList.forEach(summary => {
      _photoSubGroupsList.push(createSubGroup(_photosList, summary, "byCreator"));
    });
  }

  // Put files that left into the "ungrouped" subgroup
  if (_photosList.length) {
    _photoSubGroupsList.splice(0, 0, {
      id: 0,
      type: null,
      title: null,
      photos: _photosList,
    });
  }

  // Filter out empty groups
  return _photoSubGroupsList.filter(g => g.photos.length > 0) as Array<PhotoSubGroup>;
});

// Track subgroups visibility =================================================
const visibleSubGroupIndexes = ref<Array<number>>([]);
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";
@import "@/scss/variables.scss";

// Uploads history ============================================================
.uploads-history {
  color: rgba(91, 91, 91, 1);
  font: 14px/19px sans-serif;

  &__info-snippets-list {
    padding: 0;
    margin: 0;
    position: relative;
    z-index: 0;
    list-style: none;
    overflow: hidden;

    &--half-hidden {
      height: 90px;
      margin-bottom: 10px;

      &::after {
        content: "";
        width: 100%;
        height: 40px;
        position: absolute;
        inset: auto auto 0 0;
        z-index: 1;
        pointer-events: none;
        background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
      }
    }
  }

  &__info-snippet {
    padding-bottom: 8px;
    margin-bottom: 8px;
    position: relative;
    z-index: 0;
    white-space: pre-wrap;

    &::after {
      content: "";
      width: 100%;
      max-width: 250px;
      border-bottom: 1px rgba(0, 0, 0, 0.1) solid;
      position: absolute;
      inset: auto auto 0 0;
    }

    &:last-child {
      padding-bottom: 0;

      &::after {
        display: none;
      }
    }
  }

  &__footer {
  }

  &__show-more-link {
  }
}

// "Select all" and "Edit" buttons ============================================
.select-all-and-edit-buttons {
  display: flex;
  justify-content: space-between;

  &__left-side {
    gap: 15px;
    display: flex;
    align-items: center;
  }

  &__right-side {
    gap: 15px;
    display: flex;
    align-items: center;
  }

  &__button {
  }
}

// Thumbnail container ========================================================
.thumbnail-container {
  aspect-ratio: 4/3;
  border-radius: 6px;
  position: relative;
  z-index: 0;
  background: #d7d7d7;

  &__button-icon {
    position: absolute;
    z-index: 1;

    &--top-right {
      inset: 7px 7px auto auto;
    }
  }

  &__thumbnail {
    position: relative;
    z-index: 0;
  }
}

// Sub Group title ============================================================
.sub-group-title {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 0;

  &::after {
    content: "";
    flex-grow: 1;
    border-bottom: 1px rgba(0, 0, 0, 0.15) solid;
  }

  &__content-wrap {
    margin-right: 12px;
    display: flex;
    align-items: center;
  }

  &__icon {
    margin-right: 3px;
  }

  &__type-and-name {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  &__type {
    margin-right: 8px;
    color: rgba(0, 0, 0, 0.5);
    font-weight: 600;
    line-height: 15px;
  }

  &__name {
    font-weight: 700;
    line-height: 15px;

    :deep(b) {
      background: rgba(230, 230, 0, 1);
      outline: 2px rgba(200, 200, 0, 1) solid;
    }

    &--link {
      text-decoration: underline;
      text-decoration-color: rgba(0, 0, 0, 0.2);
      text-underline-offset: 2px;
      text-decoration-thickness: 1px;
      text-decoration-style: solid;

      &:hover {
        text-decoration: none;
      }
    }
  }
}

// Thumbnails Sub Group =======================================================
.thumbnails-sub-group {
  &__title {
    margin-bottom: 12px;
  }

  &__additional-info {
    margin-bottom: 12px;
  }

  &__thumbnails-list {
    padding: 0;
    margin: 0 -5px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    list-style: none;
  }

  &__thumbnail-container {
    width: 130px;
    max-width: 130px;
    margin: 5px;
  }

  &__thumbnail-spacer {
    width: 130px;
    max-width: 130px;
    margin: 0 5px;
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
  .thumbnails-sub-group {
    &__thumbnail-container,
    &__thumbnail-spacer {
      width: calc(13% + 2px);
    }
  }
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
  .thumbnails-sub-group {
    &__thumbnail-container,
    &__thumbnail-spacer {
      width: calc(15% + 3px);
    }
  }
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
  .thumbnails-sub-group {
    &__thumbnail-container,
    &__thumbnail-spacer {
      width: calc(15% + 2px);
    }
  }
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .thumbnails-sub-group {
    &__thumbnail-container,
    &__thumbnail-spacer {
      width: calc(23%);
    }
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .thumbnails-sub-group {
    &__thumbnail-container,
    &__thumbnail-spacer {
      width: calc(33.3% - 10px);
    }
  }
}

// Photos spoiler content =====================================================
.photos-spoiler-content {
  position: relative;
  z-index: 0;

  &__header {
  }

  &__uploads-history {
    width: 100%;
    margin-bottom: 20px;
  }

  &__select-all-and-edit-buttons {
    margin-bottom: 20px;
  }

  &__thumbnails-sub-group {
    margin-bottom: 25px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

// Header =====================================================================
.header {
  gap: 15px 30px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  color: rgba(91, 91, 91, 1);
  font: 14px/19px sans-serif;

  &__title {
    text-decoration: underline;
    text-decoration-color: rgba(91, 91, 91, 0.5);
    text-underline-offset: 3px;
    text-decoration-thickness: 1px;
    text-decoration-style: dashed;

    :deep(b) {
      background: rgba(230, 230, 0, 1);
      outline: 2px rgba(200, 200, 0, 1) solid;
    }
  }
  &:hover &__title {
    text-decoration: none;
  }

  &__link-snippet,
  &__email,
  &__date {
    display: flex;
    align-items: center;
  }

  &__link-snippet-link {
    display: flex;
    align-items: center;
    text-decoration: underline;
    text-decoration-color: rgba(91, 91, 91, 0.5);
    text-underline-offset: 2px;
    text-decoration-thickness: 1px;
    text-decoration-style: dashed;

    &:hover {
      text-decoration: none;
    }
  }
}

// Photos spoiler =============================================================
.photos-spoiler {
  padding: 17px 0 24px;
  position: relative;
  z-index: 0;

  &::before {
    content: "";
    width: calc(100% + 50px);
    height: 100%;
    box-sizing: border-box;
    border: 1px rgba(0, 0, 0, 0.15) solid;
    border-radius: 6px;
    position: absolute;
    inset: 0 auto auto -25px;
  }
}
</style>
