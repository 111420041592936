<template>
  <SrpDetailsSummary class="how-it-work-wrap" :isOpened="props.isOpened">
    <template #heading>
      <h3 class="global-h3">
        <span><span>How Custom Collabs Work</span></span>
      </h3>
    </template>
    <template #details>
      <div style="margin-bottom: 8px; border-bottom: 1px rgba(0, 0, 0, 0.15) solid"></div>

      <!-- "How it work" -->
      <div class="how-it-work">
        <ol class="how-it-work__ol-list">
          <li>Choose who you want to work with (this step);</li>
          <li>Communicate with the creator to hash out specifics;</li>
          <li>Create the collab based on that communication;</li>
          <li>The creator then does the work;</li>
          <li>Receive deliverables and transfer compensation!</li>
        </ol>

        <NoteWithIcon>
          <template #icon><IconEmbedded name="info-simple_4" :size="28" /></template>
          <template #text>
            <div>
              <h5 class="global-h5" style="margin-bottom: 5px">Important</h5>
              <span>
                YOU are responsible for custom collab coordination.<br /><a class="global-a global-a--orange">Get more details</a>&nbsp;<IconEmbedded
                  name="external-link_2-5"
                  :size="16"
                  style="position: relative; top: 2px; opacity: 0.5"
                />
              </span>
            </div>
          </template>
        </NoteWithIcon>
      </div>
      <!-- / "How it work" -->
    </template>
  </SrpDetailsSummary>
</template>

<script setup lang="ts">
// Components
import SrpDetailsSummary from "@components/ui/SrpDetailsSummary.vue";
import SrpButton from "@components/ui/SrpButton.vue";
import NoteWithIcon from "@components/NoteWithIcon.vue";
import IconEmbedded from "@components/ui/IconEmbedded.vue";

const props = withDefaults(
  defineProps<{
    isOpened?: boolean;
  }>(),
  {
    isOpened: true,
  }
);
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";
@import "@/scss/variables.scss";

// "How it work" ==============================================================
.how-it-work {
  &__ol-list {
    @include standardFont;
    padding-left: 15px;
    margin-left: 0;

    li {
      margin-bottom: 7px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

// "How it work" wrap =========================================================
.how-it-work-wrap {
  padding: 20px 25px 24px;
  border-radius: 6px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0 3px 15px -5px rgba(0, 0, 0, 0.4);
}
</style>
