<template>
  <div
    :class="{
      'work-status-snippet': true,
      'work-status-snippet--yellow-bg': workStatus === CustomCollabStatuses.InProgress,
      'work-status-snippet--green-bg': workStatus === CustomCollabStatuses.Complete,
    }"
  >
    <!-- Header -->
    <div class="header work-status-snippet__header">
      <div class="global-h3 header__title">Work Status</div>
      <div class="header__subtitle">Keep the destination updated on how the work is progressing</div>
    </div>
    <!-- / Header -->

    <div class="work-status-snippet__divider"></div>

    <!-- Work status controls -->
    <div class="work-status-controls work-status-snippet__work-status-controls">
      <div class="global-h4 work-status-controls__title">Status:</div>
      <!-- Work status dropdown -->
      <div class="work-status-dropdown work-status-controls__dropdown">
        <CollabStatusStripe class="work-status-dropdown__status-stripe" :text="getStatusText(workStatus)" size="medium" :color="workStatus === CustomCollabStatuses.Complete ? 'green' : 'white'" />

        <span class="global-select-wrap work-status-dropdown__select">
          <span class="global-select-wrap__arrow"></span>
          <select class="global-select" v-model="workStatus">
            <option :value="CustomCollabStatuses.Planning">{{ getStatusText(CustomCollabStatuses.Planning) }}</option>
            <option :value="CustomCollabStatuses.InProgress">{{ getStatusText(CustomCollabStatuses.InProgress) }}</option>
            <option :value="CustomCollabStatuses.Complete">{{ getStatusText(CustomCollabStatuses.Complete) }}</option>
          </select>
        </span>
      </div>
      <!-- / Work status dropdown -->
    </div>
    <!-- / Work status controls -->

    <div class="work-status-snippet__divider"></div>

    <!-- Notes form -->
    <div class="notes-form work-status-snippet__notes-form">
      <div class="global-h4 notes-form__title">Notes</div>
      <textarea class="global-textarea notes-form__textarea"></textarea>
    </div>
    <!-- / Notes form -->

    <DeliverablesForm class="work-status-snippet__deliverables-form" />

    <div class="work-status-snippet__bottom-panel">
      <NoteWithIcon size="tiny" :isTransparentBg="true" color="gray">
        <template #icon><IconEmbedded name="info-simple_4" :size="18" /></template>
        <template #text><div>The destination will be notified when updates are made</div></template>
      </NoteWithIcon>

      <SrpButton>
        <template #icon><IconEmbedded name="save_2" :size="22" /></template>
        Save
      </SrpButton>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { getStatusText } from "@helpers/CustomCollabsHelper";

// Types
import { CustomCollabStatuses } from "@contracts/customCollabs";

// Components
import CollabStatusStripe from "@components/CollabStatusStripe.vue";
import DeliverablesForm from "@views/CustomCollabs/CreatorCustomCollab/DeliverablesForm.vue";
import NoteWithIcon from "@components/NoteWithIcon.vue";
import IconEmbedded from "@components/ui/IconEmbedded.vue";
import SrpButton from "@components/ui/SrpButton.vue";

const props = withDefaults(defineProps<{}>(), {});

// Work Status ================================================================
const workStatus = ref<CustomCollabStatuses>(CustomCollabStatuses.Planning);
</script>

<style scoped lang="scss">
@import "@/scss/variables.scss";

// Header =====================================================================
.header {
  &__title {
    margin-bottom: 3px;
  }

  &__subtitle {
  }
}

// Work status dropdown =======================================================
.work-status-dropdown {
  position: relative;
  z-index: 0;

  &__status-stripe {
    position: absolute;
    inset: 6px auto auto 10px;
    z-index: 1;
    pointer-events: none;
  }

  &__select {
    select {
      padding-right: 50px;
    }
  }
}

// Work status controls =======================================================
.work-status-controls {
  display: inline-flex;
  align-items: center;

  &__title {
    margin-right: 7px;
  }

  &__dropdown {
  }
}

// Notes form =================================================================
.notes-form {
  &__title {
    margin-bottom: 6px;
  }

  &__textarea {
    height: 70px;
    min-height: 70px;
  }
}

// Work status snippet ========================================================
.work-status-snippet {
  @include standardFont;
  padding: 20px 25px;
  border-radius: 6px;
  background: rgba(240, 240, 240, 1);

  &--yellow-bg {
    background: rgba(240, 231, 184, 1);
  }
  &--green-bg {
    background: rgba(213, 236, 206, 1);
  }

  &__header {
    margin-bottom: 14px;
  }

  &__divider {
    margin-bottom: 14px;
    border-bottom: 1px rgba(0, 0, 0, 0.1) solid;
  }

  &__work-status-controls {
    margin-bottom: 18px;
  }

  &__notes-form {
    margin-bottom: 23px;
  }

  &__deliverables-form {
    margin-bottom: 20px;
  }

  &__bottom-panel {
    gap: 12px 22px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
  }
}
</style>
