<template>
  <div class="apply-form">
    <div class="apply-form__sections-list">
      <!--<div class="apply-form__buttons-panel">-->
      <!--  <SrpButton class="apply-form__button" color="orange">Apply for Collab</SrpButton>-->
      <!--  <SrpButton class="apply-form__button" color="gray" isWithOrSeparator>Can't make it</SrpButton>-->
      <!--</div>-->

      <div class="apply-form__section">
        <h6 class="global-h6 apply-form__title">When do you plan to visit?</h6>
        <div class="apply-form__form-elements-row">
          <VueDatePicker
            class="apply-form__form-element apply-form__form-element--narrow"
            type="date"
            format="MMM d yyyy"
            v-model="_dateRange"
            @update:modelValue="dateChanged"
            range
            multi-calendars
            ref="datePicker"
            autoApply
            :enableTimePicker="false"
            :teleportCenter="['mobile'].includes(screenSize)"
            :teleport="true"
          />
        </div>
        <NoteWithIcon v-if="creatorDatesAreOutsideCommunityInputDates" class="apply-form__note" size="tiny">
          <template #icon><IconEmbedded name="info-simple_4" /></template>
          <template #text>
            <span>The dates are out of the preferred visit range and may not work for the destination.</span>
          </template>
        </NoteWithIcon>
      </div>

      <div v-if="props.collab?.creatorInput" class="apply-form__section">
        <h6 class="global-h6 apply-form__title">Anyone you plan to travel with?</h6>
        <div class="apply-form__form-elements-row">
          <input class="apply-form__form-element apply-form__form-element--narrow" type="text" v-model="props.collab.creatorInput.travelingWith" placeholder="Ex. My family of 4" />
        </div>
      </div>

      <div v-if="props.collab?.creatorInput" class="apply-form__section">
        <h6 class="global-h6 apply-form__title">Do you plan to stay overnight?</h6>

        <div class="apply-form__form-elements-row">
          <SrpCheckbox class="apply-form__form-element">
            <template #input><input type="radio" v-model="props.collab.creatorInput.stayingOverNight" @change="stayingOvernightChanged" name="overnight" :value="true" /></template>
            <template #text>Yes</template>
          </SrpCheckbox>

          <SrpCheckbox class="apply-form__form-element">
            <template #input><input type="radio" v-model="props.collab.creatorInput.stayingOverNight" @change="stayingOvernightChanged" name="overnight" :value="false" /></template>
            <template #text>No</template>
          </SrpCheckbox>
        </div>
      </div>

      <TransitionedHeightAuto v-if="props.collab?.creatorInput" class="apply-form__section" :isOpened="creatorInput?.stayingOverNight">
        <h6 class="global-h6 apply-form__title">How many nights do you require?</h6>

        <div class="apply-form__form-elements-row">
          <SrpCheckbox class="apply-form__form-element">
            <template #input><input type="radio" v-model="props.collab.creatorInput.nightsRequired" name="nightsRequire" :value="1" /></template>
            <template #text>1 night</template>
          </SrpCheckbox>

          <SrpCheckbox class="apply-form__form-element">
            <template #input><input type="radio" v-model="props.collab.creatorInput.nightsRequired" name="nightsRequire" :value="2" /></template>
            <template #text>2 nights</template>
          </SrpCheckbox>
        </div>

        <TransitionedHeightAuto class="apply-form__note" :isOpened="collabInput?.hotelNightsCovered === 0 && creatorInput?.nightsRequired > 0">
          <NoteWithIcon class="apply-form__note" size="tiny" color="blue">
            <template #icon><IconEmbedded name="info-simple_4" /></template>
            <template #text>
              <span>Shrpa will provide ${{ HotelCoverageFromShrpa }} for you to arrange your own lodging.</span>
            </template>
          </NoteWithIcon>
        </TransitionedHeightAuto>

        <TransitionedHeightAuto class="apply-form__note" :isOpened="collabInput?.hotelNightsCovered === 1 && creatorInput?.nightsRequired === 2">
          <NoteWithIcon class="apply-form__note" size="tiny">
            <template #icon><IconEmbedded name="info-simple_4" /></template>
            <template #text>
              <span>Only 1 night is covered by this destination.</span>
            </template>
          </NoteWithIcon>
        </TransitionedHeightAuto>
      </TransitionedHeightAuto>

      <TransitionedHeightAuto v-if="props.collab?.creatorInput" class="apply-form__section" :isOpened="collabInput?.gearToBring?.length > 0">
        <h6 class="global-h6 apply-form__title">I can bring all the gear needed:</h6>
        <p>{{ collabInput?.gearToBring }}</p>

        <div class="apply-form__form-elements-row">
          <SrpCheckbox class="apply-form__form-element">
            <template #input><input type="radio" v-model="props.collab.creatorInput.canBringNeededGear" name="gearNeeded" :value="true" /></template>
            <template #text>Yes</template>
          </SrpCheckbox>

          <SrpCheckbox class="apply-form__form-element">
            <template #input><input type="radio" v-model="props.collab.creatorInput.canBringNeededGear" name="gearNeeded" :value="false" /></template>
            <template #text>No</template>
          </SrpCheckbox>
        </div>
      </TransitionedHeightAuto>

      <TransitionedHeightAuto
        v-if="props.collab?.creatorInput"
        class="apply-form__section"
        :isOpened="typeof collab.creatorInput.canBringNeededGear === 'boolean' && !collab.creatorInput.canBringNeededGear"
      >
        <h6 class="global-h6 apply-form__title">What additional gear do you need?</h6>
        <div class="apply-form__form-elements-row">
          <input class="apply-form__form-element" type="text" v-model="props.collab.creatorInput.gearNeeded" />
        </div>
      </TransitionedHeightAuto>

      <div v-if="!props.collab?.collabInput?.createdForCreatorId" class="apply-form__section">
        <h6 class="global-h6 apply-form__title">Tell the destination why you're a great fit for this visit! (optional)</h6>
        <div class="apply-form__form-elements-row">
          <textarea
            v-if="props.collab?.creatorInput"
            class="apply-form__form-element"
            type="text"
            maxlength="5000"
            autocapitalize="sentences"
            style="height: 80px"
            v-model="props.collab.creatorInput.notesForCommunity"
          ></textarea>
        </div>
      </div>

      <!-- Errors list -->
      <TransitionedHeightAuto class="apply-form__errors-list-wrap" :isOpened="props.errors.length > 0">
        <div class="errors-list apply-form__errors-list">
          <h5 class="global-h5 errors-list__title">Required Fields</h5>
          <ul class="errors-list__list-itself">
            <li class="errors-list__error" v-for="(error, index) in props.errors" :key="index">- {{ error }}</li>
          </ul>
        </div>
      </TransitionedHeightAuto>
      <!-- / Errors list -->
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, inject, onMounted, watch, Ref } from "vue";
import CollabValueCalculator from "@logic/CollabValueCalculator";

// Types
import { CollabCreatorInput, CollabInput, CreatorCollab } from "@contracts/collab";
import { ScreenSize } from "@contracts/screenSize";

// Components
import DateUtils from "@logic/DateUtils";
import NoteWithIcon from "@components/NoteWithIcon.vue";
import SrpCheckbox from "@components/ui/SrpCheckbox.vue";
import TransitionedHeightAuto from "@components/ui/TransitionedHeightAuto.vue";
import VueDatePicker from "@vuepic/vue-datepicker";
import IconEmbedded from "@components/ui/IconEmbedded.vue";

export interface Props {
  dateRange: Array<Date> | null;
  collab: CreatorCollab | null;
  errors: Array<string>;
}
const props = withDefaults(defineProps<Props>(), {
  dateRange: null,
  collab: null,
  errors: () => [],
});

const emit = defineEmits<{
  (e: "update:dateRange", event: Date[] | null): void;
}>();

const screenSize = inject("screenSize") as Ref<ScreenSize>;

// Set dateRange ==============================================================
const _dateRange = computed<Array<Date>>({
  get: () => (props.dateRange ? props.dateRange : []),
  set: newValue => emit("update:dateRange", newValue),
});

// ============================================================================
const globalLog = inject("globalLog") as any;

const HotelCoverageFromShrpa = CollabValueCalculator.HotelCoverageFromShrpa;

const collabInput = computed<CollabInput | null>(() => props.collab?.collabInput);
const creatorInput = computed<CollabCreatorInput | null>(() => props.collab?.creatorInput);

const creatorDatesAreOutsideCommunityInputDates = computed<boolean>(() => {
  // Only applies if creator has chosen a date
  if (props.dateRange?.length > 1 && props.dateRange[0] && props.dateRange[1]) {
    // Parse out the dates and see if the creator's are outside the community's requested.
    const creatorStartDate = props.dateRange[0];
    const creatorEndDate = props.dateRange[1];
    const communityStartDate = new Date(props.collab?.collabInput?.preferredDateRangeStart);
    const communityEndDate = new Date(props.collab?.collabInput?.preferredDateRangeEnd);
    // Adjust these dates to account for timezone stuff (note that we set the community dates to midday to avoid it changing days during DST or users in different timezones)
    communityStartDate.setHours(0, 0, 0, 0);
    communityEndDate.setHours(23, 59, 59);
    const isOutOfRange = creatorStartDate < communityStartDate || creatorEndDate > communityEndDate;
    return isOutOfRange;
  }
  return false;
});
function stayingOvernightChanged() {
  if (props.collab.creatorInput.stayingOverNight === false) {
    props.collab.creatorInput.nightsRequired = 0;
  }
}

function dateChanged(dates: Array<Date | null>) {
  if (!props.collab) return;

  // If the date is cleared, we get an array with null vales
  if (!dates || dates.length === 0 || dates[0] === null) {
    props.collab.creatorInput.proposedVisitDateTime = null;
    props.collab.creatorInput.numberOfDaysStaying = 0;
  } else {
    let startDate = forceToNoonUTC(dates[0]);
    props.collab.creatorInput.proposedVisitDateTime = startDate.toISOString();
    const daysDiff = DateUtils.daysBetween(dates[0], dates[1]);
    props.collab.creatorInput.numberOfDaysStaying = daysDiff;
    globalLog.info(`Date set: ${props.collab.creatorInput.proposedVisitDateTime}, daysStaying=${props.collab.creatorInput.numberOfDaysStaying}`);
  }
}

function forceToNoonUTC(date: Date) {
  // Repeated other places DatePicker is used
  // Note: Previously we were using setUTCHours(12) but that was causing the date to be off by a day
  // Have to use Date.UTC or it will adjust hours to local time
  const local = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 12, 11, 11));
  return local;
}
</script>

<style scoped lang="scss">
// Errors list ================================================================
.errors-list {
  &__title {
    margin-bottom: 3px;
    color: #da0000;
  }

  &__list-itself {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  &__error {
    margin-bottom: 4px;
    color: #da0000;
    font: 14px/18px sans-serif;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

// Apply form =================================================================
.apply-form {
  &__sections-list {
    margin-bottom: 16px;
    border-bottom: 1px rgba(0, 0, 0, 0.15) solid;
  }

  &__section {
    margin-bottom: 16px;
  }

  &__title {
    margin-bottom: 6px;
  }

  &__form-elements-row {
    margin-bottom: 8px;
    display: flex;
    justify-content: flex-start;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__form-element {
    margin-right: 8px !important;

    &--narrow {
      max-width: 300px;
    }

    &:last-child {
      margin-right: 0 !important;
    }
  }

  &__note {
  }

  &__errors-list-wrap {
    padding-top: 15px;
    border-top: 1px rgba(0, 0, 0, 0.1) solid;
    margin-bottom: 15px;
  }

  &__errors-list {
  }

  &__buttons-panel {
    display: flex;
    align-items: center;
  }

  &__button {
    margin-right: 4px;
    //
    //&:last-child {
    //  margin-right: 0;
    //}
  }
}
</style>
