<template>
  <Teleport to="#app">
    <div
      :class="{
        'side-flyout': true,
        'side-flyout--right': side === 'right',
        'side-flyout--invisible': !isVisible,
      }"
      :style="{ width: `${width}px` }"
    >
      <!-- Content container -->
      <div
        v-if="isSlotVisible"
        :class="{
          'content-container': true,
          'side-flyout__content-container': true,
          'side-flyout__content-container--right': side === 'right',
          'side-flyout__content-container--invisible': !isVisibleLocal,
        }"
      >
        <div class="content-container__header">
          <CloseDeleteButton
            :class="{
              'content-container__close-button': true,
              'content-container__close-button--left-aligned': side === 'right',
            }"
            @click="close"
          />

          <div class="global-h1 content-container__title">
            {{ title }}
          </div>
          <div class="content-container__sub-title">{{ subTitle }}</div>
        </div>

        <div class="content-container__content-itself">
          <slot v-if="isSlotVisible" />
        </div>
      </div>
      <!-- / Content container -->

      <!-- It should stay separated from the "side-flyout" component because it uses mix-blend mode which doesn,t work when div with mix-blend mode is not a direct child of the body element -->
      <Teleport v-if="isSlotVisible" to="#app">
        <!-- Clickable bg layer -->
        <div
          :class="{
            'clickable-bg-layer': true,
            'clickable-bg-layer--invisible': !isVisibleLocal,
          }"
          @click="close"
        ></div>
        <!-- / Clickable bg layer -->
      </Teleport>
    </div>
  </Teleport>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import IconEmbedded from "@components/ui/IconEmbedded.vue";
import CloseDeleteButton from "@components/ui/CloseDeleteButton.vue";

export default defineComponent({
  name: "SideFlyout",
  components: { CloseDeleteButton, IconEmbedded },

  props: {
    isVisible: { type: Boolean },
    side: { type: String, default: "left", validator: (v: string) => ["left", "right"].includes(v) },
    title: { type: String, default: "Flyout" },
    subTitle: { type: String, default: "" },
    width: { type: [Number, String], default: 580 },
  },

  emits: ["update:isVisible"],

  data() {
    return {
      activeSlide: 0,

      // displays the slot content only when it's needed
      isSlotVisible: false as boolean,
      // needed to play the transition animation after/before the actual isVisible property value changes
      isVisibleLocal: false as boolean,

      oldBodyOverflowY: "",
    };
  },

  watch: {
    isVisible() {
      if (this.isVisible) {
        this.isSlotVisible = true;

        setTimeout(() => (this.isVisibleLocal = true), 50);
      } else {
        this.isVisibleLocal = false;

        setTimeout(() => (this.isSlotVisible = false), 320);
      }

      if (this.isVisible) {
        setTimeout(() => {
          this.oldBodyOverflowY = document.querySelector("body").style.overflowY;
          (document.querySelector("#app") as HTMLElement).style.overflowY = "scroll";
          document.querySelector("body").style.overflowY = "hidden";
        }, 200);
      } else {
        setTimeout(() => {
          (document.querySelector("#app") as HTMLElement).style.overflowY = "initial";
          document.querySelector("body").style.overflowY = this.oldBodyOverflowY;
        }, 200);
      }
    },
  },

  methods: {
    close() {
      this.$emit("update:isVisible", false);
    },
  },
});
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";

// Creator info spoiler =======================================================
.creator-info-spoiler {
  height: 50px;
  box-sizing: border-box;
  border-bottom: 1px rgba(0, 0, 0, 0.1) solid;
}

// Messages list ==============================================================
.messages-list {
  width: 100%;
  background: rgba(255, 0, 0, 0.1);
}

// New message form ===========================================================
.new-message-form {
  width: 100%;
  height: 160px;
  background: rgba(0, 255, 0, 0.1);
}

// Content container ==========================================================
.content-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: white;

  &__header {
    height: 85px;
    padding: 0 33px;
    border-bottom: 1px rgba(0, 0, 0, 0.1) solid;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
  }

  &__title {
    padding-bottom: 8px;
    position: relative;
    overflow: hidden;
    font-weight: 400;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__sub-title {
    position: absolute;
    inset: 58px auto auto 35px;
    color: #d94b4b;
    font:
      14px/14px "Helvetica Neue",
      sans-serif;
    white-space: nowrap;
  }

  &__close-button {
    width: 37px;
    height: 37px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    inset: calc(50% - 18px) -18px auto auto;
    z-index: 0;
    cursor: pointer;
    user-select: none;

    :deep(svg) {
      fill: rgba(23, 37, 45, 0.5);
      transition: fill 0.07s ease-in-out;
    }

    &:hover {
      :deep(svg) {
        fill: #fff;
      }
    }

    &::after {
      content: "";
      width: 100%;
      height: 100%;
      border-radius: 50%;
      position: absolute;
      inset: 0 auto auto 0;
      z-index: -1;
      background: #e7edf0;
      transition:
        width 0.07s ease-in-out,
        height 0.07s ease-in-out,
        inset 0.07s ease-in-out,
        background 0.07s ease-in-out;
    }

    &:hover::after {
      width: calc(100% + 6px);
      height: calc(100% + 6px);
      inset: -3px auto auto -3px;
      background: #ec563b;
    }

    &--left-aligned {
      inset: calc(50% - 18px) auto auto -18px;
    }
  }

  &__content-itself {
    height: calc(100% - 85px);
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;

    & > :deep(*) {
      height: 100%;
    }
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .content-container {
    &__header {
      height: 60px;
      padding: 0 17px;
    }

    &__title {
      padding-bottom: 5px;
      font-size: 23px;
      line-height: 23px;
    }

    &__sub-title {
      top: 40px;
      left: 18px;
    }

    &__close-button {
      width: 36px;
      height: 36px;
    }

    &__content-itself {
      height: calc(100% - 60px) !important;
    }
  }
}

// Side flyout ================================================================
.side-flyout {
  max-width: calc(100% - 50px);
  height: 100%;
  position: fixed;
  inset: 0 auto auto 0;
  z-index: 202;

  &--invisible {
    pointer-events: none;
  }

  &--right {
    inset: 0 0 auto auto;
  }

  &__content-container {
    position: absolute;
    inset: 0 auto auto 0;
    z-index: 2;
    transform: translateX(0);
    transition: transform 0.3s cubic-bezier(0.8, 0, 0.2, 1);

    &--invisible {
      transform: translateX(calc(-100% - 18px));
    }

    &--right {
      inset: 0 0 auto auto;
      transform: translateX(0);
    }

    &--right.side-flyout__content-container--invisible {
      transform: translateX(calc(100% + 18px));
    }
  }
}

// Clickable bg layer =========================================================
.clickable-bg-layer {
  width: 100vw;
  height: 100%;
  position: fixed;
  inset: 0 auto auto 0;
  z-index: 201;
  opacity: 1;
  background: rgba(#02070a, 0.9);
  mix-blend-mode: hard-light;
  transition: opacity 0.3s cubic-bezier(0.8, 0, 0.2, 1);

  &--invisible {
    opacity: 0;
    pointer-events: none;
  }
}
</style>
