import { Router } from "vue-router";

export class CreatorRedirectHelper {
  static checkForRedirect(router: Router, creatorId: string): boolean {
    // Get redirect param from query string
    const params = new URLSearchParams(window.location.search);
    const redirect = params.get("r")?.toUpperCase();

    if (redirect) {
      const collabId = params.get("collab");
      const customerId = params.get("dest");

      if (["AVAILABLECOLLABS", "COLLAB", "COLLABCREDITS"].includes(redirect)) {
        if (customerId && collabId) {
          // Navigate to specific collab
          router.replace({
            name: "CollabOpportunity",
            params: {
              communityId: customerId,
              collabInputId: collabId,
              creatorId: creatorId,
            },
          });
          return true;
        }
      } else if (["REFERRAL", "REFERRALS"].includes(redirect)) {
        router.replace({
          name: "CreatorReferrals",
          params: { creatorId: creatorId },
        });
        return true;
      }
    }
    return false;
  }
}
