<template>
  <SrpModal
    :isVisible="props.isVisible"
    @update:isVisible="v => emit('update:isVisible', v)"
    isWithScroll
    :maxHeight="screenSize === 'mobile' ? 'calc(100dvh - 220px)' : 'calc(100dvh - 120px)'"
    :isClosable="false"
    :isCloseButtonVisible="showVisitPlanFields"
    size="large"
  >
    <template #content>
      <div class="plan-edit-form" style="margin-bottom: 20px">
        <template v-if="showVisitPlanFields">
          <h1 class="global-h1 plan-edit-form__title">Share your Plan with the Destination</h1>

          <!-- prettier-ignore -->
          <div class="plan-edit-form__description">
          <h5 class="global-h5">List out the {{collabInput.requestedLocationCount}}+ locations or activities you plan to feature during this visit.</h5>
          The destination will sign off on your visit itinerary so that everyone is on the same page.<br/>
          <p style="margin-bottom: 12px; font: 14px/18px sans-serif;">If you have questions, just ask! <CopyTextNew>collabs@shrpa.com</CopyTextNew></p>
          
          <b>How am I supposed to visit {{collabInput.requestedLocationCount}} unique locations? </b> 
          <InfoIconWithTooltip :width="420" style="position:relative;top: 0;" icon="question-simple_4" :iconSize="11">
            <p style="font: 14px/18px sans-serif;">If your visit is focused around an event or a single location that might take a whole day to explore, it doesn't make sense to go to a bunch of places!</p>

            <p style="font: 14px/18px sans-serif;"><b>Feature aspects of the event or location as stops in the adventure.</b> Talk about the food options, live music, arts, shopping, activities, etc, as individual stops..</p>

            <p style="font: 14px/18px sans-serif;">
              <span style="margin-bottom: 4px;display: inline-block;">Creator Visits that did a great job of this:</span><br/>
              <a class="global-a" href="https://shrpa.com/collabs/beaverdamwi/468491b8-f07e-4b02-ab22-b08e69f6f253" target="_blank" style="margin-bottom: 4px; display:inline-block;text-decoration: underline">
                An Unforgettable Autumn Experience at Fall Downtown Fest
              </a><br />
              <a class="global-a" href="https://shrpa.com/collabs/waterlooia/acabe21b-1e5a-49fc-8121-80550fe04b55" target="_blank" style="text-decoration: underline">
                Lost Island Family Adventure
              </a></p>
          </InfoIconWithTooltip>
        </div>
        </template>

        <LocationsList
          v-if="props.collabInput.useLocationsInVisitPlan || (!showVisitPlanFields && props.collabInput.useLocationsInAdventures)"
          :boundaryGeoJson="page?.boundaryGeoJson"
          :locationsList="collabLocationsData.locations"
          :showValidation="errorMessage != null"
          :isVisitPlanNotesVisible="showVisitPlanFields"
          v-model:visitPlanNotes="collabLocationsStore.visitPlanNotes"
          @update:locationsList="
            newLocationsList => {
              collabLocationsData.locations = newLocationsList;
            }
          "
          :boundarySelectMode="page?.boundarySelectMode"
          :withinMiles="page?.withinMiles"
          :mapCenter="page ? { latitude: page.latitude, longitude: page.longitude } : null"
        />

        <template v-if="showVisitPlanFields">
          <div class="plan-edit-form__notify-destination-note"></div>

          <NoteWithIcon v-if="isPlanWasChanged && props.creatorInput.visitPlanSignOffDate" class="plan-edit-form__notify-destination-note" color="yellow" size="small">
            <template #icon><IconEmbedded name="info-simple_4" :size="18" /></template>
            <template #text>
              <div>
                <h4 class="global-h4" style="margin-bottom: 3px">If meaningful changes are made, notify the destination.</h4>
              </div>
            </template>
          </NoteWithIcon>

          <LinkWithIcon isDottedUnderline @click="switchToChat" color="blue" style="margin-bottom: 20px">
            <template #icon><IconEmbedded name="chat_2-5" :size="19" /></template>
            <span>Connect with the Destination</span>
          </LinkWithIcon>

          <textarea
            v-if="!props.collabInput.useLocationsInVisitPlan"
            :class="{
              'global-textarea': true,
              'plan-edit-form__plan-textarea': true,
            }"
            v-model="_visitPlan"
            placeholder="1. Central Perk
2. Krusty Krab
3. Willy Wonka's Chocolate Factory
4. …
"
          ></textarea>

          <div class="plan-edit-form__divider"></div>

          <SrpDetailsSummary class="plan-edit-form__activities-and-boundary-spoiler" :isOpened="true" :transitionDuration="150">
            <template #heading>
              <div class="global-h3">
                <span
                  ><span>
                    {{ props.collabInput.useLocationsInVisitPlan ? "Activities" : "Activities and Boundary" }}
                  </span></span
                >
              </div>
            </template>
            <template #details>
              <div class="plan-edit-form__activities-section">
                <!-- Activity snippet -->
                <div class="activity-snippet plan-edit-form__activity-snippet">
                  <div class="activity-snippet__header">
                    <IconEmbedded class="activity-snippet__title-icon" name="zap-solid" />
                    <h5 class="global-h5 activity-snippet__title">Required Locations</h5>
                  </div>

                  <div class="activity-snippet__content">
                    {{ props.collabInput.requiredAttractions }}
                  </div>
                </div>
                <!-- / Activity snippet -->

                <!-- Activity snippet -->
                <div class="activity-snippet plan-edit-form__activity-snippet">
                  <div class="activity-snippet__header">
                    <IconEmbedded class="activity-snippet__title-icon" name="zap_2" />
                    <h5 class="global-h5 activity-snippet__title">Suggested Locations</h5>
                  </div>

                  <div class="activity-snippet__content">
                    {{ props.collabInput.suggestedAttractions }}
                  </div>
                </div>
                <!-- / Activity snippet -->
              </div>

              <!-- Boundary section -->
              <div v-if="!props.collabInput.useLocationsInVisitPlan" class="boundary-section plan-edit-form__boundary-section">
                <div class="boundary-section__header">
                  <h5 class="global-h5 boundary-section__title">Boundary</h5>
                  <div class="boundary-section__description">This is the destination boundary. We recommend verifying any stops outside this boundary with the destination.</div>
                </div>

                <DrawingMap
                  v-if="page"
                  class="boundary-section__map"
                  :lat="page.latitude"
                  :lng="page.longitude"
                  :inputGeoJson="page.boundaryGeoJson"
                  :circleRadiusInMiles="+page.withinMiles"
                  :boundarySelectMode="page.boundarySelectMode"
                  :onlyAllowDrawingMode="true"
                  :showBoundaryAsReadonly="true"
                  :isCreatorFlow="false"
                />
                <!-- Map placeholder -->
                <div v-else class="map-placeholder boundary-section__map-placeholder">
                  <div class="map-placeholder__text">Loading map<EllipsisAnimated /></div>
                  <img class="map-placeholder__bg-image" src="/images/map-placeholder.gif" alt="Map" />
                </div>
                <!-- / Map placeholder -->
              </div>
              <!-- / Boundary section -->
            </template>
          </SrpDetailsSummary>
        </template>
      </div>
    </template>

    <template #footer>
      <div v-if="errorMessage" class="validation-message">
        <b>{{ errorMessage }}</b>
      </div>

      <SrpButton :style="{ margin: ['mobile', 'tablet'].includes(screenSize) ? '0 13px 13px 0' : '0 13px 0 0' }" color="gray" fill="outlined" @click="cancelPlanEditing"> Cancel </SrpButton>

      <template v-if="showVisitPlanFields && creatorInput && !creatorInput.visitPlanSentDate">
        <SrpButton
          :style="{
            margin: ['mobile', 'tablet'].includes(screenSize) ? '0 0 13px 0' : '0 13px 0 0',
          }"
          color="gray"
          fill="outlined"
          @click="save(false)"
        >
          <IconEmbedded name="save_2" style="margin-right: 6px" :size="22" color="rgba(0, 0, 0, 0.4)" />
          Only Save
        </SrpButton>

        <SrpButton @click="save(true)">
          <template #icon><IconEmbedded name="send_2-5" style="margin-right: 6px" :size="22" color="rgba(255, 255, 255, 0.5)" /></template>
          Save and Send to Destination
        </SrpButton>
      </template>

      <SrpButton v-else color="gray" fill="outlined" @click="save(false)">
        <template #icon><IconEmbedded name="save_2" :size="22" color="rgba(0, 0, 0, 0.4)" style="margin-right: 6px" /></template>
        Save
      </SrpButton>
    </template>
  </SrpModal>
</template>

<!-- prettier-ignore -->
<script lang="ts">export default { name: "PlanEditModal" };</script>

<script setup lang="ts">
import axios from "axios";
import { inject, Ref, ref, onMounted, watch, computed, watchEffect } from "vue";
import { v4 as uuid } from "uuid";
import isEqual from "lodash-es/isEqual";

// Components
import DrawingMap from "@views/Community/DrawingMap.vue";
import EllipsisAnimated from "@components/ui/EllipsisAnimated.vue";
import LinkWithIcon from "@components/LinkWithIcon.vue";
import NoteWithIcon from "@components/NoteWithIcon.vue";
import SrpButton from "@components/ui/SrpButton.vue";
import SrpDetailsSummary from "@components/ui/SrpDetailsSummary.vue";
import SrpModal from "@components/ui/SrpModal.vue";
import InfoIconWithTooltip from "@components/ui/InfoIconWithTooltip.vue";
import CopyTextNew from "@components/CopyTextNew.vue";
import LocationsList from "./LocationsList.vue";
import IconEmbedded from "@components/ui/IconEmbedded.vue";

// Types
import { ScreenSize } from "@contracts/screenSize";
import { CollabCreatorInput, CollabInput, VisitPlanFields } from "@contracts/collab";
import { Page } from "@contracts/pages";

// Stores
import { useCollabLocationsStore } from "@stores/collabLocations";
import { CollabLocationEmpty, CollabLocationsData } from "@contracts/collabLocations";
const collabLocationsStore = useCollabLocationsStore();

const props = withDefaults(
  defineProps<{
    showVisitPlanFields: boolean;
    isVisible: boolean;
    creatorInput: CollabCreatorInput | null;
    communityId: string;
    // NOTE! Only certain fields might be filled out when this is used in the CMS
    collabInput: CollabInputSummary;
    creatorId: string;
  }>(),
  {
    showVisitPlanFields: false,
    isVisible: true,
    creatorInput: null,
    communityId: "",
    collabInput: null,
    creatorId: "",
  }
);

interface CollabInputSummary {
  // Always set
  id: string;
  useLocationsInVisitPlan: boolean;
  useLocationsInAdventures: boolean;

  // Only set for editing the Visit Plan
  requestedLocationCount?: number;
  requiredAttractions?: string;
  suggestedAttractions?: string;
}

const emit = defineEmits<{
  (e: "update:isVisible", value: boolean): void;
  (e: "showChat"): void;
  (e: "updatePlan", value: VisitPlanFields): void;
}>();

watch(
  () => props.isVisible,
  value => {
    if (value) {
      CalculateLocationsList();
      errorMessage.value = null;
    }
  }
);

const screenSize = inject("screenSize") as Ref<ScreenSize>;
const globalRemoteLogger = inject("globalRemoteLogger") as any;

// Load plan text =============================================================
const _visitPlan = ref("");

const collabLocationsData = ref<CollabLocationsData>({ ...collabLocationsStore.collabLocationsData });
const isDirty = computed(() => {
  // NOTE: I don't think this works??
  return !isEqual(collabLocationsData.value, collabLocationsStore.collabLocationsData);
});
onMounted(async () => {
  console.info("Location PlanEditModal Mounted");
  CalculateLocationsList();
});

function CalculateLocationsList() {
  console.info("Location PlanEditModal CalculateLocationsList");
  _visitPlan.value = props.creatorInput?.visitPlan;
  if (props.showVisitPlanFields) {
    // pad the local cache of location data if necessary
    const locations = collabLocationsStore.collabLocationsData.locations;
    const requestedLocationCount = props.collabInput.requestedLocationCount;
    const hasVisitPlanBeenSent = props.creatorInput?.visitPlanSentDate != null;
    if (!hasVisitPlanBeenSent && locations.length < requestedLocationCount) {
      const paddingLocations = [...Array(props.collabInput.requestedLocationCount - collabLocationsData.value.locations.length)].map(() => new CollabLocationEmpty(uuid()));
      collabLocationsData.value.locations = [...collabLocationsData.value.locations, ...paddingLocations];
    }
  } else {
    console.info("Location PlanEditModal collabLocationsData set to " + collabLocationsStore.collabLocationsData.locations.length);
    collabLocationsData.value.locations = [...collabLocationsStore.collabLocationsData.locations];
  }
}

// Switch to chat =============================================================
function switchToChat() {
  emit("showChat");
}

// Save =======================================================================
const errorMessage = ref(null);
async function save(isReadyToSendToDestination = false): Promise<void> {
  globalRemoteLogger.info(
    `Saving Collab Locations Data. communityId: ${props.communityId}, creatorId: ${props.creatorId}, collabInputId: ${props.collabInput.id}, sendToDestination: ${isReadyToSendToDestination}`
  );
  await saveV2(isReadyToSendToDestination);
  await collabLocationsStore.loadCollabLocationsData(props.collabInput.id, props.creatorId, props.communityId);
}

// collab locations to save are only those locations that have been "filled out"
const collabLocationsDataToSave = computed<CollabLocationsData>(() => ({
  ...collabLocationsData.value,
  locations: collabLocationsData.value.locations.filter(location => location.title || location.externalId),
}));

async function saveV2(isReadyToSendToDestination = false): Promise<void> {
  if (isReadyToSendToDestination && collabLocationsDataToSave.value.locations.length < 4) {
    errorMessage.value = "Add Locations before sending to the destination.";
    return;
  }
  // NOTE: Keep this logic in sync with the LocationInputSnippet.vue
  if (collabLocationsDataToSave.value.locations.some(location => (location.title || location.externalId) && !location.latitude)) {
    errorMessage.value = "Locations must have a map position. Use the autocomplete or set a location manually on the map.";
    return;
  }

  const uri = `${import.meta.env.VITE_API_URL}/collabs/${props.communityId}/inputs/${props.collabInput.id}/creator/${
    props.creatorId
  }/update-visit-plan/v2?sendToDestination=${isReadyToSendToDestination}`;
  const config = { headers: { "Content-Type": "application/json" } };

  await axios.put(uri, JSON.stringify(collabLocationsDataToSave.value), config);
  collabLocationsStore.isCollabLocationsDataDirty = false;

  if (props.showVisitPlanFields) {
    const visitPlanFields: VisitPlanFields = {
      visitPlan: _visitPlan.value,
      visitPlanSentDate: isReadyToSendToDestination ? new Date().toISOString() : props.creatorInput.visitPlanSentDate,
      visitPlanSignOffDate: props.creatorInput.visitPlanSignOffDate,
    };
    emit("updatePlan", visitPlanFields);
  }
  emit("update:isVisible", false);
}

// Cancel plan editing ========================================================
function cancelPlanEditing() {
  setTimeout(() => (_visitPlan.value = props.creatorInput?.visitPlan), 500);
  emit("update:isVisible", false);
}

// Is plan was changed ========================================================
const isPlanWasChanged = computed<boolean>(() => {
  if (props.collabInput.useLocationsInVisitPlan || (!props.showVisitPlanFields && props.collabInput.useLocationsInAdventures)) {
    return isDirty.value;
  } else {
    return (_visitPlan.value || "") !== (props.creatorInput?.visitPlan || "");
  }
});

// Load community boundary data ===============================================
const page = ref<Page | null>(null);
const isLoadingPage = ref(false);

async function loadCommunityBoundaryData(communityId: string) {
  // Note: This is more than we need, could rework it if we wanted...
  isLoadingPage.value = true;

  let uri = `${import.meta.env.VITE_API_URL}/pages/${communityId}?isPublicFacing=true`;

  try {
    const { data } = await axios.get(uri);
    page.value = data.page;
  } catch (error) {
    globalRemoteLogger.error(`CommunityBoundaryData is not loaded. communityId: ${communityId}. Error: ${error}`);
  }

  isLoadingPage.value = false;
}

watchEffect(async () => {
  if (props.isVisible) {
    await loadCommunityBoundaryData(props.communityId);
  }
});
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";
@import "@/scss/variables.scss";

// Activity snippet ===========================================================
.activity-snippet {
  &__header {
    margin-bottom: 3px;
    display: flex;
  }

  &__title-icon {
    margin: 0 2px 0 -7px;
    font-size: 26px;
    line-height: 26px;
  }

  &__title {
  }

  &__content {
    white-space: pre-wrap;
  }
}

// Map placeholder ============================================================
.map-placeholder {
  height: 220px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 0;
  overflow: hidden;
  font-weight: bold;
  text-align: center;
  background: white;

  &__text {
    text-align: center;
  }

  &__bg-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    inset: 0 auto auto 0;
    z-index: -1;
    opacity: 0.7;
  }
}

// Boundary section ===========================================================
.boundary-section {
  &__header {
    margin-bottom: 10px;
  }

  &__title {
    margin-bottom: 7px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__description {
  }

  &__map {
    height: 450px;
    background: rgba(0, 0, 0, 0.1);

    :deep(.vue-map-container) {
      height: 100% !important;
    }

    :deep(*[data-name="re-draw button"]) {
      display: none;
    }
  }

  &__map-placeholder {
  }
}

// Plan edit form =============================================================
.plan-edit-form {
  color: #5b5b5b;
  font: 14px/19px sans-serif;

  &__title {
    margin-bottom: 15px;
  }

  &__description {
    margin-bottom: 25px;
  }

  &__notify-destination-note {
    margin-bottom: 15px;
  }

  &__plan-textarea {
    margin-bottom: 43px;
  }

  &__divider {
    margin-bottom: 24px;
    border-bottom: 1px rgba(0, 0, 0, 0.1) solid;
  }

  &__activities-and-boundary-spoiler {
  }

  &__activities-section {
    padding-top: 28px;
    margin-bottom: 22px;
    display: flex;
    justify-content: space-between;
  }

  &__activity-snippet {
    width: calc(50% - 14px);
    min-width: calc(50% - 14px);
  }

  &__boundary-section {
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .plan-edit-form {
    &__activities-section {
      flex-direction: column;
    }

    &__activity-snippet {
      width: 100%;
      min-width: 100%;
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .plan-edit-form {
    &__activities-section {
      flex-direction: column;
    }

    &__activity-snippet {
      width: 100%;
      min-width: 100%;
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

// Validation message
.validation-message {
  color: red;
  display: block;
  width: 100%;
  text-align: right;
  margin-bottom: 8px;
  font-family: $font-family;
}
</style>
