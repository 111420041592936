<template>
  <section v-if="!isInitialLoad" class="medium-spacer">
    <h2>Other Optional Emails</h2>
    <p>We may notify you from time to time of things like:</p>
    <ul>
      <li>New feature announcements</li>
      <li>Unique creator visit notifications</li>
      <li>New state expansion or coop programs</li>
    </ul>

    <SrpButton @click="toggleSubscriptionStatus" fill="outlined" size="tiny">
      <template v-if="isSubscribed">Unsubscribe</template>
      <template v-else>Subscribe</template>
    </SrpButton>
    <p v-if="subscriptionStatus === 'pending'">Please check your email to finish resubscribing!</p>
    <p class="error-loading" v-if="errorUpdatingSubscription">An error occurred updating settings. Please contact <CopyText /></p>
  </section>
</template>

<script setup lang="ts">
import { useUserProfileStore } from "@stores/userProfileStore";
import axios from "axios";
import { computed, onMounted, ref } from "vue";
import SrpButton from "./ui/SrpButton.vue";
import CopyText from "./CopyText.vue";

type MailchimpStatus = "subscribed" | "unsubscribed" | "pending" | "unknown";
interface MailchimpStatusResponseDto {
  status: MailchimpStatus;
}

const isInitialLoad = ref(true);
const subscriptionStatus = ref<MailchimpStatus>("unknown");
const isUpdatingSubscription = ref(false);
const errorUpdatingSubscription = ref(false);
const userProfileStore = useUserProfileStore();

const isSubscribed = computed(() => subscriptionStatus.value === "subscribed");

onMounted(async () => {
  try {
    const creatorId = userProfileStore.getViewingUserProfile.sherpaId;

    const { data } = await axios.get<MailchimpStatusResponseDto>(`${import.meta.env.VITE_API_URL}/profile/${creatorId}/notifications/mailchimp`);
    subscriptionStatus.value = data.status;
  } finally {
    isInitialLoad.value = false;
  }
});

async function toggleSubscriptionStatus() {
  try {
    const creatorId = userProfileStore.getViewingUserProfile.sherpaId;
    isUpdatingSubscription.value = true;
    errorUpdatingSubscription.value = false;

    const { data } = await axios.post<MailchimpStatusResponseDto>(`${import.meta.env.VITE_API_URL}/profile/${creatorId}/notifications/mailchimp`, {
      isSubscribed: !isSubscribed.value,
    });
    subscriptionStatus.value = data.status;
  } catch {
    errorUpdatingSubscription.value = true;
  } finally {
    isUpdatingSubscription.value = false;
  }
}
</script>

<style>
.error-updating {
  color: red;
}
</style>
