<template>
  <div class="homepage">
    <!--Customer Intro-->
    <div id="featured" class="hero white">
      <div class="ui segment blank">
        <div class="ui container">
          <div class="ui stackable two column grid">
            <div class="seven wide column">
              <h1 id="page-title">Connecting <br />Content Creators<br />and Destinations</h1>
              <div>
                <h3 id="page-subtitle">Multiply your marketing efforts with authentic user&#x2011;generated content created by regional content creators!</h3>
              </div>
              <a :href="globalGetLink('ForCommunities')" class="ui button primary float left" style="margin-top: 2em">For Destinations ></a>
              <a :href="globalGetLink('ForCreators')" class="ui button basic float left" style="margin-top: 2em">For Creators ></a>
            </div>
            <div class="nine wide column hide-on-mobile" id="shrpa-cover-photo" style="margin-bottom: 44em">
              <img style="float: right; border-radius: 5px; width: 1000px; position: absolute; left: 100px" :src="`${contentBaseUri}/images/misc/home/shrpa-main-photo3-mid.png`" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="hero white home-section no-top-margin pale-teal-bg">
      <div class="ui segment blank" style="background: transparent">
        <div class="ui container">
          <div class="ui three column relaxed stackable grid">
            <div class="column promo">
              <div class="promo-wrapper">
                <div class="icon">
                  <i class="heart large primary icon"></i>
                </div>
                <div>
                  <h3 class="ui header promo-header">
                    <div class="content">Effortless Creator Visits</div>
                    <div class="sub header differentiator-text">
                      Simplify creator visits with Shrpa.<br />
                      Set up, coordinate, and manage the entire process effortlessly.
                    </div>
                  </h3>
                </div>
              </div>
            </div>
            <div class="column promo">
              <div class="promo-wrapper">
                <div class="icon">
                  <i class="edit large primary icon"></i>
                </div>
                <div>
                  <h3 class="ui header promo-header">
                    <div class="content">Engage Diverse Perspectives</div>
                    <div class="sub header differentiator-text">Discover your community through a new set of eyes and connect with travelers seeking genuine experiences.</div>
                  </h3>
                </div>
              </div>
            </div>
            <div class="column promo">
              <div class="promo-wrapper">
                <div class="icon">
                  <i class="comments large primary icon"></i>
                </div>
                <div>
                  <h3 class="ui header promo-header">
                    <div class="content">Marketing content for all channels</div>
                    <div class="sub header differentiator-text">Multiply your team's efforts with amazing photos, write-ups, and experiences for any marketing channel.</div>
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Loader v-if="isLoading" class="recent-creator-visits__loader" />

    <div v-else>
      <div v-if="showCustomerQuotes">
        <Loader v-if="isLoadingCustomerQuotesAndLogos" class="recent-creator-visits__loader" />

        <div v-else class="logos-and-testimonials-carousels" style="margin: 0 auto">
          <h3 class="global-h3 logos-and-testimonials-carousels__title">Trusted Nationwide: Shrpa Connects Destinations with Top Travel Creators</h3>

          <HorizontalStepCarousel
            class="logos-and-testimonials-carousels__logos-carousel"
            itemWrapClassName="logos-row__logo-wrap"
            itemClassName="logos-row__logo"
            :stepSize="
              {
                'desktop-wide': 3,
                desktop: 3,
                laptop: 3,
                'tablet-large': 2,
                tablet: 2,
                mobile: 2,
              }[screenSize]
            "
            :transitionDuration="200"
          >
            <ul class="logos-row" style="width: 100%">
              <li class="logos-row__logo-wrap" v-for="(logoFileName, index) in customerLogos" :key="logoFileName">
                <img class="logos-row__logo" :src="`${contentBaseUri}/cms/images/orgs/${logoFileName}`" :alt="logoFileName" />
              </li>
            </ul>
          </HorizontalStepCarousel>

          <div class="logos-and-testimonials-carousels__divider"></div>

          <HorizontalStepCarousel
            class="logos-and-testimonials-carousels__quotes-carousel"
            itemWrapClassName="testimonials-row__item-wrap"
            itemClassName="testimonials-row__item"
            :stepSize="
              {
                'desktop-wide': 3,
                desktop: 3,
                laptop: 3,
                'tablet-large': 2,
                tablet: 1,
                mobile: 1,
              }[screenSize]
            "
            :transitionDuration="350"
          >
            <div class="testimonials-row" style="width: 100%">
              <ul class="testimonials-row__row-itself">
                <li class="testimonials-row__item-wrap" v-for="quote in customerQuotes" :key="quote.id">
                  <!-- Testimonial snippet -->
                  <div class="testimonial-snippet testimonials-row__item">
                    <div class="testimonial-snippet__logo-wrap">
                      <img v-if="quote.customerIcon" class="testimonial-snippet__logo" :src="`${contentBaseUri}/cms/images/orgs/${quote.customerIcon}`" :alt="quote.fullName" />
                    </div>

                    <div class="global-h5 testimonial-snippet__text" v-html="quote.quoteText.replace(quote.quoteAccentText, `<b>${quote.quoteAccentText}</b>`)"></div>

                    <div class="testimonial-snippet__divider"></div>

                    <div class="testimonial-snippet__name-and-position">
                      <div class="global-h4 testimonial-snippet__name">
                        {{ quote.fullName }}
                      </div>
                      <div class="testimonial-snippet__position">
                        {{ quote.position }}
                      </div>
                    </div>
                  </div>
                  <!-- / Testimonial snippet -->
                </li>
              </ul>
            </div>
          </HorizontalStepCarousel>

          <LinkWithIcon tag="router-link" class="logos-and-testimonials-carousels__see-all-link" :to="{ name: 'CustomerQuotes' }" iconInset="auto -5px auto auto" color="blue">
            <span>See more</span>
            <template #iconRight><IconEmbedded name="arrow-right_3" :size="17" /></template>
          </LinkWithIcon>

          <div class="logos-and-testimonials-carousels__divider"></div>
        </div>
      </div>

      <div class="hero home-section green-section" style="margin-bottom: 3em; background: white">
        <div class="ui container" style="width: var(--grid-content-full-width); padding-bottom: 45px; margin-bottom: 0; border-bottom: 1px rgba(0, 0, 0, 0.1) solid">
          <div class="creators-header-group" style="width: 100%; flex-direction: column; align-items: stretch">
            <div style="margin-top: 28px; padding-top: 10px">
              <h1 class="global-h1 ui header" style="width: 100%; font-size: 35px; line-height: 38px; text-align: center">Recent Creator Visits</h1>
            </div>
          </div>
          <div style="margin-top: 10px">
            <p id="page-subtitle" style="color: rgba(0, 0, 0, 0.5); font-family: sans-serif; text-align: center">
              Explore some creator visits that generated authentic content for our partner destinations!
            </p>
          </div>
        </div>
      </div>

      <ul class="recent-creator-visits__visit-summaries-list">
        <template v-for="(summarySection, index) in summarySections" :key="JSON.stringify(summarySection.itineraries)">
          <CollabSummarySection class="recent-creator-visits__summary-section" :summarySection="summarySection" />

          <div v-if="index !== summarySections.length - 1" style="margin-bottom: 70px; border-bottom: 1px rgba(0, 0, 0, 0.1) solid"></div>
        </template>
      </ul>
    </div>

    <div class="recent-creator-visits__foot-cta-snippets-list">
      <!-- Shrpa round logo -->
      <div class="shrpa-round-logo recent-creator-visits__srpa-round-logo">
        <img class="shrpa-round-logo__img" src="https://cdn.shrpa.com/images/shrpa_logo_white.png" alt="Shrpa" />
      </div>
      <!-- / Shrpa round logo -->

      <!-- Foot cta snippet -->
      <div class="foot-cta-snippet recent-creator-visits__foot-cta-snippet">
        <div class="global-h2 foot-cta-snippet__title">Destinations in need of authentic content?</div>
        <SrpButton class="foot-cta-snippet__cta-button" color="orange" size="big" :href="globalGetLink('ForCommunities')">
          <template #icon><IconEmbedded name="aperture_2" :size="27" color="rgba(255, 255, 255, 0.8)" /></template>
          &nbsp;Learn More
        </SrpButton>
      </div>
      <!-- / Foot cta snippet -->

      <!-- Foot cta snippet -->
      <div class="foot-cta-snippet recent-creator-visits__foot-cta-snippet">
        <div class="global-h2 foot-cta-snippet__title">Creators interested in working with destinations?</div>
        <SrpButton class="foot-cta-snippet__cta-button" color="orange" size="big" :href="globalGetLink('BecomingACreator')">
          <template #icon><IconEmbedded name="zap_2" :size="30" color="rgba(255, 255, 255, 0.8)" /></template>
          &nbsp;Creator Onboarding
        </SrpButton>
      </div>
      <!-- / Foot cta snippet -->
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import axios from "axios";
import { getInstance } from "@auth/authWrapper";
import { RouteHelper } from "@helpers/RouteHelper";
import { inject } from "vue";
import { useHead } from "@unhead/vue";
import FeatureFlags from "@/logic/FeatureFlags";

// Components
import Slider from "@components/Slider.vue";
import ItineraryTile from "@components/ItineraryTile.vue";
import SrpButton from "@components/ui/SrpButton.vue";
import CreatorAvatar from "@components/CreatorAvatar.vue";
import Loader from "@components/Loader/Loader.vue";
import HighlightsSection from "@components/HighlightsSection.vue";
import IconEmbedded from "@components/ui/IconEmbedded.vue";
import CollabSummarySection from "@components/CollabSummarySection.vue";
import HorizontalStepCarousel from "@components/HorizontalStepCarousel.vue";
import LinkWithIcon from "@components/LinkWithIcon.vue";

// Types
import { CollabSummaryModel } from "@contracts/collabCredits";
import { ScreenSize } from "@contracts/screenSize";
import { CustomerQuote, CustomerQuotesPayload } from "@contracts/pages";

export default defineComponent({
  name: "HomeV4",

  components: {
    LinkWithIcon,
    HorizontalStepCarousel,
    CollabSummarySection,
    IconEmbedded,
    HighlightsSection,
    Slider,
    Loader,
    CreatorAvatar,
    ItineraryTile,
    SrpButton,
  },

  data() {
    return {
      globalLog: inject("globalLog") as any,
      globalGetLink: inject("globalGetLink") as any,

      screenSize: inject("screenSize") as ScreenSize,

      // @ts-ignore
      contentBaseUri: globalThis.Bootstrap.Config.contentCdnBaseUri,
      isLoading: true,
      isAuthLoading: true,

      summarySections: [] as Array<CollabSummaryModel>,

      logosRowPosition: 0,
      testimonialsRowPosition: 0,

      isLoadingCustomerQuotesAndLogos: true,

      showCustomerQuotes: false,
      customerLogos: [] as Array<string>,
      customerQuotes: [] as Array<CustomerQuote>,
    };
  },

  watch: {
    screenSize() {
      this.logosRowPosition = 0;
      this.testimonialsRowPosition = 0;
    },
  },

  async mounted() {
    useHead({ title: "Shrpa - Home" });

    console.info("HomeV4 Mounted");
    // Fire the calls that don't need auth immediately
    await this.reloadUnauthenticatedData();

    // Watch for auth to load before firing the remaining calls
    const self = this;
    const authService = getInstance();
    self.isAuthLoading = authService.loading;
    if (self.isAuthLoading === false) {
      this.reloadAuthenticatedData();
    }
    authService.$watch("loading", loading => {
      if (loading === false) {
        return this.reloadAuthenticatedData();
      }
    });

    if (FeatureFlags.isFlagActive("EnableCustomerQuotes")) {
      this.showCustomerQuotes = true;
      await this.loadCustomerQuotes();
    }
  },

  methods: {
    async loadCustomerQuotes() {
      const uri = `${import.meta.env.VITE_API_URL}/customer-quotes?logosCount=30&quotesCount=6`;
      const { data } = await axios.get<CustomerQuotesPayload>(uri);

      this.customerLogos = data.logos;
      this.customerQuotes = data.customerQuotes;

      this.isLoadingCustomerQuotesAndLogos = false;
    },
    async reloadAllData() {
      await this.reloadUnauthenticatedData();
      this.reloadAuthenticatedData();
    },
    // Data that doesn't require any auth and we can fire immediately
    async reloadUnauthenticatedData() {
      this.summarySections = await this.loadPageDetails();
    },
    // Data that should let the auth code run before we send (in case the user is authenticated)
    reloadAuthenticatedData() {
      // None now that we changed how we show themes
    },

    async loadPageDetails(): Promise<Array<CollabSummaryModel>> {
      const uri = `${import.meta.env.VITE_API_URL}/collabsummary/recent`;
      const response = await axios.get(uri);
      this.isLoading = false;

      return response.data;
    },
  },
});
</script>

<style scoped lang="scss">
@import "@/scss/variables.scss";

// Logos row ==================================================================
.logos-row {
  padding: 0;
  margin: 0;
  display: flex;
  list-style: none;

  &__logo-wrap {
    height: 95px;
    padding-right: 71px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:last-child {
      padding-right: 0;
    }
  }

  &__logo {
    max-width: 190px;
    max-height: 95px;
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .logos-row {
    &__logo-wrap {
      height: 65px;
      padding-right: 45px;
    }

    &__logo {
      max-width: 130px;
      max-height: 65px;
    }
  }
}

// Testimonial snippet ========================================================
.testimonial-snippet {
  @include standardFont;
  box-sizing: border-box;
  padding: 25px 25px 25px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 10px 40px -10px rgba(0, 0, 0, 0.37);
  background: 25px 19px/42px 28px url(/images/quote-icon.svg) no-repeat;

  &__logo-wrap {
    width: 200px;
    height: 80px;
    display: flex;
    align-self: flex-end;
    justify-content: flex-end;
    align-items: center;
  }

  &__logo {
    width: 100%;
    height: 100%;
    margin-bottom: 17px;
    object-fit: contain;
    object-position: right;
  }

  &__text {
    margin-bottom: 18px;
    color: rgba(91, 91, 91, 1);
    font-weight: 500;
    line-height: 22px;
    white-space: pre-wrap;

    :deep(b) {
      color: rgba(0, 0, 0, 1) !important;
    }
  }

  &__divider {
    width: 200px;
    margin-bottom: 17px;
    border-bottom: 1px rgba(0, 0, 0, 0.1) solid;
  }

  &__name-and-position {
  }

  &__name {
  }

  &__position {
  }
}

// Testimonials row ===========================================================
.testimonials-row {
  padding: 30px 0 50px;

  &__row-itself {
    width: calc($desktop-wide-grid-col-width * 4 + $desktop-wide-grid-gap-width * 3 + $desktop-wide-grid-gap-width);
    min-width: calc($desktop-wide-grid-col-width * 4 + $desktop-wide-grid-gap-width * 3 + $desktop-wide-grid-gap-width);
    padding: 0;
    margin: 0;
    display: flex;
    align-items: flex-start;
    list-style: none;
    transition: transform 0.15s ease-in-out;
  }

  &__item-wrap {
    width: 100%;
    min-width: 100%;
    padding-right: $desktop-wide-grid-gap-width;

    &:last-child {
      width: calc(100% - $desktop-wide-grid-gap-width);
      min-width: calc(100% - $desktop-wide-grid-gap-width);
      padding-right: 0;
    }

    &--invisible {
      opacity: 0.2;
    }
  }

  &__item {
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
  .testimonials-row {
    &__row-itself {
      width: calc($desktop-grid-col-width * 4 + $desktop-grid-gap-width * 3 + $desktop-grid-gap-width);
      min-width: calc($desktop-grid-col-width * 4 + $desktop-grid-gap-width * 3 + $desktop-grid-gap-width);
    }

    &__item-wrap {
      padding-right: $desktop-grid-gap-width;

      &:last-child {
        width: calc(100% - $desktop-grid-gap-width);
        min-width: calc(100% - $desktop-grid-gap-width);
      }
    }
  }
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
  .testimonials-row {
    &__row-itself {
      width: calc($laptop-grid-col-width * 4 + $laptop-grid-gap-width * 3 + $laptop-grid-gap-width);
      min-width: calc($laptop-grid-col-width * 4 + $laptop-grid-gap-width * 3 + $laptop-grid-gap-width);
    }

    &__item-wrap {
      padding-right: $laptop-grid-gap-width;

      &:last-child {
        width: calc(100% - $laptop-grid-gap-width);
        min-width: calc(100% - $laptop-grid-gap-width);
      }
    }
  }
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
  .testimonials-row {
    &__row-itself {
      width: calc($tablet-large-grid-col-width * 4 + $tablet-large-grid-gap-width * 3 + $tablet-large-grid-gap-width);
      min-width: calc($tablet-large-grid-col-width * 4 + $tablet-large-grid-gap-width * 3 + $tablet-large-grid-gap-width);
    }

    &__item-wrap {
      padding-right: $tablet-large-grid-gap-width;

      &:last-child {
        width: calc(100% - $tablet-large-grid-gap-width);
        min-width: calc(100% - $tablet-large-grid-gap-width);
      }
    }
  }
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .testimonials-row {
    &__row-itself {
      width: calc($tablet-grid-col-width * 6 + $tablet-grid-gap-width * 5 + $tablet-grid-gap-width);
      min-width: calc($tablet-grid-col-width * 6 + $tablet-grid-gap-width * 5 + $tablet-grid-gap-width);
    }

    &__item-wrap {
      padding-right: $tablet-grid-gap-width;

      &:last-child {
        width: calc(100% - $tablet-grid-gap-width);
        min-width: calc(100% - $tablet-grid-gap-width);
      }
    }
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .testimonials-row {
    &__row-itself {
      width: calc($mobile-grid-col-width * 5 + $mobile-grid-gap-width * 4 + $mobile-grid-gap-width);
      min-width: calc($mobile-grid-col-width * 5 + $mobile-grid-gap-width * 4 + $mobile-grid-gap-width);
    }

    &__item-wrap {
      padding-right: $mobile-grid-gap-width;

      &:last-child {
        width: calc(100% - $mobile-grid-gap-width);
        min-width: calc(100% - $mobile-grid-gap-width);
      }
    }
  }
}

// Logos & testimonials carousel ==============================================
.logos-and-testimonials-carousels {
  width: $desktop-wide-content-width;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 2;

  &::before {
    content: "";
    width: calc(100% + 300px);
    height: calc(100% + 130px);
    position: absolute;
    inset: -130px auto auto -150px;
    z-index: -1;
    background: rgba(255, 255, 255, 1);
  }

  &__title {
    margin: 127px 0 60px;
    text-align: center;
    font-weight: 600;
  }

  &__logos-carousel {
    width: 100%;
    margin: 0 auto 100px;
  }

  &__divider {
    width: 100%;
    max-width: var(--grid-content-full-width);
    margin: 0 auto 75px;
    border-bottom: 1px rgba(0, 0, 0, 0.1) solid;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__quotes-carousel {
    width: 100%;
    margin: 0 auto 25px;
  }

  &__see-all-link {
    margin-bottom: 70px;
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
  .logos-and-testimonials-carousels {
    width: $desktop-content-width;
  }
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
  .logos-and-testimonials-carousels {
    width: $laptop-content-width;
  }
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
  .logos-and-testimonials-carousels {
    width: $tablet-large-content-width;

    &::before {
      width: calc(100% + 300px);
      height: calc(100% + 80px);
      inset: -80px auto auto -150px;
    }

    &__title {
      margin: 100px 0 50px;
    }

    &__logos-carousel {
      margin: 0 auto 90px;
    }

    &__divider {
      margin: 0 auto 65px;
    }

    &__quotes-carousel {
      margin: 0 auto 25px;
    }
  }
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .logos-and-testimonials-carousels {
    width: $tablet-content-width;

    &::before {
      width: calc(100% + 300px);
      height: calc(100% + 80px);
      inset: -80px auto auto -150px;
    }

    &__title {
      margin: 80px 0 50px;
    }

    &__logos-carousel {
      margin: 0 auto 70px;
    }

    &__divider {
      margin: 0 auto 45px;
    }

    &__quotes-carousel {
      margin: 0 auto 25px;
    }
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .logos-and-testimonials-carousels {
    width: $mobile-content-width;

    &::before {
      width: calc(100% + 300px);
      height: calc(100% + 40px);
      inset: -40px auto auto -150px;
    }

    &__title {
      margin: 57px 0 30px;
    }

    &__logos-carousel {
      margin: 0 auto 50px;
    }

    &__divider {
      margin: 0 auto 35px;
    }

    &__quotes-carousel {
      margin: 0 auto 15px;
    }
  }
}

// Pale teal bg ===============================================================
.pale-teal-bg {
  position: relative;
  z-index: 3;
  background: transparent;

  &::before {
    content: "";
    width: 100%;
    height: calc(100% + 20px);
    position: absolute;
    inset: -20px auto auto 0;
    z-index: -1;
    background: #e6edf0;
    pointer-events: none;
  }
}

.column.promo {
  .promo-wrapper {
    display: flex;
    .icon {
      margin-right: 0.5rem;
    }
    h3.promo-header {
      .content {
        margin-bottom: 1rem;
      }
    }
  }
}

.ui.segment.blank {
  border: none;
  box-shadow: none;
}

.green-section {
  background-color: $brand-color-lighter;
}

.differentiator-text {
  font-size: 0.9em !important;
}

#featured {
  @media screen and (max-width: 420px) {
    margin-top: 2em;
  }
}

#page-title {
  color: rgba(5, 133, 135, 1);
  font-size: 3.2rem;
  margin-top: 2em;
  @media screen and (max-width: 420px) {
    font-size: 2.5em;
    margin-top: 0em;
  }
}

#page-subtitle {
  font-size: 15pt;
  @media screen and (max-width: 420px) {
    font-size: 12pt;
  }
}

#shrpa-cover-photo {
  @media screen and (max-width: 420px) {
    visibility: hidden;
  }
}

//******************** From RecentCreatorVisits.vue (decide if we should unify or kill that page) *******************************/
@import "@/scss/screen-size-ranges.scss";

//Note: Removed the stats styles

// Foot Cta snippet ===========================================================
.foot-cta-snippet {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__title {
    max-width: 70%;
    margin-bottom: 28px;
    text-align: center;
  }

  &__cta-button {
    outline: none;
  }
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .foot-cta-snippet {
    &__title {
      max-width: 100%;
    }
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .foot-cta-snippet {
    &__title {
      margin-bottom: 15px;
    }
  }
}

// Shrpa round logo ===========================================================
.shrpa-round-logo {
  width: 70px;
  height: 70px;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #bcc2c5;

  &__img {
    width: 42%;
    position: relative;
    top: -1px;
  }
}

// Recent creator visits ======================================================
.recent-creator-visits {
  &__title-n-stats {
    margin-bottom: 150px;
  }

  &__loader {
    margin-bottom: 150px;
  }

  &__visit-summaries-list {
    width: 1269px;
    padding: 0;
    margin: 0 auto 150px;
    list-style: none;
  }

  &__summary-section {
    margin-bottom: 80px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__foot-cta-snippets-list {
    margin: 0 auto;
    padding: 95px 0 115px;
    display: flex;
    justify-content: center;
    position: relative;
    background: #e7edf0;

    &::before {
      content: "";
      width: 1px;
      height: calc(100% - 67px - 93px);
      position: absolute;
      inset: 67px auto auto 50%;
      background: rgba(0, 0, 0, 0.15);
    }
  }

  &__foot-cta-snippet {
    width: calc(1269px / 2 - 15px);
    margin-right: 30px;

    &:last-child {
      margin-right: 0;
    }
  }

  &__srpa-round-logo {
    position: absolute;
    inset: auto auto -35px calc(50% - 35px);
  }
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
  .recent-creator-visits {
    &__visit-summaries-list {
      width: 1104px;
    }

    &__foot-cta-snippet {
      width: calc(1104px / 2 - 12px);
      margin-right: 24px;
    }
  }
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
  .recent-creator-visits {
    &__title-n-stats {
      margin-bottom: 100px;
    }

    &__visit-summaries-list {
      width: 762px;
      margin-bottom: 110px;
    }

    &__summary-section {
      margin-bottom: 60px;
    }

    &__foot-cta-snippet {
      width: calc(762px / 2 - 12px) !important;
      margin-right: 25px;
    }
  }
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .recent-creator-visits {
    &__title-n-stats {
      margin-bottom: 60px;
    }

    &__visit-summaries-list {
      width: 502px;
      margin-bottom: 70px;
    }

    &__summary-section {
      margin-bottom: 45px;
    }

    &__foot-cta-snippets-list {
      padding: 60px 0 75px;
      flex-direction: column;
      align-items: center;

      &::before {
        display: none;
      }
    }

    &__foot-cta-snippet {
      width: 502px;
      margin: 0 0 70px;
      position: relative;

      &:last-child {
        margin-bottom: 0;
      }

      &:last-child::before {
        content: "";
        width: 502px;
        height: 1px;
        position: absolute;
        inset: -35px auto auto calc(50% - 251px);
        background: rgba(0, 0, 0, 0.15);
      }
    }
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .recent-creator-visits {
    &__title-n-stats {
      margin-bottom: 60px;
    }

    &__visit-summaries-list {
      width: calc(100% - 36px);
      margin-bottom: 30px;
    }

    &__summary-section {
      margin-bottom: 35px;
    }

    &__foot-cta-snippets-list {
      padding: 55px 0 80px;
      flex-direction: column;
      align-items: center;

      &::before {
        display: none;
      }
    }

    &__foot-cta-snippet {
      width: calc(100% - 36px);
      margin: 0 0 60px;
      position: relative;

      &:last-child {
        margin-bottom: 0;
      }

      &:last-child::before {
        content: "";
        width: 100%;
        height: 1px;
        position: absolute;
        inset: -25px auto auto 0;
        background: rgba(0, 0, 0, 0.15);
      }
    }
  }
}
</style>
