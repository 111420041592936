<template>
  <div
    :class="{
      'status-stripe': true,
      'status-stripe--green': props.color === 'green',
      'status-stripe--medium': props.size === 'medium',
    }"
  >
    <IconEmbedded v-if="props.isWithCheckIcon" name="check_4-5" color="rgba(84, 158, 63, 1)" :size="12" style="margin-right: -2px; position: relative; top: 1px" />
    {{ props.text }}
  </div>
</template>

<script setup lang="ts">
// Components
import IconEmbedded from "@components/ui/IconEmbedded.vue";

const props = withDefaults(
  defineProps<{
    color?: "white" | "green";
    isWithCheckIcon?: boolean;
    text: string;
    size?: "small" | "medium";
  }>(),
  {
    color: "white",
    isWithCheckIcon: false,
    text: "Status stripe",
    size: "small",
  }
);
</script>

<style scoped lang="scss">
// Status stripe ==============================================================
.status-stripe {
  height: 20px;
  padding: 2px 17px 0 7px;
  box-sizing: border-box;
  border: 1px rgba(0, 0, 0, 0.3) solid;
  border-width: 1px 0 1px 1px;
  position: relative;
  z-index: 0;
  overflow: hidden;
  font:
    500 14px/14px "Quicksand",
    sans-serif;
  white-space: nowrap;

  // size -----------------------------
  &--medium {
    height: 28px;
    padding: 3px 19px 0 10px;
    font-size: 16px;
    line-height: 16px;
  }

  &::before,
  &::after {
    content: "";
    width: 1000px;
    height: 100%;
    position: absolute;
    inset: 0 0 auto auto;
    z-index: -2;
    clip-path: polygon(0 0, 100% 0, 99.1% 50%, 100% 100%, 0 100%);
    background: rgba(0, 0, 0, 0.3);
  }

  &::after {
    z-index: -1;
    right: 1.5px;
    background: rgba(255, 255, 255, 1);
  }

  &--green {
    border-color: rgba(206, 228, 200, 1);
    color: rgba(44, 117, 23, 1);

    &::before,
    &::after {
      background: rgba(206, 228, 200, 1);
    }
  }
}
</style>
