<template>
  <div class="upcoming-collab-snippet">
    <!-- Logo container -->
    <div class="logo-container upcoming-collab-snippet__logo-container">
      <img class="logo-container__img" :src="`${contentBaseUri}/cms/images/orgs/${props.destination.icon}`" alt="partner logo" />
    </div>
    <!-- / Logo container -->

    <div class="upcoming-collab-snippet__text-container">
      <div class="upcoming-collab-snippet__title-n-subtitle">
        <h2 class="global-h2 upcoming-collab-snippet__title">{{ props.destination.communityName }}, {{ props.destination.customerState }}</h2>
        <!--<div class="upcoming-collab-snippet__subtitle">{{props.destination}}</div>-->
      </div>

      <ul class="upcoming-collab-snippet__details-snippets-list">
        <!-- Details snippet -->
        <li class="details-snippet details-snippet--location upcoming-collab-snippet__details-snippet">
          <IconEmbedded class="details-snippet__icon" name="map-pin_2" :size="18" color="rgba(0, 0, 0, 0.5)" />

          <div class="details-snippet__text-content">
            <span class="details-snippet__description">
              {{ props.destination.proximityInMiles }} miles away
              <template v-if="props.destination.customerState">
                in <b>{{ props.destination.customerState }}</b>
              </template>
            </span>
          </div>
        </li>
        <!-- / Details snippet -->

        <!-- Details snippet -->
        <li v-if="props.destination.collabThemeType" class="details-snippet details-snippet--visit-focus upcoming-collab-snippet__details-snippet">
          <img class="details-snippet__icon details-snippet__icon--theme-icon" :src="getThemeIcon(props.destination.collabThemeType)" />

          <div class="details-snippet__text-content">
            <span class="global-h6 details-snippet__title"> Visit Focus: {{ props.destination.collabThemes }} </span>
          </div>
        </li>
        <!-- / Details snippet -->
      </ul>
    </div>

    <template v-if="props.destination.collabsNotYetPostedCount">
      <div class="upcoming-collab-snippet__divider"></div>

      <!-- Collab counter -->
      <div class="collab-counter upcoming-collab-snippet__collab-counter">
        <div class="global-h1 collab-counter__big-number">{{ props.destination.collabsNotYetPostedCount }}</div>
        <div class="collab-counter__description">{{ props.destination.collabsNotYetPostedCount === 1 ? "Creator Visit" : "Creator Visits" }}<br />Coming up</div>
      </div>
      <!-- / Collab counter -->
    </template>
  </div>
</template>

<script setup lang="ts">
import { getThemeIcon } from "@helpers/ThemeTypeHelper";

// Components
import IconEmbedded from "@components/ui/IconEmbedded.vue";
import { DestinationCollabSummaryForCreator } from "@contracts/collabCredits";

// Global variables
const contentBaseUri = globalThis.Bootstrap.Config.contentCdnBaseUri;

const props = withDefaults(
  defineProps<{
    destination: DestinationCollabSummaryForCreator;
  }>(),
  {
    destination: null,
  }
);
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";

// Logo container =============================================================
.logo-container {
  max-width: 140px;
  height: 65px;
  display: flex;
  justify-content: center;
  align-items: center;

  &__img {
    max-width: 100%;
    max-height: 100%;
  }
}

// Details snippet ============================================================
.details-snippet {
  display: flex;
  align-items: center;

  &--location {
  }
  &--visit-focus {
  }

  &__icon {
    margin-right: 4px;

    &--theme-icon {
      width: 24px;
      height: 24px;
      position: relative;
      top: -1px;
    }
  }

  &__text-content {
  }

  &__title {
    margin-right: 12px;
  }

  &__description {
    color: rgba(91, 91, 91, 1);
    font-family: sans-serif;
  }

  &__read-more-link {
  }
}

// Collab counter =============================================================
.collab-counter {
  display: flex;
  align-items: center;

  &__big-number {
    margin-right: 6px;
    font-weight: 400;
  }

  &__description {
    color: rgba(91, 91, 91, 1);
    font: 14px/18px sans-serif;
  }
}

// Upcoming collab snippet ====================================================
.upcoming-collab-snippet {
  padding: 20px 21px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  background: rgba(255, 255, 255, 1);

  &__logo-container {
    margin-right: 25px;
  }

  &__text-container {
    flex-grow: 1;
  }

  &__title-n-subtitle {
    margin-bottom: 10px;
  }

  &__title {
    margin-bottom: 2px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__subtitle {
    color: rgba(91, 91, 91, 1);
    font-size: 18px;
    font-weight: 500;
  }

  &__details-snippets-list {
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    list-style: none;
  }

  &__details-snippet {
    margin-right: 29px;

    &:last-child {
      margin-right: 0;
    }
  }

  &__divider {
    align-self: stretch;
    width: 0;
    height: auto;
    margin-right: 25px;
    border-left: 1px rgba(0, 0, 0, 0.1) solid;
  }

  &__collab-counter {
    width: 175px;
    min-width: 175px;
    height: 100%;
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
  .upcoming-collab-snippet {
    &__collab-counter {
      width: 155px;
      min-width: 155px;
    }
  }
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
  .upcoming-collab-snippet {
    &__collab-counter {
      width: 113px;
      min-width: 113px;
    }
  }
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
  .upcoming-collab-snippet {
    &__divider {
      margin-right: 17px;
    }

    &__collab-counter {
      width: 105px;
      min-width: 115px;
    }
  }
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .upcoming-collab-snippet {
    flex-wrap: wrap;
    align-items: center;
    align-content: center;

    &__text-container {
      display: contents;
    }

    &__title-n-subtitle {
      width: 100%;
      order: 1;
    }

    &__logo-container {
      order: 2;
      margin-bottom: 12px;
    }

    &__divider {
      order: 3;
      height: 40px;
      margin-bottom: 12px;
      align-self: center;
    }

    &__collab-counter {
      order: 4;
      margin-bottom: 12px;
    }

    &__details-snippets-list {
      order: 5;
      flex-direction: column;
      align-items: flex-start;
    }
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .upcoming-collab-snippet {
    flex-wrap: wrap;
    align-items: center;
    align-content: center;

    &__text-container {
      display: contents;
    }

    &__title-n-subtitle {
      width: 100%;
      order: 1;
    }

    &__logo-container {
      order: 2;
      margin-bottom: 12px;
    }

    &__divider {
      order: 3;
      height: 40px;
      margin-bottom: 12px;
      align-self: center;
    }

    &__collab-counter {
      order: 4;
      width: 110px;
      min-width: 110px;
      margin-bottom: 12px;
    }

    &__details-snippets-list {
      order: 5;
      flex-direction: column;
      align-items: flex-start;
    }
  }
}
</style>
