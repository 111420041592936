import { shrpaApi } from "./axios";
import { CustomerCollabActionSummary } from "@contracts/collabActionSummaries";
import { CustomCollabClientPayload, CustomCollabCustomerData } from "@contracts/customCollabs";

export default {
  async getAllCustomCollabs(customerId: string): Promise<Array<CustomerCollabActionSummary>> {
    const uri = `/customers/${customerId}/custom-collabs`;
    const { data } = await shrpaApi.get<Array<any>>(uri);

    return data;
  },

  async getCustomCollab(customerId: string, customCollabId: string): Promise<CustomCollabClientPayload> {
    const uri = `/customers/${customerId}/custom-collabs/${customCollabId}`;
    const { data } = await shrpaApi.get<CustomCollabClientPayload>(uri);

    return data;
  },

  async saveCustomCollab(customerId: string, customCollabId: string, customerCollabInputs: CustomCollabCustomerData): Promise<CustomCollabClientPayload> {
    const uri = `/customers/${customerId}/custom-collabs/${customCollabId}`;
    const config = { headers: { "Content-Type": "application/json" } };
    const payload = JSON.stringify(customerCollabInputs);

    const { data } = await shrpaApi.put<CustomCollabClientPayload>(uri, payload, config);

    return data;
  },
};
