<template>
  <div class="deliverables-form-wrap">
    <SrpDetailsSummary>
      <template #heading>
        <div class="global-h4">
          <span><span>Deliverables</span></span>
        </div>
      </template>

      <template #details>
        <!-- Deliverables form -->
        <div class="deliverables-form">
          <div class="global-h5 deliverables-form__title">Download links</div>
          <input
            class="global-text-input deliverables-form__download-link-input"
            v-for="(_, index) in linkValues.slice(0, howManyFieldsVisible)"
            type="text"
            placeholder="https://drive.google.com/..."
            v-model="linkValues[index]"
          />

          <LinkWithIcon
            class="deliverables-form__add-link"
            isDottedUnderline
            iconInset="auto -3px auto auto"
            @click="howManyFieldsVisible += 1"
            :isDisabled="howManyFieldsVisible === maxNumberOfFields"
          >
            <template #icon><IconEmbedded name="plus_3" :size="18" /></template>
            <span>add another</span>
          </LinkWithIcon>

          <SrpCheckbox class="deliverables-form__is-ready-checkbox">
            <template #input><input type="checkbox" /></template>
            <template #text>Deliverables are ready</template>
          </SrpCheckbox>
        </div>
        <!-- / Deliverables form -->
      </template>
    </SrpDetailsSummary>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";

// Components
import SrpDetailsSummary from "@components/ui/SrpDetailsSummary.vue";
import LinkWithIcon from "@components/LinkWithIcon.vue";
import IconEmbedded from "@components/ui/IconEmbedded.vue";
import SrpCheckbox from "@components/ui/SrpCheckbox.vue";

// Link field values ==========================================================
const maxNumberOfFields = 10;
const howManyFieldsVisible = ref<number>(1);

const linkValues = ref<Array<string>>([...new Array(maxNumberOfFields)].map(() => ""));
</script>

<style scoped lang="scss">
// Deliverables form ==========================================================
.deliverables-form {
  padding-top: 15px;
  border-top: 1px rgba(0, 0, 0, 0.1) solid;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &__title {
    margin-bottom: 6px;
    font-weight: 400;
  }

  &__download-link-input {
    width: 100%;
    max-width: 440px;
    margin-bottom: 6px;
  }

  &__add-link {
    margin: 0 0 16px -7px;
  }

  &__is-ready-checkbox {
  }
}

// Deliverables form wrap =====================================================
.deliverables-form-wrap {
  padding: 14px 20px 16px;
  border-radius: 6px;
  background: rgba(0, 0, 0, 0.08);
}
</style>
