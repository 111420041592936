<template>
  <div class="custom-collab-details-page">
    <!--<LinkWithIcon tag="RouterLink" class="custom-collab-details-page__back-link"
        :to="{ name: 'CustomerCustomCollabs', params: { communityId: customCollabClientPayload?.customerCollabInputs.customerId } }">
      <template #icon><IconEmbedded name="arrow-left_2-5" :size="17" /></template>

      <span>Custom Collabs</span>
    </LinkWithIcon>
    <div class="custom-collab-details-page__divider"></div>
    -->

    <Loader v-if="isLoading" />

    <template v-else>
      <h1 class="global-h1 custom-collab-details-page__title">
        {{ collabTitle }}
      </h1>

      <div class="custom-collab-details-page__content">
        <div class="custom-collab-details-page__content-left-col">
          <div class="custom-collab-details-page__white-snippet-wrap" style="padding: 0; display: flex; flex-direction: column">
            <h3 class="global-h3" style="margin: 20px 24px 20px">Messages</h3>

            <CollabMessagesThread
              style="flex-grow: 1"
              :isImpersonationToggleVisible="false"
              :isAutoScrollToBottom="false"
              :isAutoFocusTextarea="false"
              :isMsgBubblesFullWidth="true"
              :messageThreadId="customCollabClientPayload?.customerCollabInputs?.messageThreadId"
              :customerId="customCollabClientPayload?.customerCollabInputs?.customerId"
              messageSenderType="CustomerId"
              @isImpersonateChange="() => {}"
              :customCollabId="customCollabClientPayload?.customerCollabInputs?.id"
              :isEscalateContextVisible="false"
            />
          </div>
        </div>

        <div class="custom-collab-details-page__content-right-col">
          <WorkStatusSnippet v-if="!isInPlanning" class="custom-collab-details-page__work-status-snippet" :customCollabClientPayload="customCollabClientPayload" />

          <CollabDetailsForm class="custom-collab-details-page__collab-details-snippet" :customCollabClientPayload="customCollabClientPayload" :isOpened="!isInPlanning" @save="save" />

          <div v-if="customCollabClientPayload.creatorCollabInputs.status !== CustomCollabStatuses.Complete" class="custom-collab-details-page__white-snippet-wrap">
            <ContactShrpaAndEmails
              :pageId="customerId"
              :contactEmails="customCollabClientPayload?.customerCollabInputs.contactEmail"
              :isSaving="false"
              :showContactShrpa="false"
              :collabName="null"
              @saveEmails="saveContactEmail"
            />
          </div>

          <HowItWork class="custom-collab-details-page__how-it-work-snippet" :isOpened="isInPlanning" />
        </div>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref, computed } from "vue";
import CustomCollabsRepo from "@repos/CustomCollabsRepo";
import { useRoute, useRouter } from "vue-router";

// Components
import LinkWithIcon from "@components/LinkWithIcon.vue";
import IconEmbedded from "@components/ui/IconEmbedded.vue";
import WorkStatusSnippet from "./WorkStatusSnippet.vue";
import CollabDetailsForm from "./CollabDetailsForm.vue";
import ContactShrpaAndEmails from "@views/PaidCollab/CollabSummary/ContactShrpaAndEmails.vue";
import HowItWork from "./HowItWork.vue";
import CollabMessagesThread from "@views/PaidCollab/CollabMessagesThread/index.vue";
import Loader from "@components/Loader/Loader.vue";

// Types
import { CustomCollabClientPayload, CustomCollabStatuses } from "@contracts/customCollabs";

const route = useRoute();

// Check Is Completed =========================================================
const isCompleted = computed<boolean>(() => {
  return customCollabClientPayload.value.creatorCollabInputs.status === CustomCollabStatuses.Complete;
});

// Computed properties =========================================================
const isInPlanning = computed<boolean>(() => {
  return customCollabClientPayload.value?.creatorCollabInputs?.status === CustomCollabStatuses.Planning;
});

// Computed title with fallback ===============================================
const collabTitle = computed<string>(() => {
  return customCollabClientPayload.value?.customerCollabInputs.title ?? `Custom Collab with ${customCollabClientPayload.value?.creator?.creatorName}`;
});

// Load Custom collab =========================================================
const isLoading = ref<boolean>(true);
const customCollabClientPayload = ref<CustomCollabClientPayload | null>(null);

let customerId = ref<string>("");
let customCollabId = ref<string>("");

onMounted(async () => {
  isLoading.value = true;

  customerId.value = String(route.params.pageId);
  customCollabId.value = String(route.params.customCollabId);
  customCollabClientPayload.value = await CustomCollabsRepo.getCustomCollab(customerId.value, customCollabId.value);

  isLoading.value = false;
});

// Add the saveContactEmail method
function saveContactEmail(emails: Array<string>) {
  customCollabClientPayload.value.customerCollabInputs.contactEmail = emails.join(";");
  save();
}

// Add the save method
async function save() {
  isLoading.value = true;
  const newCustomCollabClientPayload = await CustomCollabsRepo.saveCustomCollab(String(route.params.pageId), String(route.params.customCollabId), customCollabClientPayload.value.customerCollabInputs);
  customCollabClientPayload.value = newCustomCollabClientPayload;
  isLoading.value = false;
}
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";

// Custom collab details page =================================================
.custom-collab-details-page {
  width: $desktop-wide-right-col-width;
  margin: 0 auto;

  &__back-link {
    margin-bottom: 20px;
  }

  &__divider {
    margin-bottom: 30px;
    border-bottom: 1px rgba(0, 0, 0, 0.15) solid;
  }

  &__title {
    margin-bottom: 27px;
  }

  &__content {
    min-height: 700px;
    gap: $desktop-wide-grid-gap-width;
    padding: 30px 0;
    display: flex;
    position: relative;
    z-index: 0;

    &::before {
      content: "";
      width: calc(100% + 50px);
      height: 100%;
      border: 1px rgba(0, 0, 0, 0.15) solid;
      border-radius: 6px;
      position: absolute;
      inset: 0 auto auto -25px;
      z-index: -1;
    }
  }

  &__content-left-col {
    width: calc($desktop-wide-grid-col-width * 3 + $desktop-wide-grid-gap-width * 2);
    min-width: calc($desktop-wide-grid-col-width * 3 + $desktop-wide-grid-gap-width * 2);
    display: flex;
    align-items: stretch;
  }

  &__content-right-col {
    flex-grow: 1;
    gap: $desktop-wide-grid-gap-width;
    display: flex;
    flex-direction: column;
  }

  &__work-status-snippet {
  }

  &__collab-details-snippet {
  }

  &__how-it-work-snippet {
  }

  &__white-snippet-wrap {
    width: 100%;
    padding: 20px 25px 24px;
    box-sizing: border-box;
    border-radius: 6px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0 3px 15px -5px rgba(0, 0, 0, 0.4);
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
  .custom-collab-details-page {
    width: $desktop-right-col-width;
  }
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
}
</style>
