<template>
  <SrpDetailsSummary class="collab-details-form-wrap" :isOpened="isOpened">
    <template #heading>
      <h3 class="global-h3">
        <span><span>Collab Details</span></span>
      </h3>
    </template>
    <template #details>
      <div style="margin-bottom: 20px; border-bottom: 1px rgba(0, 0, 0, 0.15) solid"></div>

      <!-- Collab details form -->
      <div
        :class="{
          'collab-details-form': true,
          'collab-details-form--disabled': isSaving,
        }"
      >
        <SrpButton v-if="!isCompleted" class="collab-details-form__ai-button" size="small" fill="outlined">
          <template #icon><IconEmbedded name="ai_3" :size="17" /></template>
          Fill from messages with AI
        </SrpButton>

        <div class="collab-details-form__form-itself">
          <!-- Title and form element -->
          <div class="title-and-form-element collab-details-form__title-and-form-element">
            <div class="global-h4 title-and-form-element__title">Title</div>
            <input class="global-text-input title-and-form-element__form-element" type="text" v-model="props.customCollabClientPayload.customerCollabInputs.title" :disabled="isCompleted" />
          </div>
          <!-- / Title and form element -->

          <!-- Title and form element -->
          <div class="title-and-form-element collab-details-form__title-and-form-element">
            <div class="global-h4 title-and-form-element__title">What's the purpose of the work?</div>
            <textarea
              class="global-textarea title-and-form-element__form-element title-and-form-element__form-element--textarea"
              placeholder="Ex. Location names or context, your social media handle, etc..."
              v-model="props.customCollabClientPayload.customerCollabInputs.purpose"
              :disabled="isCompleted"
            ></textarea>
          </div>
          <!-- / Title and form element -->

          <!-- Title and form element -->
          <div class="title-and-form-element collab-details-form__title-and-form-element">
            <div class="global-h4 title-and-form-element__title">Deliverables</div>
            <textarea
              class="global-textarea title-and-form-element__form-element title-and-form-element__form-element--textarea"
              type="text"
              placeholder="Ex. Location names or context, your social media handle, etc..."
              v-model="props.customCollabClientPayload.customerCollabInputs.deliverables"
              :disabled="isCompleted"
            ></textarea>
          </div>
          <!-- / Title and form element -->

          <!-- Title and form element -->
          <div class="title-and-form-element collab-details-form__title-and-form-element collab-details-form__title-and-form-element--half-width">
            <div class="global-h4 title-and-form-element__title">Payment</div>
            <input class="global-text-input title-and-form-element__form-element" type="number" v-model="props.customCollabClientPayload.customerCollabInputs.paymentAmount" :disabled="isCompleted" />
          </div>
          <!-- / Title and form element -->

          <!-- Title and form element -->
          <div class="title-and-form-element collab-details-form__title-and-form-element collab-details-form__title-and-form-element--half-width">
            <div class="global-h4 title-and-form-element__title">Other Compensation</div>
            <input
              class="global-text-input title-and-form-element__form-element"
              type="text"
              placeholder="e.g. Hotel stay"
              v-model="props.customCollabClientPayload.customerCollabInputs.otherComp"
              :disabled="isCompleted"
            />
          </div>
          <!-- / Title and form element -->

          <!-- Title and form element -->
          <div class="title-and-form-element collab-details-form__title-and-form-element collab-details-form__title-and-form-element--half-width">
            <div class="global-h4 title-and-form-element__title">Activity Date</div>
            <input class="global-text-input title-and-form-element__form-element" type="date" v-model="props.customCollabClientPayload.customerCollabInputs.activityDate" :disabled="isCompleted" />
          </div>
          <!-- / Title and form element -->

          <!-- Title and form element -->
          <div class="title-and-form-element collab-details-form__title-and-form-element collab-details-form__title-and-form-element--half-width">
            <div class="global-h4 title-and-form-element__title">Due Date</div>
            <input class="global-text-input title-and-form-element__form-element" type="date" v-model="props.customCollabClientPayload.customerCollabInputs.dueDate" :disabled="isCompleted" />
          </div>
          <!-- / Title and form element -->

          <!-- Title and form element -->
          <div class="title-and-form-element collab-details-form__title-and-form-element">
            <div class="global-h4 title-and-form-element__title">Additional Notes</div>
            <textarea
              class="global-textarea title-and-form-element__form-element title-and-form-element__form-element--textarea"
              placeholder="Any other details..."
              v-model="props.customCollabClientPayload.customerCollabInputs.otherNotes"
              :disabled="isCompleted"
            ></textarea>
          </div>
          <!-- / Title and form element -->
        </div>

        <div v-if="!isCompleted" class="collab-details-form__buttons-panel">
          <SrpButton @click="$emit('save')" :isDisabled="isSaving">
            <template #icon>
              <LoadingIcon v-if="isSaving" :size="20" />
              <IconEmbedded v-else name="save_2-5" :size="20" />
            </template>
            Save
          </SrpButton>
          <SrpButton fill="outlined" :isDisabled="isSaving">
            <template #icon><IconEmbedded name="send_2-5" :size="20" /></template>
            Send to Creator
          </SrpButton>
        </div>
      </div>
      <!-- / Collab details form -->
    </template>
  </SrpDetailsSummary>
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import CustomCollabsRepo from "@repos/CustomCollabsRepo";
import { useRoute, useRouter } from "vue-router";

// Components
import SrpDetailsSummary from "@components/ui/SrpDetailsSummary.vue";
import SrpButton from "@components/ui/SrpButton.vue";
import IconEmbedded from "@components/ui/IconEmbedded.vue";

// Types
import { CustomCollabClientPayload, CustomCollabStatuses } from "@contracts/customCollabs";
import LoadingIcon from "@components/ui/LoadingIcon.vue";

const props = withDefaults(
  defineProps<{
    customCollabClientPayload: CustomCollabClientPayload | null;
    isOpened: boolean;
  }>(),
  {
    customCollabClientPayload: null,
    isOpened: true,
  }
);

const emit = defineEmits<{
  (e: "save"): void;
}>();

// Check Is Completed =========================================================
const isCompleted = computed<boolean>(() => {
  return props.customCollabClientPayload.creatorCollabInputs.status === CustomCollabStatuses.Complete;
});

// Save =======================================================================
const isSaving = ref<boolean>(false);
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";

// Title and form element =====================================================
.title-and-form-element {
  &__title {
    margin-bottom: 8px;
    font-weight: 500;
  }

  &__form-element {
    &--textarea {
      height: 70px;
      min-height: 70px;
    }
  }

  &__add-more-link {
  }
}

// Collab details form ========================================================
.collab-details-form {
  &--disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &__ai-button {
    margin-bottom: 24px;
  }

  &__form-itself {
    gap: 21px $desktop-wide-grid-gap-width;
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
  }

  &__title-and-form-element {
    width: 100%;

    &--half-width {
      width: calc(50% - $desktop-wide-grid-gap-width / 2);
    }
  }

  &__buttons-panel {
    gap: 12px;
    display: flex;
    flex-wrap: wrap;
  }
}

// Collab details form wrap ===================================================
.collab-details-form-wrap {
  padding: 20px 25px 24px;
  border-radius: 6px;
  background: #f0f0f0;
}
</style>
