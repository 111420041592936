import { additionalInfoStringDividerSymbol } from "@contracts/photosPageData";
import { includesCaseInsensitive } from "@logic/String";

export function checkAdditionalInfoForSearchMatches(additionalInfoString: string, searchTerm: string): boolean {
  if (!additionalInfoString || !searchTerm) {
    return false;
  }

  return additionalInfoString.split(additionalInfoStringDividerSymbol).filter(text => includesCaseInsensitive(text, searchTerm)).length > 0;
}
