<template>
  <SrpModal :is-visible="isVisible" @update:is-visible="emit('update:isVisible', $event)" :width="500" maxHeight="calc(100dvh - 100px)" :isWithScroll="true" :isWithBottomGap="false">
    <template #content>
      <h2 class="global-h2" style="margin-bottom: 10px">Set a Location</h2>
      <template v-if="collabLocations.length">
        <div v-for="location in collabLocations" :key="location.externalId" class="location-selector__location">
          <p>
            <b style="font-weight: 600">{{ location.title }}</b>
            <br />
            <small style="color: rgba(0, 0, 0, 0.5); font-family: sans-serif">{{ location.address }}</small>
          </p>
          <SrpButton fill="outlined" @click="onSelectLocation(location)">Set</SrpButton>
        </div>
      </template>
      <p v-else>No locations for this creator visit have been created yet.</p>
    </template>

    <template #footer>
      <SrpButton size="small" @click="isAddLocationModalVisible = true">Add or Edit Locations</SrpButton>
    </template>
  </SrpModal>

  <PlanEditModal
    :showVisitPlanFields="false"
    :isVisible="isAddLocationModalVisible"
    :communityId="props.customerId"
    :collabInput="collabInputSummary"
    :creatorInput="null"
    :creatorId="userProfileStore.getViewingUserProfile.sherpaId"
    @update:isVisible="v => (isAddLocationModalVisible = v)"
  />
</template>

<script setup lang="ts">
import SrpButton from "@components/ui/SrpButton.vue";
import SrpModal from "@components/ui/SrpModal.vue";
import { CollabLocation } from "@contracts/collabLocations";
import { useUserProfileStore } from "@stores/userProfileStore";
import PlanEditModal from "@views/Creator/CollabOpportunity/PlanEditModal/index.vue";
import { computed, ref } from "vue";

const props = defineProps<{
  isVisible: boolean;
  collabLocations: CollabLocation[];
  collabInputId: string;
  customerId: string;
}>();

const collabInputSummary = computed(() => ({
  id: props.collabInputId,
  useLocationsInVisitPlan: true,
  useLocationsInAdventures: true,
}));
const emit = defineEmits<{
  (e: "update:isVisible", value: boolean): void;
  (e: "locationSelected", location: CollabLocation): void;
}>();

const userProfileStore = useUserProfileStore();
const isAddLocationModalVisible = ref(false);

function onSelectLocation(location: CollabLocation) {
  emit("update:isVisible", false);
  emit("locationSelected", location);
}
</script>

<style scoped lang="scss">
.location-selector {
  &__location {
    padding: 10px 0;
    margin: 0;
    border-bottom: 1px rgba(0, 0, 0, 0.1) solid;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    &:last-child {
      margin-bottom: 0;
      border-bottom: none;
    }

    &:hover {
      &::before {
        content: "";
        width: calc(100% + 24px);
        height: 100%;
        border-radius: 6px;
        position: absolute;
        inset: 0 auto auto -12px;
        background: rgba(0, 0, 0, 0.04);
      }
    }

    p {
      margin: 0;
    }
  }
}
</style>
