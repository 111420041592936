import { shrpaApi } from "@repos/axios";
import { MetricSender } from "@helpers/MetricSender";

// Types
import { CustomerSavedCreatorsPayload } from "@contracts/customerSavedCreators";

let loadAllDataAbortController: AbortController | null = null;

export default {
  async loadAllData(pageId: string): Promise<CustomerSavedCreatorsPayload> {
    if (loadAllDataAbortController) {
      loadAllDataAbortController.abort();
    }

    // Edge case: Aborts the request when the user deletes a saved creator shortly after adding
    loadAllDataAbortController = new AbortController();

    let payload: CustomerSavedCreatorsPayload | null = null;

    try {
      const { data } = await shrpaApi.get<CustomerSavedCreatorsPayload>(`${pageId}/saved-creators`, { signal: loadAllDataAbortController.signal });
      payload = data;
    } catch (error) {
      if (error.name === "CanceledError") {
        console.log("loadAllData: Previous request is aborted");
      } else {
        throw new Error(`unable to load the customerSavedCreators data${error}`);
      }
    }

    loadAllDataAbortController = null;

    return payload;
  },

  async saveCreator(pageId: string, creatorId: string) {
    if (loadAllDataAbortController) {
      loadAllDataAbortController.abort();
    }

    await shrpaApi.put(`${pageId}/saved-creators/${creatorId}`);
    MetricSender.sendMetric("Cust-SaveCreator", null);
  },

  async unSaveCreator(pageId: string, creatorId: string) {
    if (loadAllDataAbortController) {
      loadAllDataAbortController.abort();
    }

    await shrpaApi.delete(`${pageId}/saved-creators/${creatorId}`);
    MetricSender.sendMetric("Cust-UnsaveCreator", null);
  },
};
