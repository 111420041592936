import { removeSlashFromTheEnd } from "@helpers/Strings";

export const youTubeUrlBase = "https://www.youtube.com";
export const tikTokUrlBase = "https://www.tiktok.com";
export const twitterUrlBase = "https://x.com";
export const facebookUrlBase = "https://facebook.com";
export const instagramUrlBase = "https://instagram.com";

export default {
  cleanYouTubeUrl(ytUrl: string | null): string {
    if (ytUrl.includes("/channel/")) {
      return youTubeUrlBase + "/channel/" + ytUrl.split("/channel/").pop();
    }
    // handles a special case where the user removes symbols from the end of the string by pressing backspace
    if ((youTubeUrlBase + "/@").includes(ytUrl)) {
      return youTubeUrlBase + "/@";
    }

    let ytUserName = removeSlashFromTheEnd((ytUrl || "").trim());
    ytUserName = ytUserName.split("/").pop();
    // removes the "@" and "/" symbols
    ytUserName = ytUserName.replace(/[@\/]/gim, "");

    return youTubeUrlBase + "/@" + ytUserName;
  },

  cleanTikTokHandle(tikTokHandle: string): string {
    tikTokHandle = removeSlashFromTheEnd((tikTokHandle || "").trim());
    tikTokHandle = tikTokHandle.split("/").pop();
    // removes the "@" and "/" symbols
    tikTokHandle = tikTokHandle.replace(/[@\/]/gim, "");

    return tikTokHandle;
  },

  cleanTwitterHandle(twitterHandle: string): string {
    twitterHandle = removeSlashFromTheEnd((twitterHandle || "").trim());
    twitterHandle = twitterHandle.split("/").pop();
    // removes the "@" and "/" symbols
    twitterHandle = twitterHandle.replace(/[@\/]/gim, "");

    return twitterHandle;
  },

  cleanFacebookUrl(fbUrl: string | null): string {
    // handles a special case where the user removes symbols from the end of the string by pressing backspace
    if ((facebookUrlBase + "/").includes(fbUrl)) {
      return facebookUrlBase + "/";
    }

    let fbUserName = removeSlashFromTheEnd((fbUrl || "").trim());
    fbUserName = fbUserName.split("/").pop();
    // removes all the "@" and "/" symbols
    fbUserName = fbUserName.replace(/[@\/]/gim, "");
    // removes the "profile.php?id=" part if exists
    fbUserName = fbUserName.replace(/^.+id=/gim, "");

    return facebookUrlBase + "/" + fbUserName;
  },

  cleanInstagramHandle(instaHandle: string): string {
    instaHandle = removeSlashFromTheEnd((instaHandle || "").trim());
    // removes everything that goes before the username
    instaHandle = instaHandle.replace(/^.+instagram\.com\//gim, "");
    // removes everything that goes after the username
    instaHandle = instaHandle.replace(/\/[^\/]*$/gim, "");

    return instaHandle;
  },
};
