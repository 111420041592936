<template>
  <div v-if="showPleaseLoginMessage" class="creator-profile-public">
    <h2>Please log in to view your profile!</h2>
    <p>
      <button class="ui small primary button loginButton" @click="authenticate()">Log in</button>
    </p>

    <!--Login Modal-->
    <LoginModal :isVisible="isShowLoginModal" @close="isShowLoginModal = false" />
  </div>
  <div v-else-if="creatorNotFound" style="margin: 50px 70px">
    <h2>Creator not found</h2>
  </div>
  <div v-else-if="!profileToBind" class="edit-profile-page__loader"><Loader /></div>
  <div v-else class="creator-profile-public">
    <!-- Floating buttons panel -->
    <div class="floating-buttons-panel creator-profile-public__floating-buttons-panel">
      <RequiredFieldNote v-if="enableRequiredFieldNote && errorMessage" class="error-field-note floating-buttons-panel__error-field-note">{{ errorMessage }}</RequiredFieldNote>

      <SrpButton v-if="isEditProfileMode" :isDisabled="isSaving" @click="saveUserProfile(false)">
        <template #icon>
          <IconEmbedded name="save_2" :size="21" color="rgba(255, 255, 255, 0.5)" />
        </template>
        {{ isOnboarding ? "Save & Continue" : "Save" }}
      </SrpButton>

      <SrpButton v-else-if="canEdit" @click="setModeToEditProfile()">
        <template #icon>
          <IconEmbedded name="pencil_2" color="rgba(255, 255, 255, 0.5)" :size="20" />
        </template>
        Edit Profile
      </SrpButton>
    </div>
    <!-- / Floating buttons panel -->

    <!-- Header -->
    <div class="header creator-profile-public__header">
      <div class="header__left-side">
        <AvatarWithFallback
          class="header__avatar"
          isRounded
          :isError="enableRequiredFieldNote && (!profileToBind?.tileImageLocation || profileToBind?.tileImageLocation === DefaultProfileImage)"
          :isWithUploadBtn="isEditProfileMode"
          :src="(profileToBind.imageIsExternal ? '' : `${contentBaseUri}/cms/images/profiles/`) + profileToBind.tileImageLocation"
          @change="uploadProfilePhoto"
        />

        <div
          v-if="isCustomerAdmin && FeatureFlags.isFlagActive('EnableCustomerSavedCreators')"
          :class="{
            'header__save-link-wrap': true,
            'header__save-link-wrap--disabled': isLoadingSavedCreatorsPayload,
          }"
        >
          <LinkWithIcon
            v-if="isCreatorSaved"
            tag="RouterLink"
            iconInset="1px -2px auto auto"
            isDottedUnderline
            :to="{ name: 'CustomerSavedCreators', params: { pageId: globalRoot.orgInContext?.customerId } }"
          >
            <span style="white-space: nowrap">View Saved</span>
            <template #iconRight>
              <IconEmbedded name="arrow-right_2-5" :size="15" />
            </template>
          </LinkWithIcon>

          <LinkWithIcon v-else iconInset="1px -2px auto auto" isDottedUnderline @click="saveCreator">
            <template #icon><IconEmbedded name="save_2-5" :size="15" /></template>
            <span style="white-space: nowrap">Save Creator</span>
          </LinkWithIcon>

          <TooltipWrapper v-if="!isCreatorSaved" style="margin-left: 8px; position: relative; inset: 1px auto auto auto" :width="200">
            <template #hoverableElement>
              <LinkWithIcon>
                <template #icon>
                  <div style="width: 20px; min-width: 20px; height: 20px; border-radius: 100px; display: flex; justify-content: center; align-items: center; background: rgba(0, 0, 0, 0.1)">
                    <IconEmbedded name="info-simple_4" :size="14" />
                  </div>
                </template>
              </LinkWithIcon>
            </template>
            <template #tooltip>Save creators that you're interested in working with in the future!</template>
          </TooltipWrapper>
        </div>

        <!--Hidden link for supers to navigate to the backend page-->
        <router-link
          v-if="userProfileStore.isSuperOrSalesUser === true && profileToBind"
          style="display: block; margin-top: 10px; width: 30%"
          :to="{ name: 'EditCreator', params: { id: `${profileToBind.sherpaId}` } }"
          >&nbsp;</router-link
        >
      </div>

      <div class="header__right-side">
        <div class="header__title-n-subtitle">
          <RequiredFieldNote v-if="isEditProfileMode && !profileToBind?.firstName" class="header__title-required-note">required</RequiredFieldNote>

          <TextareaSubtle
            class="header__title"
            v-model:value="profileToBind.firstName"
            :fontSize="titleFontSize"
            :isDisabled="!isEditProfileMode"
            :isWithEditButton="false"
            :fakeBgSize="{
              width: 'calc(100% + 10px)',
              minWidth: 'calc(100% + 10px)',
              height: 'calc(100% - 22px)',
            }"
            :isWithUnderline="false"
            placeholder="Your name"
            :isTextAlignCenter="['mobile', 'tablet'].includes(screenSize)"
            :isError="enableRequiredFieldNote && !profileToBind?.firstName"
          />

          <!-- Website section -->
          <div class="website-section header__website-section">
            <!-- Website address input -->
            <div v-if="profileToBind.orgSiteUrl || isEditProfileMode" class="website-address-input website-section__address-input">
              <a
                v-if="!isEditProfileMode"
                class="website-address-input__link-overlay"
                :href="profileToBind.orgSiteUrl.startsWith('http') ? profileToBind.orgSiteUrl : `https://${profileToBind.orgSiteUrl}`"
                target="_blank"
              ></a>

              <TextareaSubtle
                :class="{
                  'website-address-input__textarea-subtle': true,
                  'website-address-input__textarea-subtle--with-underline': !isEditProfileMode,
                }"
                :value="profileToBind.orgName && !isEditProfileMode ? profileToBind.orgName : profileToBind.orgSiteUrl"
                @update:value="newValue => (profileToBind.orgSiteUrl = newValue)"
                :isDisabled="!isEditProfileMode"
                isBorderAlwaysVisible
                :fontSize="subTitleFontSize"
                :isWithEditButton="false"
                :fakeBgSize="{
                  width: 'calc(100% + 10px)',
                  minWidth: 'calc(100% + 10px)',
                  height: 'calc(100% - 16px)',
                }"
                fakeBgInset="5px auto auto 0"
                placeholder="website address"
                :isTextAlignCenter="['mobile', 'tablet'].includes(screenSize)"
                autocapitalize="none"
                autocorrect="off"
              />

              <IconEmbedded v-if="!isEditProfileMode" class="website-address-input__external-resource-link" name="external-link_2" />
            </div>
            <!-- / Website address input -->

            <template v-if="isEditProfileMode">
              <LinkWithIcon v-if="profileToBind.orgName && !isWebsiteNameInputVisible" class="website-section__show-the-name-link" isDottedUnderline @click="isWebsiteNameInputVisible = true">
                <span>Friendly website name</span>
              </LinkWithIcon>

              <div v-else-if="isWebsiteNameInputVisible" class="website-section__name-wrap">
                <h3 class="global-h5 website-section__name-title">Website name</h3>
                <TextareaSubtle
                  v-if="isEditProfileMode"
                  class="website-section__name-input"
                  v-model:value="profileToBind.orgName"
                  :isDisabled="!isEditProfileMode"
                  isBorderAlwaysVisible
                  :fontSize="subTitleFontSize"
                  :isWithEditButton="false"
                  :fakeBgSize="{
                    width: 'calc(100% + 10px)',
                    minWidth: 'calc(100% + 10px)',
                    height: 'calc(100% - 16px)',
                  }"
                  fakeBgInset="5px auto auto 0"
                  placeholder="Website name"
                  :isTextAlignCenter="['mobile', 'tablet'].includes(screenSize)"
                />
              </div>
            </template>
          </div>
          <!-- / Website section -->
        </div>

        <div class="header__about-me-wrap" v-if="isEditProfileMode || profileToBind.shortBlurb">
          <TextareaSubtle
            class="header__about-me"
            :isDisabled="!isEditProfileMode"
            :value="profileToBind.shortBlurb"
            @update:value="newValue => (profileToBind.shortBlurb = newValue)"
            :isWithUnderline="false"
            isBorderAlwaysVisible
            :isWithEditButton="false"
            :fontSize="16"
            :lineHeightCoeff="1.4"
            :fakeBgSize="{
              width: ['mobile', 'tablet'].includes(screenSize) ? 'calc(100%)' : 'calc(100% + 26px)',
              minWidth: ['mobile', 'tablet'].includes(screenSize) ? 'calc(100%)' : 'calc(100% + 26px)',
              marginLeft: ['mobile', 'tablet'].includes(screenSize) ? '-2px' : '15px',
              height: 'calc(100% + 16px)',
            }"
            placeholder="A few sentences about what you're interested in!"
            :isTextAlignCenter="['mobile', 'tablet'].includes(screenSize)"
            :lineClamp="isEditProfileMode ? 'none' : isShortBlurbExpanded ? 'none' : 5"
            ref="domRefTextareaSubtleAboutMe"
          />
          <template v-if="!isEditProfileMode && (isShortBlurbExpanded ? true : domRefTextareaSubtleAboutMe?.isSomeTextHidden)">
            <span class="global-a global-a--dashed" @click="isShortBlurbExpanded = !isShortBlurbExpanded" style="white-space: nowrap">
              {{ isShortBlurbExpanded ? "collapse" : "read more" }}
            </span>
          </template>
        </div>

        <SocialButtonsRow
          class="header__social-buttons-container"
          :profileToBind="profileToBind"
          :isEditProfileMode="isEditProfileMode"
          :enableRequiredFieldNote="enableRequiredFieldNote"
          @saveUserProfile="saveUserProfile"
        />
      </div>
    </div>
    <!-- / Header -->

    <div v-if="profileToBind.disabledDate">
      <ProfileDisabledWarning />
    </div>
    <template v-else>
      <PhotosSection
        class="creator-profile-public__photos-layout"
        :imagesList="profileToBind.featuredMedia"
        :isEditProfileMode="isEditProfileMode"
        :minimumRequiredPhotoCount="MinimumRequiredPhotoCount"
        @imageAdded="imageAdded"
        @imageRemoved="imageRemoved"
        @imagesReordered="imagesReordered"
        :enableRequiredFieldNote="enableRequiredFieldNote"
      />

      <template v-if="publicProfileStore.collabSummaries.length">
        <!-- Creator visits -->
        <div class="creator-visits creator-profile-public__creator-visits">
          <ul class="creator-visits__collab-summaries-list">
            <CollabSummarySection
              class="creator-visits__collab-summary"
              v-for="collabSummary in publicProfileStore.collabSummaries.filter(cs => cs.itineraries?.length || cs.highlightAssetIds?.length)"
              :key="collabSummary.id"
              :summarySection="collabSummary"
              isVisitDateVisible
              :isCreatorAvatarVisible="false"
            />
          </ul>
        </div>
        <!-- / Creator visits -->

        <!--<div class="creator-profile-public__divider"></div>-->
      </template>

      <!-- Previous adventures -->
      <div
        v-if="publicProfileStore.nonCollabAdventures.length || isEditProfileMode"
        :class="{
          'previous-adventures': true,
          'previous-adventures--disabled': isEditProfileMode,
          'creator-profile-public__previous-adventures': true,
        }"
      >
        <h2 class="global-h1 previous-adventures__title">Adventures</h2>

        <ul class="previous-adventures__adventure-sections-list">
          <ItineraryDetailsSnippet class="previous-adventures__adventure-section" v-for="itinerary in publicProfileStore.nonCollabAdventures" :key="itinerary.id" :itinerary="itinerary" />
        </ul>
      </div>
      <!-- / Previous adventures -->
    </template>

    <!--Unsaved Changes Modal-->
    <SrpModal v-model:isVisible="showUnsavedChangesModal" :isCloseButtonVisible="false">
      <template #header>
        <h2 class="global-h2">There are unsaved changes, are you sure you want to navigate away?</h2>
      </template>
      <template #footer>
        <SrpButton @click="navigateAwayConfirmed" color="gray" fill="outlined">I've saved what I need</SrpButton>
        <SrpButton @click="showUnsavedChangesModal = false" style="margin-left: 10px">Back</SrpButton>
      </template>
    </SrpModal>
  </div>
</template>

<!-- prettier-ignore -->
<script lang="ts">export default { name: "CreatorProfilePublic" };</script>

<script setup lang="ts">
import { ref, inject, Ref, computed, onMounted, watch, onUnmounted, nextTick, watchEffect } from "vue";
import { useRoute, useRouter, onBeforeRouteLeave, NavigationGuardNext } from "vue-router";
import lodashThrottle from "lodash-es/throttle";
import FileUtils from "@logic/FileUtils";
import axios from "axios";
import SocialFieldValidator from "@logic/SocialFieldValidator";
import FeatureFlags from "@/logic/FeatureFlags";
import CustomerSavedCreatorsRepo from "@repos/CustomerSavedCreatorsRepo";

// Components
import AvatarWithFallback from "@components/AvatarWithFallback.vue";
import ItineraryDetailsSnippet from "@components/ItineraryDetailsSnippet.vue";
import PhotosSection from "./PhotosSection.vue";
import RequiredFieldNote from "@components/ui/RequiredFieldNote.vue";
import SrpButton from "@components/ui/SrpButton.vue";
import SrpModal from "@components/ui/SrpModal.vue";
import TextareaSubtle from "@components/ui/TextareaSubtle.vue";
import Loader from "@components/Loader/Loader.vue";
import LoginModal from "@components/Modals/LoginModal.vue";
import SocialButtonsRow from "./SocialButtonsRow.vue";
import ProfileDisabledWarning from "@components/Sherpa/ProfileDisabledWarning.vue";

// Types
import { ScreenSize } from "@contracts/screenSize";

// Stores
import { useCreatorPublicProfileStore } from "@stores/creatorPublicProfile";
import { useUserProfileStore } from "@stores/userProfileStore";
import { MetricSender } from "@helpers/MetricSender";
import { RouteHelper } from "@helpers/RouteHelper";
import LinkWithIcon from "@components/LinkWithIcon.vue";
import { ICreatorProfileViewModel } from "./creatorProfileViewModel";
import IconEmbedded from "@components/ui/IconEmbedded.vue";
import CollabSummarySection from "@components/CollabSummarySection.vue";
import { useToastsStore } from "@/stores/toasts";
import TooltipWrapper from "@components/ui/TooltipWrapper.vue";
import { CustomerSavedCreatorsPayload } from "@contracts/customerSavedCreators";

const publicProfileStore = useCreatorPublicProfileStore();
const userProfileStore = useUserProfileStore();
const profileToBind = ref<ICreatorProfileViewModel>(null);

// Router
const route = useRoute();
const router = useRouter();

// Global variables
const screenSize = inject("screenSize") as Ref<ScreenSize>;
const globalRemoteLogger = inject("globalRemoteLogger") as any;
const contentBaseUri = globalThis.Bootstrap.Config.contentCdnBaseUri;
const globalRoot = inject("globalRoot") as any;

onMounted(async () => {
  await DetermineWhichCreatorToLoad();
});

// Profile Loading ==========================================================
const showPleaseLoginMessage = ref<boolean>(false);
const isShowLoginModal = ref<boolean>(false);
const creatorNotFound = ref<boolean>(false);

async function DetermineWhichCreatorToLoad() {
  // "me" allows us to use a static link (ex. in emails) to send a user to their profile
  if (!creatorIdIsMeConstant()) {
    // We have an id to load data with
    await loadCreatorData(route.params.creatorId as string);
  } else if (userProfileStore.getViewingUserProfile) {
    // The user is logged in, so we can use their id
    await loadCreatorData(userProfileStore.getViewingUserProfile.sherpaId);
  } else {
    // We have to wait for the profile to load (see the watch below), or they need to log in still
    showPleaseLoginMessage.value = true;
  }
}

watch(
  async () => userProfileStore.loggedInUser,
  async profile => {
    if (profile) {
      if (creatorIdIsMeConstant()) {
        // This fires if the user used the "me" link and their profile wasn't loaded yet
        showPleaseLoginMessage.value = false;
        await loadCreatorData(userProfileStore.getViewingUserProfile.sherpaId);
      }
      setIsEditable();
      checkForEditMode();
    }
  }
);

function authenticate() {
  isShowLoginModal.value = true;
}

function creatorIdIsMeConstant() {
  return (route.params.creatorId as string)?.toLowerCase() === "me";
}

async function loadCreatorData(idToLoad: string) {
  await publicProfileStore.loadCreatorSummaryV2(idToLoad);
  // We're in view mode so set this to the public profile store
  profileToBind.value = publicProfileStore.creator;
  if (!profileToBind.value) {
    creatorNotFound.value = true;
  }
  setIsEditable();
  checkForEditMode();
  if (profileToBind.value) MetricSender.viewCreatorProfile(profileToBind.value.sherpaId, profileToBind.value.firstName);
}

// Edit profile mode ==========================================================
const canEdit = ref<boolean>(false);
const isEditProfileMode = ref<boolean>(false);
const isOnboarding = ref<boolean>(false);

function setIsEditable() {
  if (userProfileStore.getActingUserProfile) {
    if (userProfileStore.getActingUserProfile.sherpaId && userProfileStore.getActingUserProfile.sherpaId === profileToBind.value?.sherpaId) {
      canEdit.value = true;
    } else if (userProfileStore.isSuperOrSalesUser) {
      canEdit.value = true;
    }
  }
}

function checkForEditMode() {
  if (canEdit.value && RouteHelper.getQueryStringOrHashParam("isOnboarding") === "true") {
    isOnboarding.value = true;
    setModeToEditProfile();
  }
}

async function setModeToEditProfile() {
  if (!creatorIdIsMeConstant()) {
    // Check if we should be impersonating a user (this method handles the permission checks)
    await userProfileStore.impersonateProfile(route.params.creatorId as string);
  }
  // Switch over to the full profile store since that's what is needed to save
  profileToBind.value = userProfileStore.getViewingUserProfile;
  isEditProfileMode.value = true;
  // Adjust isDirty (since we're using a watch below and the assignment above triggers it)
  nextTick(() => {
    isDirty.value = false;
  });
}

const enableRequiredFieldNote = computed(() => {
  // Don't show if they're not in edit mode
  if (!isEditProfileMode.value) return false;
  // Don't show them if they're elready paid eligible (ex. existing paid creators won't have featuredMedia when we ship this new page)
  if (userProfileStore.getViewingUserProfile.collabPreferences?.isCollabEligible === true) return false;
  // If they're not interested in becoming paid eligible, then nothing is really 'required'
  if (userProfileStore.getViewingUserProfile.collabPreferences?.becomingCollabEligible === false) return false;

  return true;
});

function imageAdded(imageId: string) {
  profileToBind.value.featuredMedia.push(imageId);
  if (profileToBind.value.featuredMedia.length > MinimumRequiredPhotoCount) {
    clearErrorMessage();
  }
  throttledSaveUserProfile();
}

function imageRemoved(imageId: string) {
  profileToBind.value.featuredMedia.splice(profileToBind.value.featuredMedia.indexOf(imageId), 1);
  throttledSaveUserProfile();
}

function imagesReordered(newList: string[]) {
  profileToBind.value.featuredMedia = newList;
  throttledSaveUserProfile();
}

// IsDirty check ==============================================================
const isDirty = ref<boolean>(false);
const showUnsavedChangesModal = ref<boolean>(false);
let nextNav: NavigationGuardNext | null = null;
// Watch the profile for changes
watch(
  () => profileToBind.value,
  () => {
    if (isEditProfileMode.value && !isSaving.value) {
      isDirty.value = true;
    }
  },
  { deep: true }
);

onBeforeRouteLeave((to, from, next) => {
  // If we're in the save flow this doesn't apply
  if (isSaving.value) {
    next();
  } else if (isDirty.value) {
    // Prompt the user if they're trying to leave the page with unsaved changes
    nextNav = next;
    showUnsavedChangesModal.value = true;
  } else {
    next();
  }
});

function navigateAwayConfirmed() {
  nextNav();
  showUnsavedChangesModal.value = false;
}

// Throttled save =============================================================
const throttledSaveUserProfile = lodashThrottle(saveUserProfile, 3000, { leading: false });
// when the component is unmounted, flush any remaining calls
onUnmounted(() => throttledSaveUserProfile.flush());

// Save profile ===============================================================
const isSaving = ref<boolean>(false);
const MinimumRequiredPhotoCount = 15;
const errorMessage = ref<string>(null);
// This is defaulted server-side. Until we rework everywhere to handle null, this is needed.
const DefaultProfileImage = "GenericProfile.png";

async function saveUserProfile(stayInEditMode = true) {
  isSaving.value = true;
  // Validation
  // Note: We still save even if the user hasn't met all the validation requirements
  const isValid = validateRequiredFields();

  // Cleanup
  SocialFieldValidator.fixSocialFields(profileToBind.value);
  if (profileToBind.value?.firstName) {
    profileToBind.value.firstName = profileToBind.value?.firstName.trim();
  }

  globalRemoteLogger.info(`Profile.saveUserProfile: ${profileToBind.value.authNameId}, errorMessage=${errorMessage.value}`);

  try {
    let profileToSave = userProfileStore.getViewingUserProfile;
    // NOTE: NOT refreshing with server state here since we auto-save
    // and that can drop images that were uploaded while the save occurred.
    var savedProfileFromServer = await userProfileStore.saveProfile(profileToSave, null, false);
    // Manually updating the etag so minimize conflict warnings
    userProfileStore.getViewingUserProfile._etag = savedProfileFromServer._etag;

    if (isValid) {
      if (isOnboarding.value) {
        if (!stayInEditMode) {
          await router.push({ name: "CreatorDashboard", params: { creatorId: profileToBind.value.sherpaId } });
        }
      } else {
        if (!stayInEditMode) {
          isEditProfileMode.value = false;
        }
      }
    }

    isSaving.value = false;
    isDirty.value = false;
  } catch (error) {
    globalRemoteLogger.error(`saveUserProfile Error: ${error.message} userId=${profileToBind.value?.sherpaId}`);
    throw error;
  }
}

function clearErrorMessage() {
  errorMessage.value = null;
}

function validateRequiredFields(): boolean {
  // Shortcut for existing paid creators or not interested
  if (!enableRequiredFieldNote.value) {
    return true;
  }

  const errors = [];
  if (!profileToBind.value.firstName) {
    errors.push("Please add your name");
  }
  if (!profileToBind.value.tileImageLocation || profileToBind.value.tileImageLocation === DefaultProfileImage) {
    errors.push("Please add a profile photo");
  }
  if (profileToBind.value.featuredMedia.length < MinimumRequiredPhotoCount) {
    errors.push(`Add a few more photos or videos!`);
  }

  if (errors.length > 1) {
    errorMessage.value = "Please fill out the required fields";
  } else if (errors.length === 1) {
    errorMessage.value = errors[0];
  } else {
    errorMessage.value = null;
  }
  return errors.length === 0;
}

// Truncated short blurb ======================================================
const isShortBlurbExpanded = ref<boolean>(false);

watch(
  () => isEditProfileMode.value,
  () => (isShortBlurbExpanded.value = isEditProfileMode.value)
);

// Calc title/subtitle font size ==============================================
const titleFontSize = computed<number>(() => {
  if (["desktop-wide", "desktop"].includes(screenSize.value)) {
    return 60;
  } else if (["laptop", "tablet-large"].includes(screenSize.value)) {
    return 60;
  } else if (["tablet"].includes(screenSize.value)) {
    return 40;
  } else if (["mobile"].includes(screenSize.value)) {
    return 35;
  } else {
    return 60;
  }
});

const subTitleFontSize = computed<number>(() => {
  switch (screenSize.value) {
    case "desktop-wide":
    case "desktop":
    case "laptop":
    case "tablet-large":
      return 37;
    case "tablet":
      return 30;
    case "mobile":
      return 24;
    default:
      return 30;
  }
});

// Upload profile photo =======================================================
async function uploadProfilePhoto(event: Event) {
  if ((event.target as HTMLInputElement).files.length > 0) {
    const file = (event.target as HTMLInputElement).files[0];
    const error = FileUtils.isFileSupportedForUpload(file.name, file.type, file.size);
    if (error?.length > 0) {
      errorMessage.value = error;
      useToastsStore().warn({ message: error, expiresInMs: 10000 });
    } else {
      // Upload
      const formData = new FormData();
      formData.append("file", file);
      const result = await axios.post(`${import.meta.env.VITE_API_URL}/images?type=profile`, formData);
      // Log the result
      globalRemoteLogger.info(`Profile.uploadProfilePhoto: ${profileToBind.value.sherpaId}, ${file.name}, result=${result.data[0]}`);
      profileToBind.value.tileImageLocation = result.data[0];
      // Save the profile
      await throttledSaveUserProfile();

      clearErrorMessage();
    }
  }
}

// Check whether the About me textarea has some hidden lines ==================
const domRefTextareaSubtleAboutMe = ref<HTMLElement | null>(null);

// Toggle website name input ==================================================
const isWebsiteNameInputVisible = ref<boolean>(false);

watch(isEditProfileMode, () => {
  if (!isEditProfileMode.value) {
    isWebsiteNameInputVisible.value = false;
  }
});

// Check is Creator saved =====================================================
const savedCreatorsPayload = ref<CustomerSavedCreatorsPayload | null>(null);
const isLoadingSavedCreatorsPayload = ref<boolean>(false);

watchEffect(async () => {
  if (globalRoot.orgInContext?.customerId) {
    isLoadingSavedCreatorsPayload.value = true;
    savedCreatorsPayload.value = await CustomerSavedCreatorsRepo.loadAllData(globalRoot.orgInContext?.customerId);
    isLoadingSavedCreatorsPayload.value = false;
  }
});

// Save creator ===============================================================
const isCreatorSaved = ref<boolean>(false);
const isCustomerAdmin = computed<boolean>(() => userProfileStore.getViewingUserProfile?.adminOf?.length > 0);

watch(
  () => [userProfileStore.getViewingUserProfile, profileToBind.value?.sherpaId, savedCreatorsPayload.value?.manuallySaved],
  () => {
    if (!isCustomerAdmin.value || !savedCreatorsPayload.value?.manuallySaved) {
      return;
    }

    isCreatorSaved.value = savedCreatorsPayload.value?.manuallySaved.findIndex(c => c.shrpaCreatorId === profileToBind.value?.sherpaId) > -1;
  }
);

async function saveCreator() {
  if (!isCustomerAdmin.value) {
    return;
  }

  isCreatorSaved.value = true;

  await CustomerSavedCreatorsRepo.saveCreator(globalRoot.orgInContext?.customerId, profileToBind.value.sherpaId);
}
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";
@import "@/scss/variables.scss";

// Website address input ======================================================
.website-address-input {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 0;

  &__external-resource-link {
    position: relative;
    top: 3px;
    color: rgba(17, 134, 137, 0.3);
    font-size: 34px;
  }

  &__link-overlay {
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    inset: 0 auto auto 0;
    z-index: 2;
    cursor: pointer;
  }

  &__textarea-subtle {
    width: 100%;

    :deep(textarea),
    :deep(.textarea-subtle__text-overlay) {
      color: rgba(17, 134, 137, 1);
      -webkit-text-fill-color: rgba(17, 134, 137, 1); // prevents dimming of disabled text color on iOS
      font-weight: 400;
    }

    &--with-underline {
      :deep(textarea),
      :deep(.textarea-subtle__text-overlay) {
        text-decoration: underline;
        text-decoration-color: rgba(17, 134, 137, 0.3);
        text-underline-offset: 4px;
        text-decoration-thickness: 2px;
        text-decoration-style: solid;
      }
    }
  }

  &__link-overlay:hover + &__textarea-subtle {
    :deep(textarea),
    :deep(.textarea-subtle__text-overlay) {
      text-decoration: none;
    }
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
  .website-address-input {
    &__external-resource-link {
      font-size: 30px;
    }
  }
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
  .website-address-input {
    &__external-resource-link {
      font-size: 30px;
    }
  }
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .website-address-input {
    &__textarea-subtle {
      margin-right: 7px;
    }

    &__external-resource-link {
      font-size: 27px;
    }
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .website-address-input {
    &__textarea-subtle {
      margin-right: 5px;
    }

    &__external-resource-link {
      top: 2px;
      font-size: 22px;
    }
  }
}

// Website section ============================================================
.website-section {
  display: flex;
  align-items: center;

  &__address-input {
    margin-right: 20px;
  }

  &__show-the-name-link {
  }

  &__name-wrap {
    position: relative;
  }

  &__name-title {
    position: absolute;
    inset: -5px auto auto 0;
    color: rgba(100, 100, 100, 1);
    font-weight: 700;
    text-align: center;
    white-space: nowrap;
  }

  &__name-input {
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .website-section {
    flex-direction: column;
    align-items: center;

    &__address-input {
      margin-right: 0;
    }

    &__name-title {
      inset: -5px auto auto 50%;
      transform: translateX(-50%);
    }
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .website-section {
    flex-direction: column;
    align-items: center;

    &__address-input {
      margin-right: 0;
    }

    &__name-title {
      inset: -5px auto auto 50%;
      transform: translateX(-50%);
    }
  }
}

// Header =====================================================================
.header {
  display: flex;
  justify-content: flex-start;
  position: relative;

  // left side ------------------------
  &__left-side {
    width: calc(var(--grid-col-width) * 2 + var(--grid-col-gap-width));
    min-width: calc(var(--grid-col-width) * 2 + var(--grid-col-gap-width));
    margin-right: var(--grid-col-gap-width);
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__avatar {
    width: 100%;
  }

  &__save-link-wrap {
    margin: 10px 0 0 -3px;
    display: flex;
    align-items: center;

    &--disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  // right side -----------------------
  &__right-side {
    width: calc(var(--grid-col-width) * 7 + var(--grid-col-gap-width) * 6);
    padding-top: 8px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__title-n-subtitle {
    width: 100%;
    margin-bottom: 25px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__title-required-note {
    margin: 0 0 -7px -10px;
  }

  &__title {
    width: calc(var(--grid-col-width) * 10 + var(--grid-col-gap-width) * 9);
    margin-bottom: -10px;

    &:last-child {
      margin-bottom: 0;
    }

    :deep(textarea),
    :deep(.textarea-subtle__text-overlay) {
    }
  }

  &__website-section {
  }

  &__about-me-wrap {
    width: 100%;
    margin: 0 0 30px 0;
  }

  &__about-me {
    width: calc(100% - 13px);

    &:last-child {
      margin-bottom: 0;
    }

    :deep(textarea),
    :deep(.textarea-subtle__text-overlay) {
      color: rgba(0, 0, 0, 0.7);
      font-weight: 500 !important;
      font-family: sans-serif;
    }
  }

  &__social-buttons-container {
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
  .header {
  }
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
  .header {
    &__right-side {
      width: calc(var(--grid-col-width) * 8 + var(--grid-col-gap-width) * 7);
    }
  }
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
  .header {
    &__title {
      width: 100%;
      max-width: 100%;
    }

    &__about-me {
      width: calc(100% - 13px);
      max-width: calc(100% - 13px);
    }
  }
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .header {
    flex-direction: column;
    align-items: center;

    &__left-side {
      margin: 0;
    }

    &__right-side {
      width: 100%;
      align-items: center;
    }

    &__title-n-subtitle {
      align-items: center;
    }

    &__title {
      width: auto;
      max-width: calc(100% - 16px);
      margin: 0 0 -10px -5px;
    }

    &__about-me-wrap {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &__about-me {
      max-width: 100%;
      margin-left: -2px;
    }
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .header {
    flex-direction: column;
    align-items: center;

    &__left-side {
      margin: 0;
    }

    &__right-side {
      width: 100%;
      align-items: center;
    }

    &__title-n-subtitle {
      margin-bottom: 20px;
      align-items: center;
    }

    &__title {
      width: auto;
      max-width: calc(100% - 30px);
      margin: 0 0 -10px -5px;
    }

    &__about-me-wrap {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &__about-me {
      max-width: calc(100% - 30px);
      margin-left: -4px;
    }
  }
}

// Previous adventures ========================================================
.previous-adventures {
  &--disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &__title {
    margin-bottom: 35px;
  }

  &__adventure-tiles-list {
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
  }

  &__adventure-tile {
    width: calc(var(--grid-col-width) * 2 + var(--grid-col-gap-width));
    margin: 0 var(--grid-col-gap-width) var(--grid-col-gap-width) 0 !important;

    &:nth-child(6n) {
      margin-right: 0 !important;
    }
  }

  &__adventure-sections-list {
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
  }

  &__adventure-section {
    width: 100%;
    margin-bottom: 30px;
    position: relative;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
  .previous-adventures {
    &__adventure-tile {
      width: calc(var(--grid-col-width) * 2 + var(--grid-col-gap-width));
      margin: 0 var(--grid-col-gap-width) var(--grid-col-gap-width) 0 !important;

      &:nth-child(6n) {
        margin: 0 var(--grid-col-gap-width) var(--grid-col-gap-width) 0 !important;
      }

      &:nth-child(4n) {
        margin-right: 0 !important;
      }
    }
  }
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .previous-adventures {
    &__adventure-tile {
      width: calc(var(--grid-col-width) * 4 + var(--grid-col-gap-width) * 3);
      margin: 0 var(--grid-col-gap-width) var(--grid-col-gap-width) 0 !important;

      &:nth-child(6n) {
        margin: 0 var(--grid-col-gap-width) var(--grid-col-gap-width) 0 !important;
      }

      &:nth-child(4n) {
        margin: 0 var(--grid-col-gap-width) var(--grid-col-gap-width) 0 !important;
      }

      &:nth-child(2n) {
        margin-right: 0 !important;
      }
    }
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .previous-adventures {
    &__adventure-tile {
      width: calc(var(--grid-col-width) * 3 + var(--grid-col-gap-width) * 2);
      margin: 0 var(--grid-col-gap-width) var(--grid-col-gap-width) 0 !important;

      &:nth-child(6n) {
        margin: 0 var(--grid-col-gap-width) var(--grid-col-gap-width) 0 !important;
      }

      &:nth-child(4n) {
        margin: 0 var(--grid-col-gap-width) var(--grid-col-gap-width) 0 !important;
      }

      &:nth-child(2n) {
        margin-right: 0 !important;
      }
    }
  }
}

// Floating buttons panel =====================================================
.floating-buttons-panel {
  padding-bottom: 22px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  z-index: 0;

  &::before {
    content: "";
    width: calc(100% + 120px);
    height: calc(100% * 2 + 40px);
    border-radius: 100px;
    position: absolute;
    inset: -40px auto auto -60px;
    z-index: -1;
    filter: blur(25px);
    background: rgba(255, 255, 255, 1);
    pointer-events: none;
  }

  &__error-field-note {
    margin-right: 23px;
  }

  &__button {
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .floating-buttons-panel {
    &__error-field-note {
      max-width: calc(100vw - 170px);

      :deep(.required-field-note__text) {
        white-space: normal;
      }
    }
  }
}

// Creator visits =============================================================
.creator-visits {
  &__title {
    margin-bottom: 60px;
  }

  &__collab-summaries-list {
    gap: 55px;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    list-style: none;
  }

  &__collab-summary {
    padding-bottom: 60px;
    border-bottom: 1px rgba(0, 0, 0, 0.1) solid;

    &:last-child {
      padding-bottom: 0;
      border-bottom: none;
    }
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
  .creator-visits {
    &__title {
      margin-bottom: 10px;
    }

    &__collab-summaries-list {
      gap: 25px;
    }

    &__collab-summary {
      padding-bottom: 50px;
    }
  }
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .creator-visits {
    &__title {
      margin-bottom: 10px;
    }

    &__collab-summaries-list {
      gap: 25px;
    }

    &__collab-summary {
      padding-bottom: 50px;
    }
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .creator-visits {
    &__title {
      margin-bottom: 10px;
    }

    &__collab-summaries-list {
      gap: 25px;
    }

    &__collab-summary {
      padding-bottom: 50px;
    }
  }
}

// Creator profile public =====================================================
.creator-profile-public {
  width: var(--grid-content-full-width);
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  &__floating-buttons-panel {
    position: fixed;
    inset: auto calc(50% - var(--grid-content-full-width) / 2) 0 auto;
    z-index: 6;
  }

  &__header {
    width: 100%;
    margin-bottom: 55px;
  }

  &__photos-layout {
    width: 100vw;
    margin-bottom: 80px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__creator-visits {
    margin-bottom: 80px;
  }

  &__divider {
    width: 100%;
    margin-bottom: 70px;
    border-bottom: 1px rgba(0, 0, 0, 0.1) solid;
  }

  &__previous-adventures {
    width: 100%;
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
  .creator-profile-public {
  }
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
  .creator-profile-public {
  }
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
  .creator-profile-public {
    &__photos-layout {
      margin-bottom: 65px;
    }
  }
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
  .creator-profile-public {
    &__photos-layout {
      margin-bottom: 65px;
    }
  }
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .creator-profile-public {
    &__photos-layout {
      margin-bottom: 60px;
    }
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .creator-profile-public {
    &__floating-buttons-panel {
      bottom: 58px;
    }

    &__header {
      margin-bottom: 70px;
    }

    &__photos-layout {
      margin-bottom: 50px;
    }

    &__creator-visits {
      margin-bottom: 50px;
    }

    &__divider {
      margin-bottom: 40px;
    }
  }
}
</style>
