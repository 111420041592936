<template>
  <div class="slide-content">
    <img class="slide-content__illustration" alt="slide1" src="/images/onboarding/planning.svg" style="width: 340px" />

    <div class="global-h3 slide-content__title">You have {{ `${onboardingParams.collabCount} ${onboardingParams.collabCount > 1 ? "collabs" : "collab"}` }} to use!</div>

    <div class="global-h5 slide-content__subtitle">Roughly when would you like your first visit to happen?</div>

    <VueDatePicker
      class="slide-content__datepicker"
      type="date"
      format="MMM d yyyy"
      v-model="firstCollabDate"
      :default-value="defaultDate"
      placeholder="Choose a date"
      autoApply
      :enableTimePicker="false"
      :teleportCenter="['mobile'].includes(screenSize)"
    />

    <p class="slide-content__text-paragraph slide-content__text-paragraph--italic">
      For each visit a creator will visit your community and produce 4 adventures full of photos and tips for locations you would like highlighted.
    </p>

    <div class="slide-content__divider"></div>

    <div class="global-h5 slide-content__subtitle">Do you have a theme in mind for this visit?</div>

    <textarea class="slide-content__textarea" v-model="collabTheme" autocapitalize="sentences"></textarea>

    <p class="slide-content__text-paragraph slide-content__text-paragraph--italic slide-content__text-paragraph--bigger-bottom-margin">you can change this later</p>

    <div class="slide-content__buttons-section">
      <div
        :class="{
          'ui orange large button': true,
          'slide-content__main-cta-button': true,
          'slide-content__main-cta-button--disabled': isLoading,
          'slide-content__main-cta-button--loading': isLoading && isClickedNextButton,
        }"
        @click="goToNextStep"
      >
        Next step&nbsp;<IconEmbedded :name="isLoading && isClickedNextButton ? 'loader_3-5' : 'arrow-bottom_3-5'" :size="26" color="rgba(255, 255, 255, 0.4)" />
      </div>

      <div
        :class="{
          'ui gray small button': true,
          'slide-content__additional-cta-button': true,
          'slide-content__additional-cta-button--disabled': isLoading || !(firstCollabDate && collabTheme),
          'slide-content__additional-cta-button--loading': isLoading && isClickedContinueButton,
        }"
        @click="handleContinue"
      >
        Continue setting up this visit&nbsp;<IconEmbedded :name="isLoading && isClickedContinueButton ? 'loader_3-5' : 'arrow-right_3-5'" :size="16" color="rgba(0, 0, 0, 0.2)" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import axios from "axios";
import DateUtils from "@logic/DateUtils";
import { defineComponent, inject } from "vue";

// Types
import { OnboardingCustomer } from "@contracts/onboarding";
import { ScreenSize } from "@contracts/screenSize";

// Components
import CopyTextNew from "@components/CopyTextNew.vue";
import LinkWithIcon from "@components/LinkWithIcon.vue";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import IconEmbedded from "@components/ui/IconEmbedded.vue";

export default defineComponent({
  name: "SlideCollab",

  components: {
    IconEmbedded,
    CopyTextNew,
    VueDatePicker,
    LinkWithIcon,
  },

  props: {
    onboardingParams: { type: Object as () => OnboardingCustomer, required: true },
    communityIdInContext: { type: String, required: true },
  },

  emits: ["goToPrevStep", "clickOnLink", "goToNextStep"],

  data() {
    return {
      screenSize: inject("screenSize") as ScreenSize,

      isLoading: false,
      isClickedNextButton: false,
      isClickedContinueButton: false,

      existingCollabId: "",

      defaultDate: DateUtils.addDays(new Date(), 60),
      firstCollabDate: null,

      collabTheme: "",
    };
  },

  watch: {
    onboardingParams: {
      handler() {
        this.updateLocalVariables();
      },
      deep: true,
    },
  },

  mounted() {
    this.updateLocalVariables();
  },

  methods: {
    updateLocalVariables(): void {
      if (this.onboardingParams.initialCollabDate) {
        this.defaultDate = new Date(this.onboardingParams.initialCollabDate);
        this.firstCollabDate = new Date(this.onboardingParams.initialCollabDate);
      }

      this.collabTheme = this.onboardingParams.collabTheme ?? "";

      this.existingCollabId = this.onboardingParams.onboardingCollabInputId;
    },

    async saveCollab(): Promise<void> {
      const payload = {
        initialCollabDate: this.firstCollabDate,
        themes: this.collabTheme,
      };

      this.isLoading = true;
      // @ts-ignore
      const { data } = await axios.put(`${import.meta.env.VITE_API_URL}/customer-onboard/${this.globalRoot.orgInContext.customerId}/collab`, payload);

      this.existingCollabId = data;
      // @ts-ignore
      await Promise.all([this.globalRoot.allCollabsComponent?.loadCollabs(this.globalRoot.orgInContext.customerId), this.globalRoot.customerAdminLeftNavComponent?.getOnboardingCustomerParams()]);

      this.isLoading = false;
    },

    async goToNextStep(): Promise<void> {
      if (this.firstCollabDate && this.collabTheme) {
        this.isClickedNextButton = true;

        await this.saveCollab();

        this.isClickedNextButton = false;
      }

      this.$emit("goToNextStep");
    },

    async handleContinue(): Promise<void> {
      this.isClickedContinueButton = true;

      await this.saveCollab();

      this.isClickedContinueButton = false;

      this.$router.replace({
        name: "PaidCollabCommunityAdmin",
        params: { communityId: this.communityIdInContext },
        hash: `#collab=${this.existingCollabId}`,
      });

      this.$emit("clickOnLink");
    },
  },
});
</script>

<style scoped lang="scss">
@import "./slide-styles.scss";
</style>
