<template>
  <div class="customization-controls">
    <div class="customization-controls__controls-snippets-wrap" style="width: 100%">
      <!-- Controls snippet -->
      <div class="controls-snippet customization-controls__controls-snippet customization-controls__controls-snippet--what-to-show">
        <div class="controls-snippet__header">
          <h5 class="global-h5 controls-snippet__title">What to Show</h5>
          <!--<div class="controls-snippet__subtitle">How many rows of photos to show?</div>-->
        </div>

        <div class="controls-snippet__content">
          <!-- "What to show" form -->
          <div class="what-to-show-form controls-snippet__what-to-show-form">
            <!-- "What to show" snippet -->
            <div class="what-to-show-snippet what-to-show-snippet--left what-to-show-form__snippet what-to-show-form__snippet--left">
              <label class="what-to-show-snippet__header">
                <input class="what-to-show-snippet__radio-button" type="radio" name="what to show" v-model="props.settings.alwaysShowAll" :value="false" />
                <span class="what-to-show-snippet__title">Set Number of Rows</span>

                <span class="what-to-show-snippet__darken-overlay"></span>
              </label>

              <div class="what-to-show-snippet__content">
                <NumberInputWithPlusMinus class="what-to-show-snippet__number-input" v-model:value="props.settings.rowsToShow" :min="1" />

                <div class="what-to-show-snippet__divider"></div>

                <SrpCheckbox class="what-to-show-snippet__show-more-checkbox" :isWithHalo="false" whiteSpace="wrap">
                  <template #input><input type="checkbox" v-model="props.settings.allowShowMore" /></template>
                  <template #text>“Show More” button enabled</template>
                </SrpCheckbox>
              </div>
            </div>
            <!-- / "What to show" snippet -->

            <div class="global-h5 what-to-show-form__or-circle">or</div>

            <!-- "What to show" snippet -->
            <div class="what-to-show-snippet what-to-show-snippet--right what-to-show-form__snippet what-to-show-form__snippet--right">
              <label class="what-to-show-snippet__header">
                <input class="what-to-show-snippet__radio-button" type="radio" name="what to show" v-model="props.settings.alwaysShowAll" :value="true" />
                <span class="what-to-show-snippet__title">Show All Photos</span>

                <span class="what-to-show-snippet__darken-overlay"></span>
              </label>

              <div class="what-to-show-snippet__content">
                Always show<br />
                all {{ props.numberOfImages }} photos
              </div>
            </div>
            <!-- / "What to show" snippet -->
          </div>
          <!-- / "What to show" form -->
        </div>
      </div>
      <!-- / Controls snippet -->

      <div v-if="isEqualOrBigger(screenSize, 'tablet-large')" class="customization-controls__divider customization-controls__divider--vertical"></div>
      <div v-else class="customization-controls__divider customization-controls__divider--horizontal"></div>

      <!-- Controls snippet -->
      <div class="controls-snippet customization-controls__controls-snippet customization-controls__controls-snippet--name">
        <div class="controls-snippet__header">
          <h5 class="global-h5 controls-snippet__title">
            Photo Wall Name
            <InfoIconWithTooltip>This isn't shown, it's just to help you tell photo walls apart.</InfoIconWithTooltip>
          </h5>
        </div>

        <div class="controls-snippet__content">
          <input
            class="global-text-input controls-snippet__name-text-input"
            type="text"
            placeholder="Photo Wall Name"
            :value="props.photoWallName"
            @input="$event => emit('update:photoWallName', ($event.target as HTMLInputElement).value)"
          />
        </div>
      </div>
      <!-- / Controls snippet -->

      <div class="customization-controls__divider customization-controls__divider--horizontal"></div>

      <!-- Controls snippet -->
      <div class="controls-snippet customization-controls__controls-snippet customization-controls__controls-snippet--photo-size">
        <div class="controls-snippet__header">
          <h5 class="global-h5 controls-snippet__title">Size</h5>
        </div>

        <div class="controls-snippet__content">
          <!-- Photo size selector -->
          <div class="photo-size-selector controls-snippet__photo-size-selector">
            <PhotoSizeRadio class="photo-size-selector__radio-snippet" size="small">
              <input id="PhotoSizeRadio-small" type="radio" name="PhotoSize" v-model="props.settings.imageHeight" :value="5" />
            </PhotoSizeRadio>
            <PhotoSizeRadio class="photo-size-selector__radio-snippet" size="medium">
              <input id="PhotoSizeRadio-medium" type="radio" name="PhotoSize" v-model="props.settings.imageHeight" :value="7" />
            </PhotoSizeRadio>
            <PhotoSizeRadio class="photo-size-selector__radio-snippet" size="large">
              <input id="PhotoSizeRadio-large" type="radio" name="PhotoSize" v-model="props.settings.imageHeight" :value="10" />
            </PhotoSizeRadio>
          </div>
          <!-- / Photo size selector -->
        </div>
      </div>
      <!-- / Controls snippet -->

      <div v-if="isEqualOrBigger(screenSize, 'tablet-large')" class="customization-controls__divider customization-controls__divider--vertical"></div>
      <div v-else class="customization-controls__divider customization-controls__divider--horizontal"></div>

      <!-- Controls snippet -->
      <div class="controls-snippet customization-controls__controls-snippet customization-controls__controls-snippet--padding">
        <div class="controls-snippet__header">
          <h5 class="global-h5 controls-snippet__title">Padding</h5>
        </div>

        <div class="controls-snippet__content">
          <PhotosPaddingSelector class="controls-snippet__padding-between-photos-selector" v-model:value="props.settings.padding" />
        </div>
      </div>
      <!-- / Controls snippet -->

      <div class="customization-controls__divider customization-controls__divider--vertical"></div>

      <!-- Controls snippet -->
      <div class="controls-snippet customization-controls__controls-snippet customization-controls__controls-snippet--accent-color">
        <div class="controls-snippet__header">
          <SrpCheckbox class="header__randomize-checkbox" :isWithHalo="false" @click.stop>
            <template #input><input type="checkbox" v-model="props.settings.randomizePhotos" /></template>
            <template #text><b>Randomize photos</b></template>
          </SrpCheckbox>
        </div>
        <div class="customization-controls__divider customization-controls__divider--horizontal" style="margin-top: 12px"></div>
        <div class="controls-snippet__content">
          <GlobalAccentColorPicker />
        </div>
        <div class="customization-controls__divider customization-controls__divider--horizontal" style="margin-top: 12px"></div>
        <div class="controls-snippet__content">
          <LinkWithIcon isDottedUnderline @click="showMoreButtonCustomizationModal = true">
            <!--<template #icon><IconEmbedded name="pencil_2" :size="20" /></template>-->
            <span style="font-weight: bold">Customize Photo Buttons</span>
          </LinkWithIcon>
        </div>
      </div>
      <!-- / Controls snippet -->
    </div>
    <SrpModal v-model:isVisible="showMoreButtonCustomizationModal">
      <template #header>
        <h2 class="global-h2">Customize Photo Buttons</h2>
      </template>
      <template #content>
        <div style="margin-bottom: 10px">Customize the button that appears when photos are clicked and shown full screen.</div>
        <SrpCheckbox class="header__randomize-checkbox" :isWithHalo="false" @click.stop>
          <template #input><input type="checkbox" v-model="props.settings.buttonSettings.hide" /></template>
          <template #text><b>Hide Button</b></template>
        </SrpCheckbox>
        <div v-if="!props.settings.buttonSettings.hide" style="margin-top: 10px">
          <h3 style="margin-bottom: 5px">Button Text:</h3>
          <input type="text" v-model="props.settings.buttonSettings.text" placeholder="See More" />
          <h3 style="margin-bottom: 5px; margin-top: 12px">Button Link:</h3>
          <input type="text" v-model="props.settings.buttonSettings.uri" @blur="ensureHttps" :placeholder="`https://shrpa.com/destination/${props.customerId}`" />

          <div style="margin-top: 10px">* If no Link is set, the button will link to your Shrpa Destination page.</div>
        </div>
      </template>
      <template #footer>
        <SrpButton
          class="ui primary button"
          @click="
            () => {
              showMoreButtonCustomizationModal = false;
              emit('save');
            }
          "
          >Save</SrpButton
        >
      </template>
    </SrpModal>
  </div>
</template>

<script setup lang="ts">
import { inject, ref, Ref, watch } from "vue";
import { isEqualOrBigger } from "@helpers/ScreenSizeHelper";

// Components
import GlobalAccentColorPicker from "@components/GlobalAccentColorPicker.vue";
import InfoIconWithTooltip from "@components/ui/InfoIconWithTooltip.vue";
import NumberInputWithPlusMinus from "@components/ui/NumberInputWithPlusMinus.vue";
import PhotoSizeRadio from "./PhotoSizeRadio.vue";
import PhotosPaddingSelector from "./PhotosPaddingSelector.vue";
import LinkWithIcon from "@components/LinkWithIcon.vue";
import SrpCheckbox from "@components/ui/SrpCheckbox.vue";
import SrpModal from "@/components/ui/SrpModal.vue";
import SrpButton from "@components/ui/SrpButton.vue";

// Types
import { PhotoWidgetSettings } from "@contracts/photoWidget";
import { ScreenSize } from "@contracts/screenSize";

// Global variables & injections
const screenSize = inject("screenSize") as Ref<ScreenSize>;

const props = withDefaults(
  defineProps<{
    customerId: string | null;
    settings: PhotoWidgetSettings | null;
    photoWallName: string | null;
    numberOfImages: number | null;
  }>(),
  {
    customerId: null,
    settings: null,
    photoWallName: null,
    numberOfImages: null,
  }
);

const showMoreButtonCustomizationModal = ref(false);

const emit = defineEmits<{
  (e: "update:photoWallName", value: string): void;
  (e: "save"): void;
}>();

function ensureHttps(): string | null {
  let trimmed = props.settings.buttonSettings.uri?.trim();
  if (!trimmed) return;

  // Otherwise, if it doesn't start with https://, fix it
  if (!trimmed.includes("https://") && trimmed !== "") {
    trimmed = "https://" + trimmed.replace(/^https?:\/\//i, "");
  }
  props.settings.buttonSettings.uri = trimmed;
}
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";

// "What to show" snippet =====================================================
.what-to-show-snippet {
  padding: 13px 15px;
  border-radius: 4px;
  position: relative;
  z-index: 0;

  &::before {
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 4px;
    position: absolute;
    inset: 0 auto auto 0;
    z-index: -2;
    background: rgba(232, 232, 232, 1);
  }

  &--left {
    padding-right: 27px;
  }
  &--right {
    padding-left: 22px;
  }

  &__header {
    display: flex;
    align-content: center;
    cursor: pointer;
  }

  &__radio-button {
    width: 18px;
    margin-right: 6px;
  }

  &__title {
    color: rgba(0, 0, 0, 1);
    font-weight: bold;
    user-select: none;
  }
  &__header:hover &__title {
    opacity: 0.7;
  }

  &__darken-overlay {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border: 2px rgba(84, 179, 180) solid;
    border-radius: 4px;
    display: block;
    position: absolute;
    inset: 0 auto auto 0;
    z-index: -1;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.05s ease-in-out;
  }
  &__radio-button:checked + * + &__darken-overlay {
    opacity: 1;
  }

  &__content {
    padding-left: 25px;
    display: flex;
    align-items: center;
  }

  &__number-input {
    margin-right: 20px;
  }

  &__divider {
    width: 0;
    max-width: 0;
    height: 25px;
    margin-right: 20px;
    border: 1px rgba(0, 0, 0, 0.1) solid;
    border-width: 0 1px 0 0;
  }

  &__show-more-checkbox {
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
  .what-to-show-snippet {
    &__content {
      flex-direction: column;
      align-items: flex-start;
    }

    &__number-input {
      margin: 0 0 5px 0;
    }

    &__divider {
      width: 100%;
      min-width: 100%;
      height: 0;
      margin: 0 0 4px 0;
      border-width: 0 0 1px 0;
    }
  }
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .what-to-show-snippet {
    &__content {
      flex-direction: column;
      align-items: flex-start;
    }

    &__number-input {
      margin: 0 0 5px 0;
    }

    &__divider {
      width: 100%;
      min-width: 100%;
      height: 0;
      margin: 0 0 4px 0;
      border-width: 0 0 1px 0;
    }
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .what-to-show-snippet {
    &__content {
      flex-direction: column;
      align-items: flex-start;
    }

    &__number-input {
      margin: 0 0 5px 0;
    }

    &__divider {
      width: 100%;
      min-width: 100%;
      height: 0;
      margin: 0 0 4px 0;
      border-width: 0 0 1px 0;
    }
  }
}

// "What to show" form ========================================================
.what-to-show-form {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 0;

  &__snippet {
    flex-grow: 1;
    align-self: stretch;

    &--left {
      width: 62%;
      max-width: 62%;
    }

    &--right {
    }
  }

  &__or-circle {
    width: 40px;
    min-width: 40px;
    height: 40px;
    padding: 0 0 2px;
    margin: 0 -16px;
    box-sizing: border-box;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 1;
    background: rgba(255, 255, 255, 1);
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .what-to-show-form {
    &__snippet {
      &--left {
        width: 58%;
        max-width: 58%;
      }

      &--right {
      }
    }
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
}

// Photo size selector ========================================================
.photo-size-selector {
  gap: 7px;
  display: flex;
  align-items: flex-start;

  &__radio-snippet {
    flex-grow: 1;
  }
}

// Controls snippet ===========================================================
.controls-snippet {
  color: rgba(91, 91, 91, 1);
  font: 14px/18px sans-serif;

  &__header {
  }

  &__title {
    margin-bottom: 5px;
    font-weight: 600;
  }

  &__subtitle {
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__name-text-input {
  }

  &__what-to-show-form {
    width: 100%;
  }

  &__photo-size-selector {
    width: 100%;
  }

  &__padding-between-photos-selector {
  }

  &__accent-color-snippet {
  }
}

// Header =====================================================================
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  &__left-side {
    gap: 10px;
    display: flex;
    align-items: center;
  }

  &__title {
    padding-bottom: 10px;
  }

  &__right-side {
    gap: 10px;
    display: flex;
    align-items: center;
  }

  &__randomize-checkbox {
    padding-bottom: 6px;
  }
}

// Customization controls =====================================================
.customization-controls {
  padding: 15px 18px 10px;
  border-radius: 6px;
  background: rgba(255, 255, 255, 1);

  &__details-summary {
  }

  :deep(.srp-summary__title-wrap) {
    padding-left: 5px;
  }

  &__header {
    width: 100%;
  }

  &__controls-snippets-wrap {
    padding: 2px 0 10px;
    display: flex;
    flex-wrap: wrap;
  }

  :deep(.srp-summary__details--with-top-margin) {
    margin-top: 9px;
  }

  &__controls-snippet {
    flex-grow: 1;

    &--name {
      width: 315px;
      max-width: 315px;
    }
    &--what-to-show {
      width: 440px;
    }
    &--photo-size {
      width: 435px;
      max-width: 435px;
    }
    &--padding {
      width: 205px;
      max-width: 205px;
    }
    &--accent-color {
      width: 335px;
      max-width: 335px;
    }
  }

  &__divider {
    border: 1px rgba(0, 0, 0, 0.1) solid;

    &--vertical {
      width: 0;
      margin: 0 25px;
      border-width: 0 1px 0 0;
    }

    &--horizontal {
      width: 100%;
      height: 0;
      margin: 30px 0 15px;
      border-width: 0 0 1px 0;
    }
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
  .customization-controls {
    &__controls-snippet {
      &--name {
        width: 281px;
        max-width: 281px;
      }
      &--what-to-show {
      }
      &--photo-size {
        width: 390px;
        max-width: 390px;
      }
      &--padding {
        width: 244px;
        max-width: 244px;
      }
      &--accent-color {
        width: 170px;
        max-width: 170px;
      }
    }
  }
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
  .customization-controls {
    &__controls-snippet {
      &--name {
        width: 261px;
        max-width: 261px;
      }
      &--what-to-show {
      }
      &--photo-size {
        width: 333px;
        max-width: 333px;
      }
      &--padding {
        width: 172px;
        max-width: 172px;
      }
      &--accent-color {
        width: 175px;
        max-width: 175px;
      }
    }
  }
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
  .customization-controls {
    &__controls-snippet {
      &--name {
        width: 228px;
        max-width: 228px;
      }
      &--what-to-show {
        width: 447px;
        max-width: 447px;
      }
      &--photo-size {
        width: 326px;
        max-width: 326px;
      }
      &--padding {
        width: 145px;
        max-width: 145px;
      }
      &--accent-color {
        width: 153px;
        max-width: 153px;
      }
    }
  }
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .customization-controls {
    &__controls-snippet {
      &--name {
        width: 100%;
        max-width: 350px;
      }
      &--what-to-show {
        width: 100%;
        max-width: 100%;
      }
      &--photo-size {
        width: 100%;
        max-width: 100%;
      }
      &--padding {
        width: calc(50% - 26px);
        max-width: calc(50% - 26px);
      }
      &--accent-color {
        width: calc(50% - 26px);
        max-width: calc(50% - 26px);
      }
    }
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .customization-controls {
    &__controls-snippet {
      &--name {
        width: 100%;
        max-width: 350px;
      }
      &--what-to-show {
        width: 100%;
        max-width: 100%;
      }
      &--photo-size {
        width: 100%;
        max-width: 100%;
      }
      &--padding {
        width: calc(50% - 26px);
        max-width: calc(50% - 26px);
      }
      &--accent-color {
        width: calc(50% - 26px);
        max-width: calc(50% - 26px);
      }
    }
  }
}
</style>
