<template>
  <div class="creator-dashboard-page">
    <Loader v-if="availableCollabsV2Store.isLoading" style="position: absolute" />

    <template v-else>
      <template v-if="!availableCollabsV2Store.data?.openCollabsInRange?.length && !availableCollabsV2Store.data?.destinationsInRange?.length">
        <div style="margin-bottom: 40px; display: block; font-size: 1.05rem">
          <h2 class="global-h2">Help us help you!</h2>
          Currently, there aren't any Shrpa Creator Visits in your area. We're working to address that by introducing Shrpa to more destinations, <b>but we would love your help!</b>
          <ul>
            <li>
              <b>Tell your local destinations about us!</b> We are working with Visitor Bureaus, DMOs, and Chambers of Commerce of all sizes. Introductions to your local organizations can help move
              the process forward!
            </li>
            <li style="margin-top: 8px">
              <b>Recruit other creators!</b> To provide the best experience, destinations in your area need multiple creators to select for their visits. The more creators in your area, the faster we
              can start actively selling to area destinations (if we aren't already).
            </li>
          </ul>
        </div>
      </template>

      <template v-if="availableCollabsV2Store.data?.openCollabsInRange?.length">
        <SnippetsListSection
          class="creator-dashboard-page__snippets-list-section"
          title="Open Shrpa Creator Visits"
          subTitle="Apply today to work with destinations!"
          :snippetsList="sortCollabsList(availableCollabsV2Store.data?.openCollabsInRange, sortingType)"
          :howManyToShowInitially="null"
        >
          <template #headerRightSide>
            <SortingRadioHandles
              :valuesObj="{
                'Near me first': CollabsSortingTypes.NearMe,
                'Recent first': CollabsSortingTypes.Recent,
              }"
              v-model:value="sortingType"
            />
          </template>
          <template #default="{ snippetData }">
            <OpenCollabSnippet :collab="snippetData" :creatorCurrentRate="availableCollabsV2Store.data?.creatorCurrentRate" :creatorId="availableCollabsV2Store.data.creatorId" />
          </template>
        </SnippetsListSection>

        <div class="creator-dashboard-page__divider"></div>
      </template>

      <template v-if="availableCollabsV2Store.data?.openCollabsOutOfRange?.length">
        <SnippetsListSection
          class="creator-dashboard-page__snippets-list-section"
          :title="availableCollabsV2Store.data?.openCollabsInRange?.length ? 'Outside Your Boundary' : 'Open Shrpa Creator Visits Outside Your Area'"
          subTitle="Shrpa Creator Visits that are open to apply today but are further away."
          :snippetsList="sortCollabsList(availableCollabsV2Store.data?.openCollabsOutOfRange, sortingType)"
          :howManyToShowInitially="2"
        >
          <template v-if="!availableCollabsV2Store.data?.openCollabsInRange?.length" #headerRightSide>
            <SortingRadioHandles
              :valuesObj="{
                'Near me first': CollabsSortingTypes.NearMe,
                'Recent first': CollabsSortingTypes.Recent,
              }"
              v-model:value="sortingType"
            />
          </template>
          <template #default="{ snippetData }">
            <OpenCollabSnippet :collab="snippetData" :creatorCurrentRate="availableCollabsV2Store.data?.creatorCurrentRate" :creatorId="availableCollabsV2Store.data.creatorId" />
          </template>
        </SnippetsListSection>
      </template>

      <SrpDetailsSummary
        v-if="availableCollabsV2Store.data?.destinationsInRange?.length || availableCollabsV2Store.data?.destinationsOutOfRange?.length"
        class="creator-dashboard-page__gray-bg-section"
        :transitionDuration="150"
        :isOpened="true"
      >
        <template #heading>
          <div>
            <div class="global-h2" style="margin-bottom: 8px !important">
              <span><span>Upcoming Shrpa Creator Visits</span></span>
            </div>
            <p v-if="availableCollabsV2Store.data?.destinationsInRange?.length" style="color: rgba(91, 91, 91, 1); font: 14px/18px sans-serif">
              Destinations with Shrpa Creator Visits on the horizon! (expect in 2-12 months)
            </p>
          </div>
        </template>
        <template #details>
          <SnippetsListSection
            v-if="availableCollabsV2Store.data?.destinationsInRange?.length"
            class="creator-dashboard-page__snippets-list-section"
            :snippetsList="sortCollabsList(availableCollabsV2Store.data?.destinationsInRange, sortingType)"
          >
            <template #default="{ snippetData }">
              <DestinationUpcomingCollabsSnippet :destination="snippetData" />
            </template>
          </SnippetsListSection>

          <template v-if="availableCollabsV2Store.data?.destinationsOutOfRange?.length">
            <div class="creator-dashboard-page__divider"></div>

            <SnippetsListSection
              class="creator-dashboard-page__snippets-list-section"
              title="Outside Your Boundary"
              subTitle="Destinations further away with Shrpa Creator Visits on the horizon. (expect in 2-12 months)"
              :snippetsList="sortCollabsList(availableCollabsV2Store.data?.destinationsOutOfRange, sortingType)"
            >
              <template #default="{ snippetData }">
                <DestinationUpcomingCollabsSnippet :destination="snippetData" />
              </template>
            </SnippetsListSection>
          </template>
        </template>
      </SrpDetailsSummary>
    </template>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from "vue";

// Components
import CopyText from "@components/CopyText.vue";
import DestinationUpcomingCollabsSnippet from "./snippets/DestinationUpcomingCollabsSnippet.vue";
import Loader from "@components/Loader/Loader.vue";
import OpenCollabSnippet from "./snippets/OpenCollabSnippet.vue";
import SnippetsListSection from "./SnippetsListSection.vue";
import SortingRadioHandles from "./SortingRadioHandles.vue";
import SrpCheckbox from "@components/ui/SrpCheckbox.vue";
import SrpDetailsSummary from "@components/ui/SrpDetailsSummary.vue";

// Types
import { DestinationCollabSummaryForCreator } from "@contracts/collabCredits";

// Stores
import { useUserProfileStore } from "@stores/userProfileStore";
import { useAvailableCollabsV2Store } from "@stores/availableCollabsV2";
const availableCollabsV2Store = useAvailableCollabsV2Store();

const userProfileStore = useUserProfileStore();

// Load available collabs data ================================================
onMounted(async () => {
  await availableCollabsV2Store.loadAvailableCollabs(userProfileStore.getViewingUserProfile.sherpaId);
});

// Handle sorting =============================================================
enum CollabsSortingTypes {
  NearMe = "Near me",
  Recent = "Recent",
}
const sortingType = ref<CollabsSortingTypes>(CollabsSortingTypes.NearMe);

function sortCollabsList(list: Array<DestinationCollabSummaryForCreator>, sortingType: CollabsSortingTypes) {
  // Pick the date field that is NOT empty for all the collabs
  const dateSortingField = list.map(c => c.preferredDateRangeStart).includes(null) ? "createdDate" : "preferredDateRangeStart";

  if (sortingType === CollabsSortingTypes.Recent) {
    return [...list].sort((a, b) => {
      return +new Date(a[dateSortingField]) - +new Date(b[dateSortingField]);
    });
  } else if (sortingType === CollabsSortingTypes.NearMe) {
    return [...list].sort((a, b) => a.proximityInMiles - b.proximityInMiles);
  }
}
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";

// Header =====================================================================
.header {
  &__title {
    margin-bottom: 8px;
  }

  &__subtitle {
  }
}

// Creator dashboard page =====================================================
.creator-dashboard-page {
  width: $desktop-wide-right-col-width;
  min-height: 250px;
  margin: 0 auto;
  position: relative;

  &__header {
    margin-bottom: 20px;

    &--bigger-margin-bottom {
      margin-bottom: 35px;
    }
  }

  &__divider {
    margin-bottom: 35px;
    border-bottom: 1px rgba(0, 0, 0, 0.1) solid;
  }

  &__snippets-list-section {
    padding: 0;
    margin: 0 0 45px;
    list-style: none;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__gray-bg-section {
    padding: 45px 0 50px;
    margin-bottom: 50px;
    position: relative;
    z-index: 0;

    &::before {
      content: "";
      width: calc(100% + 250px);
      height: 100%;
      position: absolute;
      inset: 0 auto auto 50%;
      transform: translateX(-50%);
      background: linear-gradient(90deg, rgba(244, 244, 244, 0) 0%, rgba(244, 244, 244, 1) 5%, rgba(244, 244, 244, 1) 95%, rgba(244, 244, 244, 0) 100%);
    }
  }

  &__activity-feed-wrap {
    padding: 0;
    margin: 0 0 60px;
    display: flex;
    justify-content: flex-start;
    list-style: none;
    position: relative;
    z-index: 0;
    overflow: hidden;

    &::before {
      content: "";
      width: 100%;
      height: 200px;
      position: absolute;
      inset: auto auto 0 0;
      z-index: 2;
      background: linear-gradient(0deg, rgba(255, 255, 255, 1) 26%, rgba(255, 255, 255, 0) 100%) !important;
      opacity: 0;
      transition: opacity 0.4s ease-in-out;
    }

    &--with-bottom-curtain {
      &::before {
        content: "";
        width: 100%;
        height: 200px;
        position: absolute;
        inset: auto auto 0 0;
        z-index: 2;
        background: linear-gradient(0deg, rgba(255, 255, 255, 1) 26%, rgba(255, 255, 255, 0) 100%) !important;
        opacity: 1;
      }
    }
  }

  &__activity-feed {
    max-width: 600px;
    margin: 0 !important;
    align-self: flex-start;
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
  .creator-dashboard-page {
    width: $desktop-right-col-width;

    &__gray-bg-section {
      &::before {
        width: calc(100vw - 200px);
      }
    }
  }
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
  .creator-dashboard-page {
    width: $laptop-right-col-width;

    &__gray-bg-section {
      &::before {
        width: calc(100vw - 100px);
      }
    }
  }
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
  .creator-dashboard-page {
    width: $tablet-large-content-width;

    &__gray-bg-section {
      &::before {
        width: 100vw;
      }
    }
  }
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .creator-dashboard-page {
    width: $tablet-content-width;

    &__gray-bg-section {
      &::before {
        width: 100vw;
      }
    }
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .creator-dashboard-page {
    width: $mobile-content-width;

    &__gray-bg-section {
      padding: 30px 0 45px;
      margin-bottom: 50px;
      position: relative;
      z-index: 0;

      &::before {
        width: 100vw;
      }
    }
  }
}
</style>
