<template>
  <!-- Show if initializing, or if authenticated they're just waiting to be redirected-->
  <div v-if="!hasAuthenticationInitialized || isAuthenticated" class="auth-init-container">
    <h1 class="auth-init-message">Initializing...</h1>
    <div class="spinner"></div>
    <h2>Almost there!</h2>
  </div>

  <div v-else>
    <!--Not Authenticated yet-->
    <teleport to=".page-content">
      <NotAuthenticatedYetSection v-if="!isAuthenticated" @authenticate="authenticate" style="width: var(--grid-col-right-width); margin: 0 auto" />
    </teleport>
  </div>

  <LoginModal :startWithNewUserFlow="true" :isVisible="isShowLoginModal" @close="isShowLoginModal = false" />
</template>

<script setup lang="ts">
import { ref, watch, onMounted } from "vue";
import NotAuthenticatedYetSection from "@views/Creator/CreatorDashboard/NotAuthenticatedYetSection.vue";
import { getInstance } from "@auth/authWrapper";
import { useUserProfileStore } from "@/stores/userProfileStore";
import LoginModal from "@/components/Modals/LoginModal.vue";
import router from "@/router";
import { CreatorRedirectHelper } from "@/logic/CreatorRedirectHelper";
import { useRoute } from "vue-router";
import { CreatorInviteKey } from "@/logic/CreatorInviteKey";

/* NOTES
   - This is the landing page for unauthenticated creators that guides them how to log in (like the Community Dashboard)
   - This has an optional parameter to specify the user's Id, otherwise it uses the profile in context
*/

// NOTE: The below might be overkill for this since we probably don't need to support the unauthenticated scenarios.  Also might not need to wait for profileLoading (just auth)
/* Note: This Init sequence is fairly complicated since we want to handle several scenarios:
 * 1. Auth is still loading (so we don't know much yet)
 * 2. User is not logged in
 * 3. Profile is loaded (user must be logged in)
 *
 * This logic is similar to the community dashboard
 */

const hasAuthenticationInitialized = ref(false);
const isAuthenticated = ref(false);
const authService = getInstance();
if (authService.loading === false) {
  authLoaded();
}
authService.$watch("loading", loading => {
  if (loading === false) {
    authLoaded();
  }
});

function authLoaded() {
  console.log("UnauthenticatedCreator.authLoaded");
  hasAuthenticationInitialized.value = true;
  const authService = getInstance();
  isAuthenticated.value = authService.isAuthenticated;
}

const userProfileStore = useUserProfileStore();
// Watch it in case it's in the process of being loaded
watch(
  () => userProfileStore.loggedInUser,
  profile => {
    if (profile) {
      profileLoaded();
    }
  }
);

const route = useRoute();
onMounted(() => {
  // Check for an invite key (currently used for Custom Collabs)
  CreatorInviteKey.checkAndSaveInviteKey(route);
  if (userProfileStore.loggedInUser) {
    profileLoaded();
  }
});

function profileLoaded() {
  console.log("UnauthenticatedCreator.profileLoaded");
  if (userProfileStore.getViewingUserProfile?.adminOf?.length > 0) {
    router.push({ name: "CommunityAdminDashboard", params: { communityId: userProfileStore.getViewingUserProfile.adminOf[0] } });
  } else if (checkForRedirect()) {
    return;
  } else {
    // Redirect to the creator dashboard
    router.push({ name: "CreatorDashboard", params: { creatorId: userProfileStore.getViewingUserProfile.sherpaId } });
  }
}

const isShowLoginModal = ref(false);
function authenticate() {
  isShowLoginModal.value = true;
}

function checkForRedirect() {
  const redirecting = CreatorRedirectHelper.checkForRedirect(router, userProfileStore.getViewingUserProfile.sherpaId);
  return redirecting;
}
</script>

<style scoped lang="scss">
@import "@/scss/variables.scss";
@import "@/scss/screen-size-ranges.scss";
// Section while auth is initializing
.auth-init-container {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.spinner {
  border: 6px solid rgba(0, 0, 0, 0.1);
  border-left-color: $brand-color;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.auth-init-message {
  margin-top: 20px;
}
</style>
