<template>
  <div
    :class="{
      'work-status-snippet': true,
      'work-status-snippet--yellow-bg': props.customCollabClientPayload.creatorCollabInputs.status === CustomCollabStatuses.InProgress,
      'work-status-snippet--green-bg': props.customCollabClientPayload.creatorCollabInputs.status === CustomCollabStatuses.Complete,
    }"
  >
    <div class="work-status-snippet__header">
      <h3 class="global-h3 work-status-snippet__title">Work Status</h3>
      <CollabStatusStripe class="work-status-snippet__status-badge" :text="getStatusText(props.customCollabClientPayload.creatorCollabInputs.status)" />
    </div>

    <template v-if="props.customCollabClientPayload.creatorCollabInputs.status !== CustomCollabStatuses.Planning">
      <div class="work-status-snippet__divider"></div>

      <!-- Creator notes snippet -->
      <div class="creator-notes-snippet work-status-snippet__creator-notes-snippet">
        <AvatarWithFallback class="creator-notes-snippet__avatar" :src="`${contentBaseUri}/cms/images/profiles/${props.customCollabClientPayload.creator.creatorProfileImage}`" />

        <div class="creator-notes-snippet__name-and-note">
          <div class="global-h4 creator-notes-snippet__name">
            {{ props.customCollabClientPayload.creator.creatorName }}
            <LinkWithIcon
              v-if="isSuperOrSalesUser && props.customCollabClientPayload?.customerCollabInputs?.creatorId"
              :to="{
                name: 'CreatorCustomCollabDetails',
                params: {
                  creatorId: props.customCollabClientPayload.customerCollabInputs.creatorId,
                  communityId: props.customCollabClientPayload.customerCollabInputs.customerId,
                  customCollabId: props.customCollabClientPayload.customerCollabInputs.id,
                },
              }"
              tag="RouterLink"
            >
              <template #icon><IconEmbedded name="trending-up_2-5" :size="21" /></template>
            </LinkWithIcon>
          </div>
          <!-- Note itself -->
          <div v-if="props.customCollabClientPayload.creatorCollabInputs.creatorNotes" class="note-itself creator-notes-snippet__note-itself">
            <div class="note-itself__header">
              <IconEmbedded class="note-itself__icon" name="file-text_2" :size="19" color="rgba(0, 0, 0, 0.5)" />
              <div class="note-itself__title">Creator notes</div>
            </div>
            <div class="note-itself__text">
              {{ props.customCollabClientPayload.creatorCollabInputs.creatorNotes }}
            </div>
          </div>
          <!-- / Note itself -->
        </div>
      </div>
      <!-- / Creator notes snippet -->
    </template>

    <template v-if="props.customCollabClientPayload.creatorCollabInputs.deliverablesAreReady">
      <div class="work-status-snippet__divider"></div>

      <!-- Deliverables snippet -->
      <div class="deliverables-snippet work-status-snippet__deliverables-snippet">
        <div class="global-h4 deliverables-snippet__title">Deliverables</div>

        <div class="deliverables-snippet__text">Final edits complete. All photos have been uploaded to the shared drive:</div>
        <div class="deliverables-snippet__link-wrap" v-for="deliverableLink in props.customCollabClientPayload.creatorCollabInputs.deliverables" :key="deliverableLink">
          <a class="global-a deliverables-snippet__link" :href="deliverableLink" target="_blank"
            ><b>{{ deliverableLink }}</b></a
          >&nbsp;<IconEmbedded name="external-link_2" :size="15" color="rgba(0, 0, 0, 0.5)" style="position: relative; top: 2px" />
        </div>
      </div>
      <!-- / Deliverables snippet -->
    </template>

    <template v-if="isCompleted">
      <div class="work-status-snippet__divider"></div>

      <!-- What's next snippet -->
      <div class="whats-next-snippet work-status-snippet__whats-next-snippet">
        <div class="global-h4 whats-next-snippet__title">What's next</div>
        <div class="whats-next-snippet__text">If you have questions or changes, send a message to the creator.</div>
        <div class="whats-next-snippet__mark-as-paid-wrap">
          Or if it looks good:
          <SrpButton class="whats-next-snippet__mark-as-paid-button" size="small">Mark as Paid</SrpButton>
        </div>
      </div>
      <!-- / What's next snippet -->
    </template>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";

// Components
import CollabStatusStripe from "@components/CollabStatusStripe.vue";
import IconEmbedded from "@components/ui/IconEmbedded.vue";
import AvatarWithFallback from "@components/AvatarWithFallback.vue";
import SrpButton from "@components/ui/SrpButton.vue";
import LinkWithIcon from "@components/LinkWithIcon.vue";

// Types
import { CustomCollabClientPayload, CustomCollabStatuses } from "@contracts/customCollabs";
import { getStatusText } from "@helpers/CustomCollabsHelper";

import { useUserProfileStore } from "@stores/userProfileStore";

// Global variables & injections
const contentBaseUri = globalThis.Bootstrap.Config.contentCdnBaseUri;

const props = withDefaults(
  defineProps<{
    customCollabClientPayload: CustomCollabClientPayload | null;
  }>(),
  {
    customCollabClientPayload: null,
  }
);

// Super access
const userProfileStore = useUserProfileStore();
const isSuperOrSalesUser = computed(() => userProfileStore.isSuperOrSalesUser);

// Check Is Completed =========================================================
const isCompleted = computed<boolean>(() => {
  return props.customCollabClientPayload.creatorCollabInputs.status === CustomCollabStatuses.Complete;
});
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";
@import "@/scss/variables.scss";

// Note itself ================================================================
.note-itself {
  @include standardFont;
  padding: 11px 11px 13px;
  border-radius: 6px;
  position: relative;
  z-index: 0;
  background: rgba(255, 255, 255, 1);

  &::before {
    content: "";
    width: calc(23px + 6px);
    height: 26px;
    position: absolute;
    inset: 0 auto auto -15px;
    background: rgba(255, 255, 255, 1);
    clip-path: polygon(0 0, 100% 0, 100% 100%);
  }

  &__header {
    display: flex;
    align-items: center;
  }

  &__icon {
    margin-right: 3px;
  }

  &__title {
    font-weight: bold;
  }

  &__text {
    padding-left: 23px;
  }
}

// Creator notes snippet ======================================================
.creator-notes-snippet {
  gap: 24px;
  display: flex;
  align-items: flex-start;

  &__avatar {
    width: 95px;
    position: relative;
    top: 2px;
  }

  &__name-and-note {
  }

  &__name {
    margin-bottom: 10px;
    font-weight: 500;
  }

  &__note-itself {
  }
}

// Deliverables snippet =======================================================
.deliverables-snippet {
  @include standardFont;
  padding: 15px 19px 18px;
  border-radius: 6px;
  background: rgba(0, 0, 0, 0.1);

  &__title {
    margin-bottom: 4px;
    font-weight: 500;
  }

  &__text {
    margin-bottom: 3px;
  }

  &__link-wrap {
  }

  &__link {
  }
}

// What's next snippet ========================================================
.whats-next-snippet {
  @include standardFont;

  &__title {
    margin-bottom: 7px;
    font-weight: 500;
  }

  &__text {
    margin-bottom: 10px;
  }

  &__mark-as-paid-wrap {
    gap: 5px 6px;
    display: flex;
    align-items: center;
    font-weight: bold;
  }

  &__mark-as-paid-button {
  }
}

// Work status snippet ========================================================
.work-status-snippet {
  padding: 20px 25px;
  border-radius: 6px;
  background: rgba(240, 240, 240, 1);

  &--yellow-bg {
    background: rgba(240, 231, 184, 1);
  }
  &--green-bg {
    background: rgba(213, 236, 206, 1);
  }

  &__header {
    gap: 12px 15px;
    padding-bottom: 16px;
    display: flex;
    align-items: center;

    &:last-child {
      padding-bottom: 0;
    }
  }

  &__title {
  }

  &__status-badge {
  }

  &__divider {
    margin-bottom: 15px;
    border-bottom: 1px rgba(0, 0, 0, 0.15) solid;
  }

  &__creator-notes-snippet {
    margin-bottom: 25px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__deliverables-snippet {
    margin-bottom: 25px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__whats-next-snippet {
    margin-bottom: 25px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
