<template>
  <div class="custom-collab-details-page">
    <Loader v-if="isLoading" />

    <template v-else>
      <h1 class="global-h1 custom-collab-details-page__title">Restaurant Week Hype</h1>

      <div class="custom-collab-details-page__divider"></div>

      <!-- Community logo snippet -->
      <div class="community-logo-snippet custom-collab-details-page__community-logo-snippet">
        <div class="community-logo-snippet__logo-wrap">
          <img class="community-logo-snippet__logo" :src="`${contentBaseUri}/cms/images/orgs/${'7f675a26-424a-43fb-a44e-26b22ddbfa8e.png'}`" alt="Funtown" />
        </div>
        <div class="global-h3 community-logo-snippet__name">Funtown</div>
      </div>
      <!-- / Community logo snippet -->

      <div class="custom-collab-details-page__content">
        <div class="custom-collab-details-page__content-left-col">
          <div class="custom-collab-details-page__white-snippet-wrap" style="padding: 0; display: flex; flex-direction: column">
            <h3 class="global-h3" style="margin: 20px 24px 20px">Messages</h3>

            <CollabMessagesThread
              style="flex-grow: 1"
              :isImpersonationToggleVisible="false"
              :isAutoScrollToBottom="false"
              :isAutoFocusTextarea="false"
              :isMsgBubblesFullWidth="true"
              :messageThreadId="'7ea7521b-0b68-4f27-9f24-a49e0459e85c'"
              :customerId="'funtown'"
              :creatorDetails="{
                creatorName: 'sarah.anderson@example.com',
                creatorProfileImage: 'd7b5848c-7dce-44a3-aef3-1c8de4fa2d79.jpg',
              }"
              :collabInput="{}"
              messageSenderType="CustomerId"
              @isImpersonateChange="() => {}"
              :customCollabId="'1'"
            />
          </div>
        </div>

        <div class="custom-collab-details-page__content-right-col">
          <WorkStatusSnippet class="custom-collab-details-page__work-status-snippet" />

          <CollabDetailsInfo class="custom-collab-details-page__collab-details-snippet" />

          <HowItWork class="custom-collab-details-page__how-it-work-snippet" :isOpened="false" :isWithCtaButton="false" />
        </div>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { useRoute } from "vue-router";

// Components
import WorkStatusSnippet from "./WorkStatusSnippet.vue";
import HowItWork from "./HowItWork.vue";
import CollabMessagesThread from "@views/PaidCollab/CollabMessagesThread/index.vue";
import Loader from "@components/Loader/Loader.vue";
import CollabDetailsInfo from "./CollabDetailsInfo.vue";

// Global variables & injections
const contentBaseUri = globalThis.Bootstrap.Config.contentCdnBaseUri;

const isLoading = ref<boolean>(false);
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";

// Community logo snippet =====================================================
.community-logo-snippet {
  display: flex;
  align-items: center;

  &__logo-wrap {
    width: 70px;
    height: 50px;
    margin-right: 8px;
    display: flex;
    justify-items: center;
    align-items: center;
  }

  &__logo {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &__name {
  }
}

// Custom collab details page =================================================
.custom-collab-details-page {
  width: $desktop-wide-right-col-width;
  margin: 0 auto;

  &__title {
    margin-bottom: 27px;
  }

  &__divider {
    margin-bottom: 20px;
    border-bottom: 1px rgba(0, 0, 0, 0.15) solid;
  }

  &__community-logo-snippet {
    margin-bottom: 20px;
  }

  &__content {
    min-height: 700px;
    gap: $desktop-wide-grid-gap-width;
    padding: 30px 0;
    display: flex;
    position: relative;
    z-index: 0;

    &::before {
      content: "";
      width: calc(100% + 50px);
      height: 100%;
      border: 1px rgba(0, 0, 0, 0.15) solid;
      border-radius: 6px;
      position: absolute;
      inset: 0 auto auto -25px;
      z-index: -1;
    }
  }

  &__content-left-col {
    width: calc($desktop-wide-grid-col-width * 3 + $desktop-wide-grid-gap-width * 2);
    min-width: calc($desktop-wide-grid-col-width * 3 + $desktop-wide-grid-gap-width * 2);
    display: flex;
    align-items: stretch;
  }

  &__content-right-col {
    flex-grow: 1;
    gap: $desktop-wide-grid-gap-width;
    display: flex;
    flex-direction: column;
  }

  &__work-status-snippet {
  }

  &__collab-details-snippet {
  }

  &__how-it-work-snippet {
  }

  &__white-snippet-wrap {
    width: 100%;
    padding: 20px 25px 24px;
    box-sizing: border-box;
    border-radius: 6px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0 3px 15px -5px rgba(0, 0, 0, 0.4);
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
  .custom-collab-details-page {
    width: $desktop-right-col-width;
  }
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
}
</style>
