// Types
import { CustomCollabStatuses } from "@contracts/customCollabs";

export function getStatusText(status: CustomCollabStatuses | string): string {
  return {
    [CustomCollabStatuses.InProgress]: "In Flight",
    [CustomCollabStatuses.Complete]: "Complete",
    [CustomCollabStatuses.Planning]: "Planning",
  }[status];
}
