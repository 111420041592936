export interface PhotosPageData {
  groupName: string;
  photos: Array<string>;
  linkRoute: any | null;
  nonLinkText: string | null;
  source: GroupingType;
  sortValueAsc: string;
  sortValueDesc: string;
  additionalInfo: string;
  locationShortPhotoSummariesList?: Array<LocationShortPhotoSummary> | null;
  adventureShortPhotoSummariesList?: Array<AdventureShortPhotoSummary> | null;
  uploadCampaignShortPhotoSummariesList?: Array<UploadCampaignShortPhotoSummary> | null;
  creatorShortPhotoSummariesList?: Array<CreatorShortPhotoSummary> | null;
}

export type GroupingType = "adventure" | "creator" | "location" | "community-upload" | "my-uploads";

export type SubGroupingType = "byLocation" /* | "byHighlights" */ | "byAdventure" | "byUploadCampaign" | "byCreator";

export interface LocationShortPhotoSummary {
  locationId: string;
  locationName: string;
  photos: Array<string>;
}

export interface AdventureShortPhotoSummary {
  adventureId: string;
  adventureName: string;
  photos: Array<string>;
}

export interface UploadCampaignShortPhotoSummary {
  uploadCampaignId: string;
  uploadCampaignName: string;
  photos: Array<string>;
}

export interface CreatorShortPhotoSummary {
  creatorId: string;
  creatorName: string;
  photos: Array<string>;
  additionalInfo?: string;
}

export interface PhotoSubGroup {
  id: string;
  type: SubGroupingType | null;
  title: string | null;
  photos: Array<string>;
  additionalInfo?: string | null;
}

export const additionalInfoStringDividerSymbol = "-----";
