import { RouteLocationNormalizedLoaded } from "vue-router";
import axios from "axios";

// Define the CreatorInviteProcessResponse type
interface CreatorInviteProcessResponse {
  status: string;
  customerId: string;
  customCollabId: string;
}

export class CreatorInviteKey {
  private static readonly STORAGE_KEY = "inviteKey";

  // --- Currently used for Custom Collabs ---
  // We may add more ways to associate creators to a destination in the future.

  static checkAndSaveInviteKey(route: RouteLocationNormalizedLoaded): void {
    const inviteKey = route.query.inviteKey;
    if (inviteKey && typeof inviteKey === "string") {
      localStorage.setItem(this.STORAGE_KEY, inviteKey);
    }
  }

  static isInviteKeyPresent(): boolean {
    return !!localStorage.getItem(this.STORAGE_KEY);
  }

  static getInviteKey(): string | null {
    return localStorage.getItem(this.STORAGE_KEY);
  }

  static async processInviteKey(creatorId: string): Promise<CreatorInviteProcessResponse | false> {
    const inviteKey = localStorage.getItem(this.STORAGE_KEY);

    if (!inviteKey) {
      return false;
    }

    const response = await axios.put<CreatorInviteProcessResponse>(`${import.meta.env.VITE_API_URL}/creator-invites/${creatorId}/process-invite/${inviteKey}`);
    localStorage.removeItem(this.STORAGE_KEY); // Clear the invite key after successful processing
    if (response.data.status === "notfound") {
      return false;
    } else {
      return response.data;
    }
  }
}
