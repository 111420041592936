<template>
  <div class="all-custom-collabs-page">
    <h1 class="global-h1 all-custom-collabs-page__title">Custom Collabs</h1>

    <div class="all-custom-collabs-page__divider"></div>

    <Loader v-if="isLoading" />

    <template v-else>
      <div class="all-custom-collabs-page__buttons-panel">
        <SrpButton color="orange">
          <template #icon>
            <IconEmbedded name="plus_3-5" :size="22" />
          </template>
          Start a New Collab
        </SrpButton>
        <span style="display: inline-flex">
          <LinkWithIcon color="blue" :to="{ name: 'CustomerSavedCreators', params: { pageId: customerId } }">
            <template #icon><IconEmbedded name="users_2" :size="20" /></template>
            <span>View Saved Creators</span>
          </LinkWithIcon>
        </span>
      </div>

      <!-- Collab snippets section -->
      <div class="collab-snippets-section all-custom-collabs-page__collab-snippets-section">
        <ul class="collab-snippets-section__snippets-list">
          <CollabActionSummaryTile
            class="collab-snippets-section__snippet"
            v-for="collabSummary in notCompletedCollabs"
            :key="collabSummary.customCollabId"
            :customerCollabActionSummary="collabSummary"
            :to="{
              name: 'CustomerCustomCollabDetails',
              params: {
                pageId: collabSummary.customerId,
                customCollabId: collabSummary.customCollabId,
                creatorId: collabSummary.creatorId,
              },
            }"
          />
        </ul>
      </div>
      <!-- / Collab snippets section -->

      <!-- Collab snippets section -->
      <div v-if="completedCollabs.length" class="collab-snippets-section all-custom-collabs-page__collab-snippets-section">
        <h2 class="global-h2 collab-snippets-section__title">Completed</h2>

        <div class="collab-snippets-section__divider"></div>

        <ul class="collab-snippets-section__snippets-list">
          <CollabActionSummaryTile
            class="collab-snippets-section__snippet"
            v-for="collabSummary in completedCollabs"
            :key="collabSummary.customCollabId"
            :customerCollabActionSummary="collabSummary"
            :to="{
              name: 'CustomCollabDetails',
              params: {
                pageId: collabSummary.customerId,
                customCollabId: collabSummary.customCollabId,
                creatorId: collabSummary.creatorId,
              },
            }"
          />
        </ul>
      </div>
      <!-- / Collab snippets section -->
    </template>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from "vue";
import CustomCollabsRepo from "@repos/CustomCollabsRepo";
import { useRouter, useRoute } from "vue-router";

// Components
import LinkWithIcon from "@components/LinkWithIcon.vue";
import IconEmbedded from "@components/ui/IconEmbedded.vue";
import SrpButton from "@components/ui/SrpButton.vue";
import CollabActionSummaryTile from "@components/collabSnippets/CollabActionSummaryTile.vue";

// Types
import { CustomerCollabActionSummary } from "@contracts/collabActionSummaries";
import { CustomCollabStatuses } from "@contracts/customCollabs";
import Loader from "@components/Loader/Loader.vue";

const route = useRoute();

// Load data ==================================================================
const isLoading = ref<boolean>(true);
const allCustomCollabs = ref<Array<CustomerCollabActionSummary>>([]);

let customerId = ref<string>("");
onMounted(async () => {
  isLoading.value = true;
  customerId.value = String(route.params.communityId);
  allCustomCollabs.value = await CustomCollabsRepo.getAllCustomCollabs(customerId.value);
  isLoading.value = false;
});

// Filter collabs list ========================================================
const notCompletedCollabs = computed<Array<CustomerCollabActionSummary>>(() => {
  return allCustomCollabs.value.filter(c => c.status !== CustomCollabStatuses.Complete);
});
const completedCollabs = computed<Array<CustomerCollabActionSummary>>(() => {
  return allCustomCollabs.value.filter(c => c.status === CustomCollabStatuses.Complete);
});
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";

// Collab snippets section ====================================================
.collab-snippets-section {
  &__title {
    margin-bottom: 20px;
  }

  &__divider {
    margin-bottom: 30px;
    border-bottom: 1px rgba(0, 0, 0, 0.15) solid;
  }

  &__snippets-list {
    gap: $desktop-wide-grid-gap-width;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
  }

  &__snippet {
    width: calc(50% - $desktop-wide-grid-gap-width / 2);
  }
}

// All Custom Collabs page ====================================================
.all-custom-collabs-page {
  width: $desktop-wide-right-col-width;
  margin: 0 auto;

  &__title {
    margin-bottom: 16px;
  }

  &__divider {
    width: 100%;
    margin-bottom: 35px;
    border-bottom: 1px rgba(0, 0, 0, 0.15) solid;
  }

  &__buttons-panel {
    gap: 17px 40px;
    margin-bottom: 50px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  &__collab-snippets-section {
    margin-bottom: 60px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
  .all-custom-collabs-page {
    width: $desktop-right-col-width;
  }
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
  .all-custom-collabs-page {
    width: $laptop-right-col-width;
  }
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
  .all-custom-collabs-page {
    width: $tablet-large-right-col-width;
  }
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .all-custom-collabs-page {
    width: $tablet-right-col-width;
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .all-custom-collabs-page {
    width: $mobile-content-width;
  }
}
</style>
