<template>
  <div class="collab-details">
    <ul class="collab-details__snippets-list">
      <li class="snippet collab-details__snippet">
        <IconEmbedded class="snippet__icon" name="calendar_2" :size="25" />
        <div class="snippet__content snippet__content--flex-column">
          <!--Creator was chosen-->
          <template v-if="creatorInput?.acceptedDateTime && allowDateChange">
            <div class="snippet__text-wrap snippet__text-wrap--with-bottom-margin">
              <div class="global-h6 snippet__title">Visit Dates</div>
              <div class="snippet__description">
                <b>{{ visitDateString }}</b>
                <div v-if="dateChangeProposalString" class="ui yellow message" style="padding: 10px">
                  <b style="margin-right: 8px">{{ dateChangeProposalString }} is proposed by {{ dateChangeProposedBy }}</b>
                  <button v-if="dateChangeNeedsActionByCurrentUser" class="ui small primary button" style="margin-top: 8px" @click="reviewDateChangeProposal">Review Proposed Change</button>
                </div>
              </div>
            </div>

            <SrpButton
              v-if="collabInput.collaboration.completedDate === null && !(dateChangeProposalString && dateChangeNeedsActionByCurrentUser)"
              class="snippet__button"
              @click="showProposeNewDatesModal = true"
              size="tiny"
              color="gray"
              fill="outlined"
            >
              Change Dates
            </SrpButton>
          </template>

          <!--Creator hasn't been chosen yet-->
          <div v-else class="snippet__text-wrap">
            <div class="global-h6 snippet__title">Preferred Visit Window</div>
            <div class="snippet__description">
              <span>{{ formatPreferredTimingDates }} </span><br />
              <span v-if="collabInput.preferredTiming?.length > 0">
                {{ collabInput.preferredTiming }}
              </span>
            </div>
          </div>
        </div>
      </li>

      <li class="snippet collab-details__snippet">
        <IconEmbedded v-if="!collabInput.themeType" class="snippet__icon" name="bulb_2" :size="25" />
        <img v-else class="snippet__icon" :src="getThemeIcon(collabInput.themeType)" :alt="collabInput.themeType" />
        <div class="snippet__content">
          <div class="snippet__text-wrap">
            <div class="global-h6 snippet__title">
              {{ `Visit Focus: ${getThemeTypeName(collabInput.themeType)}` }}
            </div>
            <div class="snippet__description">
              <span v-if="collabInput.themes" v-html="linkifyStr(collabInput.themes, globalRoot.linkifyOptions)"></span>
            </div>
            <div v-if="collabInput.requestedLocationCount && collabInput.requestedAdventureCount" class="snippet__description">
              <div>
                <strong>{{ collabInput.requestedLocationCount }}</strong> locations
              </div>
              <div>
                <strong>{{ collabInput.requestedAdventureCount }}</strong> adventures
              </div>
            </div>
          </div>
        </div>
      </li>

      <li v-if="collabInput.contentFocus" class="snippet collab-details__snippet">
        <IconEmbedded class="snippet__icon" name="target_2" :size="25" />
        <div class="snippet__content">
          <div class="snippet__text-wrap">
            <div class="global-h6 snippet__title">Content Focus</div>
            <div v-if="collabInput.contentFocus" class="snippet__description" v-html="linkifyStr(collabInput.contentFocus, globalRoot.linkifyOptions)"></div>
          </div>
        </div>
      </li>

      <li class="snippet collab-details__snippet">
        <IconEmbedded class="snippet__icon" name="zap-solid" :size="26" />
        <div class="snippet__content">
          <div class="snippet__text-wrap">
            <div class="global-h6 snippet__title">Required Locations</div>
            <div v-if="collabInput.requiredAttractions" class="snippet__description" v-html="linkifyStr(fallbackTextIfEmpty(collabInput.requiredAttractions), globalRoot.linkifyOptions)"></div>
            <div v-else class="snippet__description">none</div>
          </div>
        </div>
      </li>

      <li class="snippet collab-details__snippet">
        <IconEmbedded class="snippet__icon" name="zap_2" :size="26" />
        <div class="snippet__content">
          <div class="snippet__text-wrap">
            <div class="global-h6 snippet__title">Suggested Locations</div>
            <div v-if="collabInput.suggestedAttractions" class="snippet__description" v-html="linkifyStr(fallbackTextIfEmpty(collabInput.suggestedAttractions), globalRoot.linkifyOptions)"></div>
          </div>
        </div>
      </li>

      <li class="snippet collab-details__snippet">
        <IconEmbedded class="snippet__icon" name="discount_2-5" :size="24" />
        <div class="snippet__content">
          <div class="snippet__text-wrap">
            <div class="global-h6 snippet__title">Comped Activities <br /><span class="snippet__thin-text">(coordinated by destination)</span></div>
            <div class="snippet__description">
              <div>
                <span v-if="collabInput.compedActivitiesTags?.length === 0">none</span>
                <template v-else v-for="tag in collabCompTags">
                  <button
                    v-if="collabInput.compedActivitiesTags.includes(tag.key)"
                    :key="tag.key"
                    class="ui disabled mini basic primary button comp-tag"
                    style="height: 23px; display: inline-flex; align-items: center; opacity: 1 !important"
                  >
                    <IconEmbedded :name="tag.icon" :size="17" color="rgba(5, 133, 135, 1)" />
                    {{ tag.text }}
                  </button>
                </template>
              </div>
              <span
                v-if="collabInput.bigTicketItemBookinNotes"
                v-html="linkifyStr(fallbackTextIfEmpty(collabInput.bigTicketItemBookinNotes, collabInput.compedActivitiesTags.length === 0 ? 'none' : ''), globalRoot.linkifyOptions)"
              ></span>
              <div v-if="collabInput.compedActivitiesEstimatedValue"><b style="font-weight: 600; color: #444">Estimated Value:</b> {{ "$" + collabInput.compedActivitiesEstimatedValue }}</div>
            </div>
          </div>
        </div>
      </li>

      <li v-if="collabInput.gearToBring" class="snippet collab-details__snippet">
        <IconEmbedded class="snippet__icon" name="binoculars_2" :size="26" />
        <div class="snippet__content">
          <div class="snippet__text-wrap">
            <div class="global-h6 snippet__title">Gear Needed</div>
            <div v-if="collabInput.gearToBring" class="snippet__description" v-html="linkifyStr(collabInput.gearToBring, globalRoot.linkifyOptions)"></div>
          </div>
        </div>
      </li>

      <li class="snippet collab-details__snippet">
        <IconEmbedded class="snippet__icon" name="home_2" :size="26" />
        <div class="snippet__content">
          <div class="snippet__text-wrap">
            <div class="global-h6 snippet__title">Lodging</div>
            <div class="snippet__description">
              <div v-if="lodgingSummaryText" v-html="linkifyStr(lodgingSummaryText, globalRoot.linkifyOptions)"></div>
              <template v-if="collabInput.hotelEstimatedValue"> <b style="font-weight: 600; color: #444">Estimated Value:</b> {{ "$" + collabInput.hotelEstimatedValue }}<br /> </template>
              <template v-if="collabInput.hotelName">
                <b style="font-weight: 600; color: #444">Lodging Location: </b>
                <span v-if="collabInput.hotelName" v-html="linkifyStr(collabInput.hotelName, globalRoot.linkifyOptions)"></span>
              </template>
            </div>
          </div>
        </div>
      </li>

      <li v-if="collabInput.areaBoundaries" class="snippet collab-details__snippet">
        <IconEmbedded class="snippet__icon" name="area-boundaries_2" :size="24" />
        <div class="snippet__content">
          <div class="snippet__text-wrap">
            <div class="global-h6 snippet__title">Area Boundaries</div>
            <div v-if="collabInput.areaBoundaries" class="snippet__description" v-html="linkifyStr(collabInput.areaBoundaries, globalRoot.linkifyOptions)"></div>
          </div>
        </div>
      </li>

      <li v-if="collabInput.excludedAttractions" class="snippet collab-details__snippet">
        <IconEmbedded class="snippet__icon" name="map-pin-remove_2" :size="27" />
        <div class="snippet__content">
          <div class="snippet__text-wrap">
            <div class="global-h6 snippet__title">Locations to Exclude</div>
            <div v-if="collabInput.excludedAttractions" class="snippet__description" v-html="linkifyStr(collabInput.excludedAttractions, globalRoot.linkifyOptions)"></div>
          </div>
        </div>
      </li>

      <li v-if="collabInput.referenceMaterial" class="snippet collab-details__snippet">
        <IconEmbedded class="snippet__icon" name="external-link_2" :size="25" />
        <div class="snippet__content">
          <div class="snippet__text-wrap">
            <div class="global-h6 snippet__title">Reference Material</div>
            <div v-if="collabInput.referenceMaterial" class="snippet__description" v-html="linkifyStr(collabInput.referenceMaterial, globalRoot.linkifyOptions)"></div>
          </div>
        </div>
      </li>

      <li v-if="!isCreatorFlow && (collabInput.destinationPhoneNumber || collabInput.destinationContactName)" class="snippet collab-details__snippet">
        <IconEmbedded class="snippet__icon" name="phone_2" :size="25" />
        <div class="snippet__content">
          <div class="snippet__text-wrap">
            <div class="global-h6 snippet__title">Contact</div>
            <div class="snippet__description">
              {{ collabInput.destinationContactName }}
              <div v-if="collabInput.destinationPhoneNumber">
                <MaskedPhoneNumber :phoneNumber="collabInput.destinationPhoneNumber" />
              </div>
            </div>
          </div>
        </div>
      </li>

      <li v-if="collabInput.notesForCreator" class="snippet collab-details__snippet">
        <IconEmbedded class="snippet__icon" name="notepad_1-5" :size="28" />
        <div class="snippet__content">
          <div class="snippet__text-wrap">
            <div class="global-h6 snippet__title">Note from Destination</div>
            <div v-if="collabInput.notesForCreator" class="snippet__description" v-html="linkifyStr(collabInput.notesForCreator, globalRoot.linkifyOptions)"></div>
          </div>
        </div>
      </li>
    </ul>

    <CollabDateChangeProposal
      v-if="collabInput"
      v-model:visible="showProposeNewDatesModal"
      :collabInput="collabInput"
      :isCreatorFlow="isCreatorFlow"
      :originalPreferredTiming="formatPreferredTimingDates"
      :currentVisitDateString="visitDateString"
      :dateChangeProposalString="dateChangeProposalString"
      @close="showProposeNewDatesModal = false"
    />

    <CollabDateChangeResponse
      v-if="collabInput"
      v-model:visible="showConfirmCollabDateChangeModal"
      :collabInput="collabInput"
      :creatorInput="creatorInput"
      :isCreatorFlow="isCreatorFlow"
      :currentVisitDateString="visitDateString"
      :dateChangeProposalString="dateChangeProposalString"
      @close="showConfirmCollabDateChangeModal = false"
    />
  </div>
</template>

<script lang="ts">
import DateUtils from "@logic/DateUtils";
import { defineComponent } from "vue";
import linkifyStr from "linkify-string";

// Types
import CollabConsts, { ThemeType } from "@contracts/collab";
import { CollabInput, CollabCreatorInput } from "@contracts/collab";
import CollabValueCalculator from "@logic/CollabValueCalculator";

// Components
import CollabDateChangeProposal from "@components/Modals/CollabDateChangeProposal.vue";
import CollabDateChangeResponse from "@components/Modals/CollabDateChangeResponse.vue";
import SrpButton from "@components/ui/SrpButton.vue";
import MaskedPhoneNumber from "@components/MaskedPhoneNumber.vue";

import { getThemeTypeName, getThemeIcon } from "@helpers/ThemeTypeHelper";
import IconEmbedded from "@components/ui/IconEmbedded.vue";

export default defineComponent({
  name: "CollabInputsSummary",

  components: {
    IconEmbedded,
    CollabDateChangeProposal,
    CollabDateChangeResponse,
    SrpButton,
    MaskedPhoneNumber,
  },

  props: {
    allowDateChange: { type: Boolean, default: true },

    // Optionally set (depending on the flow) for the date change display
    communityName: { type: String, required: false, default: null },
    collabInput: { type: Object as () => CollabInput, required: true },
    // passed if the creator has been chosen
    creatorInput: { type: Object as () => CollabCreatorInput | null, required: false, default: null },
    creatorName: { type: String, required: false, default: null },
    isCreatorFlow: { type: Boolean, required: true },
  },

  data() {
    return {
      // NOTE: This is in a bunch of other places also (if changing, do a search both client and server)
      hotelCoverageFromShrpa: CollabValueCalculator.HotelCoverageFromShrpa,
      showProposeNewDatesModal: false,
      showConfirmCollabDateChangeModal: false,
      collabCompTags: CollabConsts.CollabCompTags,
    };
  },

  computed: {
    creatorIsChosen(): boolean {
      if (this.collabInput?.collaboration?.creatorId) return true;
      else return false;
    },
    formatPreferredTimingDates(): string {
      if (this.collabInput && this.collabInput.preferredDateRangeStart) return DateUtils.formatDateRangeTwoDates(this.collabInput.preferredDateRangeStart, this.collabInput.preferredDateRangeEnd);
      else return "";
    },
    visitDateString(): string {
      if (this.creatorInput) return DateUtils.formatDateRange(this.creatorInput.proposedVisitDateTime, this.creatorInput.numberOfDaysStaying);
      else return "";
    },
    dateChangeProposalString(): string {
      // Only returns if it hasn't been accepted/declined
      if (this.collabInput?.proposedDateChange?.preferredDateRangeStart && this.collabInput.proposedDateChange.latestAcceptDeclineDateTime === null)
        return DateUtils.formatDateRangeTwoDates(this.collabInput.proposedDateChange.preferredDateRangeStart, this.collabInput.proposedDateChange.preferredDateRangeEnd, false);
      else return null;
    },
    dateChangeNeedsActionByCurrentUser(): boolean {
      if (this.collabInput?.proposedDateChange?.preferredDateRangeStart && this.collabInput.proposedDateChange.latestAcceptDeclineDateTime === null) {
        if (this.isCreatorFlow && this.collabInput?.proposedDateChange?.wasProposedByTheCreator === false) return true;
        else if (!this.isCreatorFlow && this.collabInput?.proposedDateChange?.wasProposedByTheCreator === true) return true;
      }

      return false;
    },
    dateChangeProposedBy(): string {
      if (this.collabInput?.proposedDateChange?.wasProposedByTheCreator === true) {
        if (this.isCreatorFlow) return "you";
        else return this.creatorName;
      } else {
        if (this.isCreatorFlow) return this.communityName;
        else return "you";
      }
    },
    lodgingSummaryText(): string {
      if (this.collabInput.hotelNightsCovered > 1) return "Destination will arrange up to 2 nights";
      else if (this.collabInput.hotelNightsCovered === 1) return "Destination will arrange 1 night";
      else {
        if (this.collabInput.hotelCoverageUsed === "Grandfathered") return `Destination can't arrange lodging. Shrpa will provide $${this.hotelCoverageFromShrpa} for you to arrange your own lodging.`;
        else return `Shrpa will provide $${this.hotelCoverageFromShrpa} for you to arrange your own lodging.`;
      }
    },
  },

  methods: {
    getThemeTypeName,
    getThemeIcon,
    linkifyStr,
    // Falls back to standard text if the field is null or empty
    fallbackTextIfEmpty(input: string, emptyText = "none"): string {
      if (input?.length > 0) return input;
      else return emptyText;
    },
    reviewDateChangeProposal() {
      this.showConfirmCollabDateChangeModal = true;
    },
  },
});
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";

// Snippet ====================================================================
.snippet {
  padding-bottom: 9px;
  border-bottom: 1px rgba(0, 0, 0, 0.1) solid;
  display: flex;
  justify-content: space-between;

  &:last-child {
    padding-bottom: 0;
    border-bottom: 0;
  }

  &__thin-text {
    font-weight: 400;
    white-space: nowrap;
  }

  &__icon {
    width: 27px;
    min-width: 27px;
    height: 27px;
    margin-right: 7px;
    display: flex;
    justify-content: center;
    align-items: center;

    :deep(svg) {
      fill: rgba(0, 0, 0, 0.3);
    }
  }

  &__content {
    flex-grow: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &--flex-column {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__text-wrap {
    flex-grow: 1;
    margin-right: 25px;
    display: flex;
    flex-direction: column;

    &:last-child {
      margin-right: 0;
    }

    &--with-bottom-margin {
      margin-bottom: 10px;
    }
  }

  &__title {
    margin-bottom: 1px;
    font-weight: 600;
  }

  &__description {
    width: 100%;
    color: #5b5b5b;
    font: 14px/18px sans-serif;
    white-space: pre-wrap;
    word-break: break-word;
    margin-bottom: 6px;
    &:last-child {
      margin-bottom: 0;
    }

    :deep(a) {
      color: #2165a6;
      text-decoration: underline;
    }
    :deep(a:hover) {
      text-decoration: none;
    }
  }

  &__theme-type-icon {
    width: 17px;
    height: 17px;
    margin-right: 3px;
    display: inline-flex;
    float: left;
    transform: scale(1.2);
  }

  &__button {
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
  .snippet {
    &__content {
      flex-direction: column;
      align-items: flex-start;
    }

    &__text-wrap {
      margin-bottom: 8px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .snippet {
    &__content {
      flex-direction: column;
      align-items: flex-start;
    }

    &__text-wrap {
      margin-bottom: 8px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

// Collab details =============================================================
.collab-details {
  &__snippets-list {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  &__snippet {
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
