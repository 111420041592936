<template>
  <div class="edit-profile-page" v-if="userProfileStore.getViewingUserProfile">
    <!--Superuser section-->
    <SrpDetailsSummary v-if="userProfileStore.isSuperOrSalesUser" style="margin-bottom: 30px; background-color: #2c058730" class="ui segment edit-profile-page__admin-panel">
      <template #heading="{ isOpened }">
        <div class="global-h5" style="width: calc(100% - 30px); display: inline-flex; justify-content: space-between; align-items: center; cursor: pointer; user-select: none">
          <h5 v-if="userProfileStore.isImpersonating" class="global-h5" style="margin: 0 15px 0 0; display: inline-block; color: maroon">
            Impersonating {{ userProfileStore.getViewingUserProfile.firstName }}! (logged in as {{ userProfileStore.getActingUserProfile.firstName }})
          </h5>
          <h5 v-else class="global-h5" style="margin: 0 15px 0 0; display: inline-block">Reminder: You're logged in as an Administrator</h5>

          <LinkWithIcon v-if="isOpened" tag="RouterLink" :to="{ name: 'EditCreator', params: { id: `${userProfileStore.getViewingUserProfile.sherpaId}` } }" target="_blank">
            <span style="margin-right: 4px">Backend</span>

            <IconEmbedded name="external-link_2-5" color="rgba(0, 0, 0, 0.3)" :size="15" />
          </LinkWithIcon>
        </div>
      </template>
      <template #details>
        <div style="margin: 12px 0 -15px; display: flex; align-items: flex-start; flex-wrap: wrap">
          <div style="margin: 0 30px 15px 0">
            <div class="ui checkbox">
              <input type="checkbox" id="isCollabEligible" v-model="userProfileStore.getViewingUserProfile.collabPreferences.isCollabEligible" />
              <label for="isCollabEligible" style="display: inline; margin-right: 15px !important; cursor: pointer; user-select: none">This is a Paid Creator</label>
            </div>
            <br />
            <div class="ui checkbox" style="margin-top: 10px">
              <input type="checkbox" v-model="userProfileStore.getViewingUserProfile.collabPreferences.becomingCollabEligible" id="becomingCollabEligible" />
              <label for="becomingCollabEligible" style="cursor: pointer; user-select: none"> Working towards being a Paid Creator </label>
            </div>

            <div style="margin-top: 10px">
              <span style="margin-right: 10px">{{ disabledState }}</span>
              <SrpButton
                v-if="!userProfileStore.getViewingUserProfile.collabPreferences.collabEligibleDisabled && !userProfileStore.getViewingUserProfile.collabPreferences.collabHideApplications"
                :isDisabled="isDisableSaving"
                @click="showDisableCollabModal = true"
                fill="outlined"
                size="tiny"
                color="black"
              >
                Disable Creator
              </SrpButton>
              <SrpButton v-else :isDisabled="isDisableSaving" @click="creatorCollabEnable()" fill="outlined" size="tiny" color="black"> Enable Creator </SrpButton>
            </div>
          </div>

          <div class="edit-profile-page__superuser-data-snippet">
            <div>
              Created: {{ moment(userProfileStore.getViewingUserProfile.createdOn).fromNow() }}<br />
              Last Login: {{ moment(userProfileStore.getViewingUserProfile.lastLogin).fromNow() }}
            </div>
            <!--<span>isVerified={{ userProfileStore.getViewingUserProfile.emailIsVerified }}, authEmail={{ userProfileStore.getViewingUserProfile.authEmail }} </span>-->
          </div>
        </div>
      </template>
    </SrpDetailsSummary>

    <div>
      <div class="edit-profile-page__main-profile-section">
        <div class="edit-profile-page__column-wrapper">
          <div class="edit-profile-page__left-column">
            <div class="field">
              <label class="cms-section-title no-top-margin">
                Email
                <div v-if="userProfileStore.isSuperOrSalesUser && !editEmail" style="display: inline; margin-left: 5px; margin-bottom: 5px">
                  <button class="ui button mini" @click="editEmail = true" style="padding: 6px; background-color: #2c058730">edit</button>
                </div>
              </label>
              <div v-if="userProfileStore.isSuperOrSalesUser && editEmail">
                <input class="global-text-input" type="text" v-model="userProfileStore.getViewingUserProfile.email" style="margin-bottom: 8px" />
                <SrpButton @click="updateEmailBySuper" size="small" fill="outlined" color="gray" style="background-color: #2c058730; margin-bottom: 8px">Update email (superuser only)</SrpButton>
              </div>
              <div v-else>
                <b>{{ userProfileStore.getViewingUserProfile.email }}</b>
              </div>
            </div>

            <div class="field" v-if="isPaidInterestedOrEligible">
              <label class="cms-section-title">Phone Number</label>
              <p class="cms">
                For last-minute creator visit coordination.
                <template v-if="userProfileStore.getViewingUserProfile.phoneNumberIsVerified"
                  ><br /><strong><MaskedPhoneNumber :phone-number="userProfileStore.getViewingUserProfile.phoneNumber" /></strong
                ></template>
              </p>
              <SrpButton size="tiny" :fill="userProfileStore.getViewingUserProfile.phoneNumberIsVerified ? 'text' : 'outlined'" @click="showPhoneNumberVerificationModal = true">{{
                userProfileStore.getViewingUserProfile.phoneNumberIsVerified ? "Change phone number" : "Provide your phone number"
              }}</SrpButton>
            </div>
          </div>

          <div class="edit-profile-page__right-column">
            <div class="fields" style="margin-bottom: 25px">
              <div class="eight wide field">
                <label class="cms-section-title no-top-margin">Photo Credit</label>
                <input class="global-text-input" type="text" v-model="userProfileStore.getViewingUserProfile.photoCredit" placeholder="(optional)" style="max-width: 300px" />
              </div>
            </div>

            <div class="fields" v-if="isPaidInterestedOrEligible">
              <div class="eight wide field">
                <label class="cms-section-title" style="margin-top: 15px !important">Full Name</label>
                <input
                  class="global-text-input"
                  type="text"
                  v-model="userProfileStore.getViewingUserProfile.collabPreferences.fullName"
                  placeholder="For lodging reservations and payment"
                  style="max-width: 300px"
                />
              </div>
            </div>
          </div>
        </div>

        <!-- Interested in being paid -->
        <div class="interested-in-being-paid edit-profile-page__interested-in-being-paid" v-if="canShowPaidCreatorFields && !isPaidInterestedOrEligible">
          <div class="interested-in-being-paid__header">
            <SrpCheckbox class="interested-in-being-paid__checkbox" :isWithHalo="false">
              <template #input>
                <input type="checkbox" v-model="userProfileStore.getViewingUserProfile.collabPreferences.becomingCollabEligible" @change="handleCollabEligibilityChange" />
              </template>
              <template #text>
                <span class="global-h5 interested-in-being-paid__checkbox-label">I'm interested in being a paid creator</span>
              </template>
            </SrpCheckbox>

            <LinkWithIcon class="interested-in-being-paid__info-link" isDottedUnderline color="green" @click="isCollabEligibilityTermsVisible = !isCollabEligibilityTermsVisible">
              <template #icon><IconEmbedded name="info_2" :size="18" /></template>
              <span>Collab Eligibility terms</span>
            </LinkWithIcon>
          </div>

          <PaidCreatorFieldsEnteredMessage v-if="isCollabEligibilityTermsVisible" class="interested-in-being-paid__eligibility-terms" />
        </div>

        <!-- Paid Creator Fields -->
        <div v-if="isPaidInterestedOrEligible">
          <div class="divider-line"></div>
          <SrpDetailsSummary :isOpened="isTravelBoundaryOpen">
            <template #heading>
              <h2>My Travel Boundary</h2>
            </template>
            <template #details>
              <PaidCreatorFields :currentProfile="userProfileStore.getViewingUserProfile" style="margin-top: -10px" />
            </template>
          </SrpDetailsSummary>
        </div>
      </div>

      <!-- Buttons sticky panel -->
      <div class="buttons-sticky-panel edit-profile-page__buttons-sticky-panel">
        <!-- Errors list (not needed anymore) -->
        <p v-if="saveStatus != 'none'" class="buttons-sticky-panel__save-status">
          <b>{{ saveStatus == "saved" ? "Saved" : "Saving..." }}</b>
        </p>
        <div class="buttons-sticky-panel__buttons-line">
          <SrpButton :is-disabled="saveStatus == 'saving'" @click="saveUserProfile()" isBiggerSidePaddings style="margin-right: 11px">Save</SrpButton>
          <SrpButton @click="cancelEdit" color="gray">Cancel</SrpButton>
          <div style="flex-grow: 1"></div>
          <SrpButton @click="logout" color="gray" fill="outlined">Log out</SrpButton>
        </div>
      </div>
      <!-- / Buttons sticky panel -->
    </div>

    <div v-if="userProfileStore.getViewingUserProfile?.disabledDate" style="margin-top: 15px">
      <ProfileDisabledWarning :showReactivateButton="true" />
    </div>

    <!-- Notifications Section -->
    <SrpDetailsSummary :isOpened="true" class="edit-profile-page__notifications-section">
      <template #heading>
        <h1>Your Notifications</h1>
      </template>
      <template #details>
        <CreatorNotifications />
      </template>
    </SrpDetailsSummary>

    <!-- Modals -->
    <SrpModal v-model:isVisible="showPhoneNumberVerificationModal" :zIndex="400" size="small">
      <template #content>
        <PhoneNumberVerificationForm @verificationCanceled="showPhoneNumberVerificationModal = false" @phoneVerified="showPhoneNumberVerificationModal = false" />
      </template>
    </SrpModal>

    <SrpModal v-model:isVisible="showDisableCollabModal" :zIndex="400" size="medium">
      <template #header>
        <h2 class="global-h2">Disable this Creator?</h2>
      </template>
      <template #content>
        <div style="margin: 10px 20px">
          <div class="ui checkbox" style="margin-top: 10px">
            <input type="checkbox" v-model="disabledCanApplyToCollabs" id="canApplyToCollabs" />
            <label for="canApplyToCollabs" style="cursor: pointer; user-select: none">Let Creator Apply to Collabs</label>
          </div>
        </div>
      </template>
      <template #footer>
        <SrpButton @click="showDisableCollabModal = false" color="gray" fill="outlined" style="margin-right: 10px">Cancel</SrpButton>
        <SrpButton @click="creatorCollabDisable()" :isDisabled="isDisableSaving" color="orange">{{ isDisableSaving ? "Disabling..." : "Disable" }} Creator</SrpButton>
      </template>
    </SrpModal>
  </div>
</template>

<script setup lang="ts">
import { useUserProfileStore } from "@stores/userProfileStore";
import { ref, computed, inject, onMounted } from "vue";
import CreatorNotifications from "./CreatorNotifications.vue";
import PaidCreatorFields from "@components/PaidCreatorFields.vue";
import PaidCreatorFieldsEnteredMessage from "@components/PaidCreatorFieldsEnteredMessage.vue";
import moment from "moment";
import SrpDetailsSummary from "@components/ui/SrpDetailsSummary.vue";
import SrpButton from "@components/ui/SrpButton.vue";
import SrpCheckbox from "@components/ui/SrpCheckbox.vue";
import LinkWithIcon from "@components/LinkWithIcon.vue";
import MaskedPhoneNumber from "@components/MaskedPhoneNumber.vue";
import PhoneNumberVerificationForm from "@components/PhoneNumberVerificationForm.vue";
import axios from "axios";
import { useRouter, useRoute } from "vue-router";
import { getInstance } from "@auth/authWrapper";
import IconEmbedded from "@components/ui/IconEmbedded.vue";
import SrpModal from "@components/ui/SrpModal.vue";
import ProfileDisabledWarning from "@components/Sherpa/ProfileDisabledWarning.vue";

const router = useRouter();
const route = useRoute();
const userProfileStore = useUserProfileStore();
const globalRemoteLogger = inject("globalRemoteLogger") as any;

onMounted(async () => {
  if (route.params.creatorId) await userProfileStore.impersonateProfile(route.params.creatorId as string);
});

// Profile Fields ============================================================
const editEmail = ref(false);
const showPhoneNumberVerificationModal = ref(false);
// none means there is no save operation in progress
const saveStatus = ref<"none" | "saving" | "saved">("none");

const isPaidInterestedOrEligible = computed(() => {
  if (userProfileStore.getViewingUserProfile.collabPreferences.collabEligibleDisabled === true) {
    return false;
  }
  return userProfileStore.getViewingUserProfile.collabPreferences.isCollabEligible || userProfileStore.getViewingUserProfile.collabPreferences.becomingCollabEligible;
});

// Disable Collab Modal =======================================================
const disabledState = computed(() => {
  if (userProfileStore.getViewingUserProfile.collabPreferences.collabHideApplications) {
    return "Creator Applications HIDDEN";
  }
  if (userProfileStore.getViewingUserProfile.collabPreferences.collabEligibleDisabled) {
    return "Creator is DISABLED from applying";
  }
  return "Creator is active";
});

const disabledCanApplyToCollabs = ref(false);
const showDisableCollabModal = ref(false);
const isDisableSaving = ref(false);
async function creatorCollabDisable() {
  isDisableSaving.value = true;

  const uri = `${import.meta.env.VITE_API_URL}/profile/${userProfileStore.getViewingUserProfile.sherpaId}/collab-disable?allowCreatorToApply=${disabledCanApplyToCollabs.value}`;
  await axios.put(uri);
  globalRemoteLogger.info(`CreatorEligibilityDisabled: ${userProfileStore.getViewingUserProfile.sherpaId}, allowCreatorToApply=${disabledCanApplyToCollabs.value}`);

  // Refresh the page to get the latest state
  window.location.reload();
}
async function creatorCollabEnable() {
  isDisableSaving.value = true;

  const uri = `${import.meta.env.VITE_API_URL}/profile/${userProfileStore.getViewingUserProfile.sherpaId}/collab-enable`;
  await axios.put(uri);
  globalRemoteLogger.info(`CreatorEligibilityEnabled: ${userProfileStore.getViewingUserProfile.sherpaId}`);

  // Refresh the page to get the latest state
  window.location.reload();
}

async function saveUserProfile(): Promise<boolean> {
  const validationPassed = true;
  globalRemoteLogger.info(`Profile.saveUserProfile: ${userProfileStore.getViewingUserProfile?.authNameId}, isValid=${validationPassed}`);
  if (validationPassed) {
    saveStatus.value = "saving";
    await userProfileStore.saveProfile(userProfileStore.getViewingUserProfile);
    saveStatus.value = "saved";
    setTimeout(() => {
      saveStatus.value = "none";
    }, 4000);
  }
  return validationPassed;
}

async function updateEmailBySuper() {
  var uri = `${import.meta.env.VITE_API_URL}/profile/${userProfileStore.getViewingUserProfile.authNameId}/email?newEmail=${encodeURIComponent(userProfileStore.getViewingUserProfile.email)}`;
  var updated = await axios.put(uri);
  globalRemoteLogger.info(`updateEmailBySuper: ${userProfileStore.getViewingUserProfile.authNameId} ${userProfileStore.getViewingUserProfile.email}, wasUpdated ${updated.data}`);
  alert("Email updated! Please refresh the page.");
}

function logout() {
  getInstance().logout();
}

function cancelEdit() {
  router.push({ name: "CreatorDashboard", params: { creatorId: userProfileStore.getViewingUserProfile.sherpaId } });
}

// Paid Creator Fields =======================================================
const isTravelBoundaryOpen = ref(false);
const isCollabEligibilityTermsVisible = ref(false);

const canShowPaidCreatorFields = computed(() => {
  if (userProfileStore.getViewingUserProfile.collabPreferences?.collabEligibleDisabled === true) {
    return false;
  }
  if (userProfileStore.getViewingUserProfile.adminOf.length > 0 || userProfileStore.getViewingUserProfile.isFormerAdmin) {
    return false;
  }
  return true;
});

function handleCollabEligibilityChange() {
  if (userProfileStore.getViewingUserProfile.collabPreferences.becomingCollabEligible) {
    isTravelBoundaryOpen.value = true;
  }
}
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";

input:out-of-range {
  border: 1px solid red !important;
}

.divider-line {
  width: 100%;
  height: 0;
  margin-top: 20px;
  margin-bottom: 20px;
  border-bottom: 1px rgba(0, 0, 0, 0.15) solid;
  font-size: 0;
  line-height: 0;
}

// Interested in being paid ===================================================
.interested-in-being-paid {
  padding: 11px 17px;
  border: 1px #ccd3d7 solid;
  border-radius: 6px;
  //background: #d5ecce;

  &__header {
    width: 100%;
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__checkbox {
    margin-right: 6px;
  }

  &__checkbox-label {
    color: #22341d;
  }

  &__info-link {
  }

  &__eligibility-terms {
    padding-top: 15px;
    position: relative;
    z-index: 0;

    &::before {
      content: "";
      width: 100%;
      height: 17px;
      position: absolute;
      inset: -12px auto auto 0;
      z-index: -1;
      clip-path: polygon(0 55%, 86.5% 55%, 88% 0, 89.5% 55%, 100% 55%, 100% 100%, 0% 100%);
      background: rgba(0, 0, 0, 0.15);
      pointer-events: none;
    }

    &::after {
      content: "";
      width: 100%;
      height: 17px;
      position: absolute;
      inset: -11px auto auto 0;
      z-index: -1;
      clip-path: polygon(0 55%, 86.6% 55%, 88% 0.2%, 89.4% 55%, 100% 55%, 100% 100%, 0% 100%);
      background: white;
      pointer-events: none;
    }
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
  .interested-in-being-paid {
    padding-bottom: 17px;

    &__header {
      flex-direction: column;
      align-items: flex-start;
    }

    &__checkbox {
      margin-right: 0;
    }

    &__info-link {
      margin-left: -3px;
    }

    &__eligibility-terms {
      &::before {
        height: 14px;
        top: -8px;
        clip-path: polygon(0 55%, 17.5% 55%, 19% 0, 20.5% 55%, 100% 55%, 100% 100%, 0% 100%);
      }

      &::after {
        height: 14px;
        top: -7px;
        clip-path: polygon(0 55%, 17.8% 55%, 19% 0.4%, 20.2% 55%, 100% 55%, 100% 100%, 0% 100%);
      }
    }
  }
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
  .interested-in-being-paid {
    padding-bottom: 17px;

    &__header {
      flex-direction: column;
      align-items: flex-start;
    }

    &__checkbox {
      margin-right: 0;
    }

    &__info-link {
      margin-left: -3px;
    }

    &__eligibility-terms {
      &::before {
        height: 14px;
        top: -8px;
        clip-path: polygon(0 55%, 17.5% 55%, 19% 0, 20.5% 55%, 100% 55%, 100% 100%, 0% 100%);
      }

      &::after {
        height: 14px;
        top: -7px;
        clip-path: polygon(0 55%, 17.8% 55%, 19% 0.4%, 20.2% 55%, 100% 55%, 100% 100%, 0% 100%);
      }
    }
  }
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .interested-in-being-paid {
    padding-bottom: 17px;

    &__header {
      flex-direction: column;
      align-items: flex-start;
    }

    &__checkbox {
      margin-right: 0;
      margin-bottom: 10px !important;
    }

    &__info-link {
      margin-left: -3px;
    }

    &__eligibility-terms {
      &::before {
        height: 14px;
        top: -8px;
        clip-path: polygon(0 55%, 17.5% 55%, 19% 0, 20.5% 55%, 100% 55%, 100% 100%, 0% 100%);
      }

      &::after {
        height: 14px;
        top: -7px;
        clip-path: polygon(0 55%, 17.8% 55%, 19% 0.4%, 20.2% 55%, 100% 55%, 100% 100%, 0% 100%);
      }
    }
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .interested-in-being-paid {
    padding-bottom: 17px;

    &__header {
      flex-direction: column;
      align-items: flex-start;
    }

    &__checkbox {
      margin-right: 0;
      margin-bottom: 10px !important;
    }

    &__info-link {
      margin-left: -3px;
    }

    &__eligibility-terms {
      &::before {
        height: 14px;
        top: -8px;
        clip-path: polygon(0 55%, 17.5% 55%, 19% 0, 20.5% 55%, 100% 55%, 100% 100%, 0% 100%);
      }

      &::after {
        height: 14px;
        top: -7px;
        clip-path: polygon(0 55%, 17.8% 55%, 19% 0.4%, 20.2% 55%, 100% 55%, 100% 100%, 0% 100%);
      }
    }
  }
}
// Buttons sticky panel =======================================================
.buttons-sticky-panel {
  width: 100%;
  padding-bottom: 17px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  z-index: 0;

  &::before {
    content: "";
    width: calc(100% + 40px);
    height: calc(100% + 36px);
    position: absolute;
    inset: auto auto 0 -20px;
    z-index: -1;
    background: linear-gradient(0deg, rgba(255, 255, 255, 1) 70%, rgba(255, 255, 255, 0) 100%);
    pointer-events: none;
  }

  &__errors-list {
    margin-bottom: 12px;
  }

  &__save-status {
  }

  &__buttons-line {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .buttons-sticky-panel {
    &::before {
      height: calc(100% + 66px);
    }
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .buttons-sticky-panel {
    &::before {
      height: calc(100% + 66px);
    }
  }
}

// Edit profile page ==========================================================
.edit-profile-page {
  width: 1116px;
  margin: 0 auto;

  .no-top-margin {
    margin-top: 0 !important;
  }

  &__notifications-section {
    margin-top: 50px;
    padding: 20px 28px;
    border: 1px #ccd3d7 solid;
    border-radius: 6px;
  }

  &__main-profile-section {
    padding: 20px 28px;
    border: 1px #ccd3d7 solid;
    border-radius: 6px;
    margin-bottom: 30px;
    width: 100%;
  }

  &__column-wrapper {
    display: flex;
    align-items: flex-start;
  }
  &__left-column {
    width: 50%;
    padding-right: 30px;
  }
  &__right-column {
    width: 50%;
  }

  &__loader {
  }

  &__admin-panel {
  }

  &__superuser-data-snippet {
    padding: 8px 14px;
    margin-bottom: 15px;
    border-radius: 6px;
    background-color: #2c058730;
  }

  &__content-for-destinations {
    padding: 16px 20px 22px;
    margin-bottom: 27px;
    border-radius: 6px;
    background: #f3f3f3;

    &[open] summary {
      margin-bottom: 14px;
    }
  }

  &__paid-collab-fields {
    padding: 18px 28px;
    margin-bottom: 27px;
    border-radius: 6px;
    background: #f3f3f3;
  }

  &__interested-in-being-paid {
    margin-bottom: 10px;
  }

  &__buttons-sticky-panel {
    position: sticky;
    inset: auto auto 0 0;
    z-index: 3;
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
  .edit-profile-page {
    width: 945px;
  }
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
  .edit-profile-page {
    width: 822px;

    &__superuser-data-snippet {
      padding: 8px 18px;
    }

    &__paid-collab-fields {
      padding: 18px 18px;
      margin-bottom: 24px;
    }
  }
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
  .edit-profile-page {
    width: 762px;

    &__superuser-data-snippet {
      padding: 8px 18px;
    }

    &__paid-collab-fields {
      padding: 18px 18px;
      margin-bottom: 24px;
    }
  }
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .edit-profile-page {
    width: calc(100vw - 34px);

    &__columns-wrapper {
      flex-direction: column;
    }

    &__paid-collab-fields {
      padding: 18px 18px;
      margin-bottom: 24px;
    }

    &__superuser-data-snippet {
      padding: 8px 18px;
    }
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .edit-profile-page {
    width: calc(100vw - 34px);

    &__paid-collab-fields {
      padding: 18px 18px;
      margin-bottom: 24px;
    }

    &__superuser-data-snippet {
      padding: 8px 18px;
    }
  }
}
</style>
