import { CustomerSummary } from "@contracts/pages";

export enum CustomCollabStatuses {
  InProgress = "inprogress",
  Planning = "planning",
  Complete = "complete",
}

export interface CustomCollabClientPayload {
  creator: CustomCollabCreatorSummary;
  customer: CustomerSummary;

  customerCollabInputs: CustomCollabCustomerData;
  creatorCollabInputs: CustomCollabCreatorData;
}

export interface CustomCollabClientSummary {
  customCollabId: string;
  title: string;

  // todo: Just the fields the widgets need (tbd)
  // Whether we share this between customer and creator depends on what the widgets need

  creator: any;
  customer: any;
}

export interface CustomCollabCreatorSummary {
  creatorName: string;
  creatorProfileImage: string;
}

export interface CustomCollabCustomerData {
  id: string;

  customerId: string;
  creatorId: string;

  messageThreadId: string;

  title: string;
  purpose: string;
  deliverables: string;
  paymentAmount: number;
  otherComp: Array<string>;
  activityDate: string | null;
  dueDate: string | null;
  otherNotes: string;
  contactEmail: string;

  creatorMarkedPaid: boolean;
  archivedDate: string | null;

  createdDate: string;
  createdBy: string;
  updatedDate: string;
  updatedBy: string;
}

export interface CustomCollabCreatorData {
  id: string;

  customerId: string;
  creatorId: string;

  customCollabId: string;
  messageThreadId: string;

  status: string;
  deliverables: Array<string>;
  deliverablesAreReady: boolean;
  creatorNotes: string;

  invite: CustomCollabCreatorInvite;

  createdDate: string;
  createdBy: string;
  updatedDate: string;
  updatedBy: string;
}

export interface CustomCollabCreatorInvite {
  invitedEmail: string;
  inviteKey: string;
}
